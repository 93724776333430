// KnowledgeCenterContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'

interface TickerDrawerContextType {
  isOpen: boolean
  ticker: string
  toggleDrawer: () => void
  setTicker: React.Dispatch<React.SetStateAction<string>>
}

const TickerDrawerContext = createContext<
  TickerDrawerContextType | undefined
>(undefined)

export const TickerDrawerProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [ticker, setTicker] = useState("")

  const toggleDrawer = () => {
    setIsOpen(c => !c)
  }


  return (
    <TickerDrawerContext.Provider
      value={{
        isOpen,
        toggleDrawer,
        setTicker,
        ticker
      }}
    >
      {children}
    </TickerDrawerContext.Provider>
  )
}

export const useTickerDrawer = () => {
  const context = useContext(TickerDrawerContext)
  if (context === undefined) {
    throw new Error(
      'useTickerDrawer must be used within a TickerDrawerProvider'
    )
  }
  return context
}
