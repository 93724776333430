import React, { useState, useEffect } from 'react'
import Fab from '@mui/material/Fab'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTheme } from '@mui/material/styles'

const ScrollToTopButton: React.FC = () => {
  const theme = useTheme()
  const [visible, setVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      // The button will appear after scrolling down 300px
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Fab
      color='primary' // Sets the button color to the default (usually matching the Paper component)
      aria-label='scroll back to top'
      onClick={scrollToTop}
      sx={{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        opacity: visible ? 0.9 : 0, // Adjust opacity here
        transition: 'opacity 0.3s, background-color 0.3s',
        
      }}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  )
}

export default ScrollToTopButton
