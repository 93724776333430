import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class ContentService {
    private setError: (message: string | null) => void

    constructor(setError: (message: string | null) => void) {
        this.setError = setError
    }

    async getLinkToContent(key: string) {
        return axiosInstance
            .post('/content/signedUrl', {
                key,
            })
            .then(res => res.data.data)
            .catch(e => ResponseHelper.handleError(e, this.setError))
    }
}
