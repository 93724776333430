import { Box, Dialog, IconButton, Modal } from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { Close } from '@mui/icons-material'

interface FullScreenImgProps extends CarnivoreModalProps {
  imgSrc: string
}

const FullScreenImgModal: React.FC<FullScreenImgProps> = ({
  open,
  onClose,
  size= "xl",
  imgSrc
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none' }
      }}
    >
      <IconButton
        onClick={onClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          backgroundColor : 'rgba(0,0,0,0.2)',
          zIndex: 1400 // Ensure it's above other elements
        }}
      >
        <Close />
      </IconButton>
      <img
        src={imgSrc}
        alt='Full screen'
        style={{ width: '100%', height: '100%' }}
      />
    </Dialog>
  )
}

export default FullScreenImgModal
