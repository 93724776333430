import React, { FormEvent, ReactNode, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  Box,
  useMediaQuery,
  Typography,
  Grid,
  CircularProgress,
  IconButton
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { StyleHelper } from '../style-helper'
import LoadingButton from '../controls/loading-btn'
import { useError } from '../../context/error'
import { PaymentService } from '../../services/payment.service'
import { useTheme as useCarnivoreTheme } from '../../context/theme'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { Cancel } from '@mui/icons-material'
import CarnivoreBox from '../carnivore-box'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string)

interface AddCardModalProps extends CarnivoreModalProps {
  onComplete?: () => void
}

interface _AddCardFormProps {
  onComplete?: () => void
  onClose: () => void
}
function _AddCardForm (props: _AddCardFormProps) {
  const elements = useElements()
  const stripe = useStripe()
  const [submitting, setSubmitting] = useState(false)
  const { setError } = useError()
  const paymentService = new PaymentService(setError)

  const _addPaymentMethod = async (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (elements) {
      const result = await stripe?.confirmSetup({
        elements,
        redirect: 'if_required'
      })

      if (result?.error) {
        setError(
          result?.error?.message || 'An occurred processing your payment method'
        )
      } else {
        if (props.onComplete) {
          props.onComplete()
        }
        props.onClose()
      }
    }
  }
  return (
    <form onSubmit={_addPaymentMethod}>
      <PaymentElement />
      <StyleHelper.VerticalSpace />
      <LoadingButton
        loading={submitting}
        disabled={submitting}
        type='submit'
        variant='contained'
        color='primary'
      >
        Add Payment Method
      </LoadingButton>
    </form>
  )
}
const AddCardModal: React.FC<AddCardModalProps> = ({
  open,
  onClose,
  size = 'sm',
  onComplete
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [loading, setLoading] = useState(false)


  const { setError } = useError()
  const paymentService = new PaymentService(setError)
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    _load()
  }, [])
  const _load = async () => {
    setLoading(true)
    const res = await paymentService.setupPaymentMethod()
    if (res.clientSecret) {
      setClientSecret(res.clientSecret)
    }
    setLoading(false)
  }

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant='h5' fontWeight={'bold'}>
            Add a new card
          </Typography>
          <IconButton onClick={onClose}>
            <Cancel />
          </IconButton>
        </Box>

        <StyleHelper.VerticalSpace />
        {loading && (
          <>
            <CircularProgress />
          </>
        )}
        {!loading && (
          <>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: {
                  theme: mode === 'dark' ? 'night' : 'flat'
                }
              }}
            >
              <_AddCardForm onClose={onClose} onComplete={onComplete} />
            </Elements>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default AddCardModal
