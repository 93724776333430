const INTROJS_INFO = [
  {
    element: '#alerts-link',
    intro: `View real-time and past alerts`
  },
  {
    element: `#heaters-link`,
    intro: `Handpicked stocks poised for upward momentum in chosen market sectors`
  },
  {
    element: `#longterm-link`,
    intro: `Curated selection of stocks primed for enduring growth and stability.`
  },
  {
    element: `#calendar-link`,
    intro: `Stay updated on calls, news, and Carnivore-centric events`
  },
  {
    element: `#dailywrap-link`,
    intro: `Your daily roundup of market movements and notable events.`
  },
  // {
  //   element: `#videos-link`,
  //   intro: `Explore past calls and expert analysis in our comprehensive video library.`
  // },
  // {
  //   element: `#academy-link`,
  //   intro: `Elevate your trading skills with live sessions from Dutch and Z in luxury.`
  // },
  {
    element: `#settings-link`,
    intro: `Manage your notifications and subscriptions with ease.`
  },
  {
    element: `#support-btn`,
    intro: `Submit support requests for prompt assistance from our team.`
  },
  {
    element: `#notification-btn`,
    intro: `Stay informed with timely browser notifications for market updates and events.`
  },
  {
    element: `#comment-btn`,
    intro: `Provide product feedback or feature requests.`
  },
  {
    element: `#theme-btn`,
    intro: `Toggle between light and dark modes for personalized viewing comfort.`
  }
]

export default INTROJS_INFO
