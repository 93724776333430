import React from 'react'
import {
  Container,
  Typography,
  Grid,
  Skeleton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { StyleHelper } from '../style-helper'
import CarnivoreBox from '../carnivore-box'

const WooSubLoadingPage = () => {
  return (
    <Container sx={{ p: 3 }}>
      <Skeleton variant='text' width={100} height={30} />
      <Skeleton variant='text' width={250} height={20} />
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Skeleton variant='text' width={100} height={30} />
        <StyleHelper.VerticalSpace />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Skeleton variant='text' width={50} height={20} />
            <Skeleton variant='text' width={100} height={30} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Skeleton variant='text' width={50} height={20} />
            <Skeleton variant='text' width={100} height={30} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='text' width={100} height={30} />
          </Grid>
          <Grid item xs={12} md={3} sx={{ p: 2 }}>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='text' width={100} height={30} />
          </Grid>
        </Grid>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Skeleton variant='text' width={100} height={30} />
          </Grid>
          <Grid item xs={12} md={6} alignItems={'flex-end'}>
            <Box display='flex' justifyContent='flex-end'>
              <Skeleton variant='rectangular' width={180} height={40} />
            </Box>
          </Grid>
        </Grid>

        <StyleHelper.SmallVerticalSpace />
        <TableContainer>
          <Table aria-label='payment methods'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant='text' width={100} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' width={100} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' width={100} height={20} />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Skeleton variant='text' width={150} height={30} />
        <StyleHelper.VerticalSpace />
        <TableContainer>
          <Table aria-label='past invoices'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
                <TableCell>
                  <Skeleton variant='text' width={100} height={20} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant='text' width={100} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' width={100} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' width={200} height={20} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
    </Container>
  )
}

export default WooSubLoadingPage
