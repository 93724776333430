import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import ThemeSwitch from '../../components/theme-switch'
import { useTextInput } from '../../hooks/useTextField'
import { StyleHelper } from '../../components/style-helper'
import useBoolean from '../../hooks/useBoolean'
import {
  ArrowRightAlt,
  AttachMoney,
  Visibility,
  VisibilityOff
} from '@mui/icons-material'
import SignupSideBar, { ImgType } from '../../components/signup-sidebar'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGlobalState } from '../../context/global'
import KnowledgeBtn from '../../components/controls/knowledge-btn'
import PhoneNumberInput from '../../components/controls/phone-input'
import TestimonialCarousel from '../../components/testimonials'

export default function SignupAccountPage (prop: PageProps) {
  useDocumentTitle('Signup')

  const fNameInput = useTextInput({
    initialValue: ''
  })
  const lNameInput = useTextInput({
    initialValue: ''
  })
  const emailInput = useTextInput({
    initialValue: ''
  })
  const companyInput = useTextInput({
    initialValue: ''
  })

  const [phoneNumber, setPhoneNumber] = useState('')

  const accountBalanceInput = useTextInput({
    initialValue: '',
    errMsg: 'Enter only numbers in this field',
    validate: (value: string) => {
      return /^[+-]?(\d+\.?\d*|\.\d+)$/.test(value)
    }
  })

  const passwordInput = useTextInput({
    initialValue: '',
    validate: (value: string) => {
      const passwordRegex =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
      return passwordRegex.test(value)
    },
    errMsg:
      'Passwords must be 8 characters long, and contain at least one number and one special character.'
  })
  const confirmInput = useTextInput({
    initialValue: '',
    validate: (value: string) => {
      return value === passwordInput.value
    },
    errMsg: 'Passwords do not match'
  })

  const { setError } = useError()

  const userService = new UserService(setError)

  const [showPassword, { toggle }] = useBoolean()
  const [showConfirm, { toggle: confirmToggle }] = useBoolean()
  const [iAgree, { toggle: toggleAgree }] = useBoolean()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { user, setUser, setIsAuthed } = useGlobalState()

  const _submitSignup = async (e: FormEvent) => {
    e.preventDefault()
    if (!iAgree) {
      setError('Please Agree to the Terms and Conditions')
      return
    }
    if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(
        passwordInput.value
      )
    ) {
      setError(
        `Passwords must be 8 characters long, and contain at least one number and one special character.`
      )
      return
    }
    if (passwordInput.value !== confirmInput.value) {
      setError('Passwords do not match')
      return
    }

    if (
      accountBalanceInput.value !== '' &&
      !/^[+-]?(\d+\.?\d*|\.\d+)$/.test(accountBalanceInput.value)
    ) {
      setError('Estimated Account Balance must be a valid number')
      return
    }
    const phoneRegex = /^\+\d{1,3}\d{10,11}$/
    if (!phoneRegex.test(phoneNumber)) {
      setError('Please enter a valid phone number')
      return
    }

    setError(null)
    setLoading(true)
    const response = await userService.signup({
      pass: passwordInput.value,
      email: emailInput.value.toLowerCase(),
      fName: fNameInput.value,
      lName: lNameInput.value,
      company: companyInput.value,
      tzOffset: new Date().getTimezoneOffset(),
      phoneNumber: phoneNumber,
      accountBalance: Number(accountBalanceInput.value),
      isActive: true
    })
    setLoading(false)
    if (response.user) {
      setUser(response.user)
      setIsAuthed(true)
      navigate('/signup/package')
    }
  }

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Box
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',

              height: '100%'
            }}
          >
            <Box sx={{ p: 3 }}>
              <Grid container alignItems={'center'}>
                <Grid item lg={6} xs={12}>
                  <Typography variant='h3' fontWeight={'bold'}>
                    Input your information
                  </Typography>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography variant='body1'>
                    We need you to help us with some basic information for your
                    account creation. Here are our{' '}
                    <Link
                      href='https://carnivoretrading.com/terms-of-service/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Terms and Conditions.
                    </Link>{' '}
                    Please read them carefully.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <StyleHelper.DoubleVerticalSpace />
            <form onSubmit={_submitSignup}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={6}>
                  <TextField
                    label='First Name'
                    required
                    variant='outlined'
                    fullWidth
                    value={fNameInput.value}
                    error={fNameInput.error}
                    helperText={fNameInput.helperText}
                    onChange={fNameInput.onChange}
                    inputProps={{ tabIndex: 1 }}
                  />
                  <StyleHelper.DoubleVerticalSpace />
                  <TextField
                    label='Email'
                    type='email'
                    required
                    variant='outlined'
                    fullWidth
                    inputProps={{ tabIndex: 3 }}
                    value={emailInput.value}
                    error={emailInput.error}
                    helperText={emailInput.helperText}
                    onChange={emailInput.onChange}
                  />
                  <StyleHelper.DoubleVerticalSpace />
                  <PhoneNumberInput onChange={setPhoneNumber} />
                  <StyleHelper.DoubleVerticalSpace />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Last Name'
                    variant='outlined'
                    required
                    fullWidth
                    value={lNameInput.value}
                    error={lNameInput.error}
                    inputProps={{ tabIndex: 2 }}
                    helperText={lNameInput.helperText}
                    onChange={lNameInput.onChange}
                  />
                  <StyleHelper.DoubleVerticalSpace />
                  <TextField
                    label='Company (Optional)'
                    variant='outlined'
                    fullWidth
                    value={companyInput.value}
                    error={companyInput.error}
                    inputProps={{ tabIndex: 4 }}
                    helperText={companyInput.helperText}
                    onChange={companyInput.onChange}
                  />
                  <StyleHelper.DoubleVerticalSpace />
                  <TextField
                    label='Estimated Account Balance (Optional)'
                    variant='outlined'
                    tabIndex={9}
                    fullWidth
                    value={accountBalanceInput.value}
                    error={accountBalanceInput.error}
                    helperText={accountBalanceInput.helperText}
                    onChange={accountBalanceInput.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AttachMoney />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <KnowledgeBtn id='account-balance' />
                        </InputAdornment>
                      )
                    }}
                  />
                  <StyleHelper.DoubleVerticalSpace />
                </Grid>
              </Grid>
              <Divider />
              <StyleHelper.DoubleVerticalSpace />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label='Password'
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    value={passwordInput.value}
                    error={passwordInput.error}
                    helperText={passwordInput.helperText}
                    onChange={passwordInput.onChange}
                    tabIndex={10}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={toggle}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Confirm Password'
                    variant='outlined'
                    type={showConfirm ? 'text' : 'password'}
                    fullWidth
                    tabIndex={11}
                    required
                    value={confirmInput.value}
                    error={confirmInput.error}
                    helperText={confirmInput.helperText}
                    onChange={confirmInput.onChange}
                    InputProps={{

                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={confirmToggle}
                            edge='end'
                          >
                            {showConfirm ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <StyleHelper.DoubleVerticalSpace />

              <Divider />
              <StyleHelper.DoubleVerticalSpace />
              <Grid container justifyContent='space-between'>
                <Grid item lg={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={iAgree} onChange={toggleAgree} />
                    }
                    tabIndex={12}
                    label={
                      <Typography variant='body1'>
                        I Agree to the{' '}
                        <Link
                          href='https://carnivoretrading.com/terms-of-service/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item lg={6} xs={12} container justifyContent='flex-end'>
                  <Button
                    variant='contained'
                    type='submit'
                    tabIndex={13}
                    disabled={loading}
                    endIcon={
                      loading ? (
                        <CircularProgress size={16} />
                      ) : (
                        <ArrowRightAlt />
                      )
                    }
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
            <StyleHelper.DoubleVerticalSpace />
            <Button variant='text' onClick={() => navigate('/login')}>
              Back to Login
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            bgcolor: 'action.hover',
            p: 3
          }}
        >
          <SignupSideBar
            imgType={ImgType.email}
            component={
              <TestimonialCarousel
                testimonials={[
                  {
                    author: 'Mark C.',
                    url: 'https://www.youtube.com/embed/iSm01uOAdCg?si=c-SirhujRIRLnWIi'
                  },
                  {
                    author: 'Powell & Gary',
                    url: 'https://www.youtube.com/embed/Yb9LnW0Jd3Y?si=HxmIqP6YrjegPNmK'
                  },
                  {
                    author: 'Bonnie G',
                    url: 'https://www.youtube.com/embed/kKJuFLRIl_c?si=VGR-FvUHUZVVSgkc'
                  }
                ]}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
