import axios from 'axios'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials : true
})

export default axiosInstance
