import { Feedback, Home, NotificationImportant } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import ThemeSwitch from './theme-switch'
import { useGlobalState } from '../context/global'
import WebPush from './webpush'
import { StyleHelper } from './style-helper'
import SupportBtn from './controls/support-btn'
import CarnivoreLogo from './imgs/carnivore-logo'
import useBoolean from '../hooks/useBoolean'
import CommentModal from './modals/comment.modal'

export default function TopBar () {
  const { user, liveAlert, setLiveAlert } = useGlobalState()
  const theme = useTheme()

  const [showFeedback, { toggle: toggleShowFeedback }] = useBoolean(false)
  const _getGreetingBasedOnCurrentTime = (): string => {
    const now = new Date()
    const hours = now.getHours()

    if (hours < 12) {
      return 'Good morning, '
    } else if (hours >= 12 && hours < 17) {
      return 'Good afternoon, '
    } else {
      return 'Good evening, '
    }
  }

  const _getFadeInAnimation = (delay: number) => {
    return {
      opacity: 0,
      animation: `fadeIn 2s ease-out`,
      animationDelay: `${delay}s`,
      animationFillMode: 'forwards', // Keep opacity at 1 after animation ends
      '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 }
      }
    }
  }
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      sx={{
        p: 2,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='body1'
            fontWeight={'bold'}
            sx={_getFadeInAnimation(0)}
          >
            {_getGreetingBasedOnCurrentTime()}
          </Typography>
          &nbsp;
          <Typography
            variant='body1'
            fontWeight={'bold'}
            sx={{ ..._getFadeInAnimation(1) }}
          >
            {user?.fName ?? ''}
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        {liveAlert && (
          <Paper elevation={1}>
            <Box display={'flex'} alignItems={'center'} p={1}>
              <Box
                width={20}
                mr={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
              >
                <CarnivoreLogo />
              </Box>
              <Typography variant='body1' sx={{ mr: 4 }}>
                {liveAlert?.subject}
              </Typography>

              <Button
                variant='text'
                onClick={() => {
                  window.open(`/alerts/${liveAlert?.id}`)
                }}
              >
                View
              </Button>
            </Box>
          </Paper>
        )}
      </Grid>
      <Grid item>
        {/* Wrap ThemeSwitch and WebPush in a Box or Grid for inline display */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SupportBtn />
          <StyleHelper.HorizontalSpace />
          <WebPush />
          <StyleHelper.HorizontalSpace />
          <Tooltip title='Give Feedback'>
            <IconButton
              id='comment-btn'
              onClick={toggleShowFeedback}
              aria-label='leave feedback'
            >
              <Feedback />
            </IconButton>
          </Tooltip>

          <StyleHelper.HorizontalSpace />

          <ThemeSwitch />
        </Box>
        {showFeedback && (
          <CommentModal
            size='md'
            open={showFeedback}
            onClose={toggleShowFeedback}
          />
        )}
      </Grid>
    </Grid>
  )
}
