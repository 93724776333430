import React, { useState } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { QueryStats } from '@mui/icons-material'

interface ImageWithFallbackProps extends BoxProps {
  src: string
  alt: string
}

const StockLogo: React.FC<ImageWithFallbackProps> = ({ src, alt, ...rest }) => {
  const [imageSrc, setImageSrc] = useState(src)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const onLoad = () => {
    setLoading(false)
  }

  const onError = () => {
    if (!error) {
      setLoading(false)
      setError(true)
    }
  }

  return (
    <Box {...rest}>
      {loading && (
                  <Skeleton variant='rectangular' width={80} height={80} />

      )}{' '}
      {!error && (
        <img
          src={imageSrc}
          alt={alt}
          onLoad={onLoad}
          onError={onError}
          style={{
            borderRadius: 2,
            width: '100%', // Makes the image responsive
            height: 'auto', // Maintains aspect ratio
            display: loading ? 'none' : 'block' // Hide the image while loading
          }}
        />
      )}
      {error && <QueryStats color='primary' sx={{
        fontSize : '60px'
      }} />}
    </Box>
  )
}

export default StockLogo
