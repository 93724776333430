 
import { Campaign, Insights } from '@mui/icons-material';
import { Box, Button, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { StyleHelper } from '../components/style-helper';
import ThemeSwitch from '../components/theme-switch';
import { useError } from '../context/error';
import useBoolean from '../hooks/useBoolean';
import { AlertService } from '../services/alert.service';
import { CarnivoreAlert } from '../_types/alert.type';
import PageProps from '../_types/page-props.interface'
import { useTheme as useCarnivoreTheme } from '../context/theme'
import useDocumentTitle from '../hooks/useDocumentTitle';

const _formateTime = (timestamp: number) => {
    const date = new Date(timestamp)
    return `${date.toLocaleDateString('en-US', {
      dateStyle: 'full'
    })} at ${date.toLocaleTimeString('en-US', { timeStyle: 'short' })}`
  }

export default function AlertPage(props: PageProps) {
    useDocumentTitle(props.title)

    const params = useParams();
    const [loading, {setFalse, toggle}] = useBoolean(true)
    const [localAlert, setLocalAlert] = useState<CarnivoreAlert | null>(null)
    const { setError } = useError()
    const alertService = new AlertService(setError)
    const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context
    const theme = useTheme()

    useEffect(() => {
      async function getAlertData() {
        const res = await alertService.getAlert(params.id!);
        setLocalAlert(res)
        
      }
  
        getAlertData()
   
    }, [])

    const iframeRef = useRef<HTMLIFrameElement>(null)
    useEffect(() => {
      setTimeout(() => {
        const iframeDoc =
          iframeRef?.current?.contentDocument ||
          iframeRef?.current?.contentWindow?.document
        if (iframeDoc) {
          iframeDoc.open('text/html', 'replace')
          iframeDoc.write(
            DOMPurify.sanitize(localAlert?.contentHTML ?? '<h1></h1>')
          )
          iframeDoc.close()
          iframeDoc.body.style.color = mode === 'dark' ? 'white' : 'black'
          iframeDoc.body.style.fontFamily = 'Roboto, sans-serif'
  
          const links: any = iframeDoc.getElementsByTagName('a')
          for (let link of links) {
            link.style.color = theme.palette.primary.main // Get the primary color from the theme
            // Apply primary color to links
  
            link.target = '_blank'
          }
          const tableTextElements = iframeDoc.querySelectorAll('table, th, td')
          tableTextElements.forEach((element: any) => {
            element.style.color = mode === 'dark' ? 'white' : 'black' // Apply text color to table text
          })
        }
        setFalse()
      }, 200)
    }, [mode, localAlert])
  
    useEffect(() => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
      }
    }, [iframeRef.current])

 
  return (
    <Box padding={8}>
        <Grid container  alignItems='center'>
          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            {localAlert?.alertType === 'alert' ? (
              <Campaign color='primary' sx={{ fontSize: 36 }} />
            ) : (
              <Insights color='primary' sx={{ fontSize: 36 }} />
            )}
          </Grid>
          <Grid item xs={9} sx={{ textAlign: 'left' }}>
            <Typography color={'primary'} variant='h5' fontWeight={'bold'}>
              {localAlert?.subject}
            </Typography>
            {/* Adjust margin */}
          </Grid>
        </Grid>
        <Typography variant='body2' sx={{ ml: 1 }}>
          {_formateTime(localAlert?.timeStamp ?? 0)}
        </Typography>
        <StyleHelper.VerticalSpace />

        <iframe
          ref={iframeRef}
          style={{
            width: '100%',
            height: loading ? 0 : '50vh',
            border: 'none'
          }}
          title='Content Frame'
        />
        {loading && (
          <Box>
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='90%' />
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='70%' />
            <Skeleton variant='text' width='60%' />
          </Box>
        )}
        <Typography variant='body2' fontWeight={'bold'} color={'primary'}>
          Alerts Adjusted to Your Portfolio Balance Coming Soon.
        </Typography>
        <StyleHelper.DoubleVerticalSpace />
       
    </Box>
  )
}
