import { Box, Grid, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface SignupHeaderProps {
  header: string
  body: ReactNode
}
export default function SignupHeader (props: SignupHeaderProps) {
  return (
    <Box sx={{ p: 3 }} >
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item lg={6} xs={12}>
          <Typography variant='h3' fontWeight={'bold'}>
            {props.header}
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography variant='body1'>{props.body}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
