import React, { useState, useEffect } from "react";
import { Modal, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CarnivoreModalProps } from "../../../_types/modal-props.interface";
import { useError } from "../../../context/error";
import { ContentService } from "../../../services/content.service";

interface VideoModalProps extends CarnivoreModalProps {
    objectKey: string;
    title: string;
}

function VideoModal({ objectKey, title, open, onClose }: VideoModalProps) {
    const [loading, setLoading] = useState(true);
    const [videoLink, setVideoLink] = useState<string | null>(null);
    const { setError } = useError();

    const contentService = new ContentService(setError);

    useEffect(() => {
        if (open) {
            setLoading(true);
            contentService.getLinkToContent(objectKey)
                .then((res) => {
                    setVideoLink(res?.url); // Assuming `res.link` contains the video URL
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [open, objectKey]);

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="video-modal" aria-describedby="video-modal-description">
            <Box
                sx={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "70%",
                    maxWidth: 800,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 2 }}>
                    <Typography variant="h6" component="h2">
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>

                {loading ? (
                    <CircularProgress />
                ) : videoLink ? (
                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            paddingTop: "56.25%", // 16:9 Aspect Ratio
                        }}
                    >
                        <video
                            controls
                            controlsList="nodownload"
                            autoPlay
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <source src={videoLink} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />Your browser does not support the video tag. I suggest you upgrade your browser.
                        </video>
                    </Box>
                ) : (
                    <Typography color="error">Failed to load video</Typography>
                )}
            </Box>
        </Modal>
    );
}

export default VideoModal;
