import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material'
import GettingStartedInfo from './getting-started-info'
import { Android, Apple, InstallMobile } from '@mui/icons-material'
import { StyleHelper } from './style-helper'
import QRCode from 'react-qr-code'

export default function DownloadTheApp () {
  const theme = useTheme()
  return (
    <Container>
      <GettingStartedInfo
        title='Download the App'
        icon={<InstallMobile fontSize='inherit' />}
        content='Take the power of Carnivore Trading with you wherever you go by downloading our mobile app. Access our suite of offerings on the go, from market insights to exclusive events, ensuring you stay connected and informed, no matter where you are. Streamline your trading experience and seize opportunities in real-time with our convenient mobile platform.'
      />

      <Grid container justifyContent={'center'}>
        <Grid item xs={12} lg={8}>
          <Typography
            variant='subtitle1'
            textAlign={'center'}
            fontWeight={'bold'}
            color={'primary'}
          >
            Scan the QR Code with your Mobile Device
          </Typography>
          <StyleHelper.DoubleVerticalSpace />
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={6}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Chip label='iOS' icon={<Apple />} />
              </Box>
              <StyleHelper.VerticalSpace />
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <QRCode
                  value='https://apps.apple.com/sa/app/carnivore-trading/id6469260729'
                  bgColor='transparent'
                  size={140}
                  level='H'
                  fgColor={theme.palette.secondary.main}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} alignItems={'center'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Chip label='Android' icon={<Android />} />
              </Box>
              <StyleHelper.VerticalSpace />
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <QRCode
                  value='https://play.google.com/store/apps/details?id=com.carnivoretrading&hl=en_US&gl=US&pli=1'
                  bgColor='transparent'
                  size={140}
                  level='H'
                  fgColor={theme.palette.secondary.main}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />

      <StyleHelper.DoubleVerticalSpace />
    </Container>
  )
}
