import { Box, Typography } from '@mui/material'
import CarnivoreLogoTxt from './imgs/carnivore-logo-txt'
import { Construction } from '@mui/icons-material'
import { StyleHelper } from './style-helper'

export default function ComingSoon () {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      height={'90vh'}
      justifyContent={'space-between'}
    >
      <Box width={'30%'}>
        <CarnivoreLogoTxt />
      </Box>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <div style={{fontSize : '125px'}}>
          <Construction color='primary' fontSize={'inherit'} />
        </div>
        <Typography variant='h2' fontWeight={'bold'} gutterBottom>
          Page Under Construction
        </Typography>
        <Typography variant='body1'>
          Come back later to see this page
        </Typography>
      </Box>
      <Box />
    </Box>
  )
}
