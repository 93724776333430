import { useEffect, useState } from 'react'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useError } from '../context/error'
import { PaymentService } from '../services/payment.service'
import { Container, Typography } from '@mui/material'
import { Subscription } from '../_types/subscription.type'

export default function ProfilePage (props: PageProps) {
  useDocumentTitle('Profile')

  const { setError } = useError()

  const paymentService = new PaymentService(setError)
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const _fetchSubInfo = async () => {
    setLoading(true)
    const res = await paymentService.getSubInfo()
  }

  useEffect(() => {
    _fetchSubInfo()
  }, [])

  return (
    <Container>
      <Typography variant='h4'>Profile</Typography>
    </Container>
  )
}
