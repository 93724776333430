// KnowledgeCenterContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'

interface KnowledgeCenterContextType {
  isOpen: boolean
  knowledgeCenterType: string | null
  openKnowledgeCenter: (type: string) => void
  closeKnowledgeCenter: () => void
}

const KnowledgeCenterContext = createContext<
  KnowledgeCenterContextType | undefined
>(undefined)

export const KnowledgeCenterProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [knowledgeCenterType, setKnowledgeCenterType] = useState<string | null>(
    null
  )

  const openKnowledgeCenter = (type: string) => {
    setKnowledgeCenterType(type)
    setIsOpen(true)
  }

  const closeKnowledgeCenter = () => {
    setIsOpen(false)
    setKnowledgeCenterType(null)
  }

  return (
    <KnowledgeCenterContext.Provider
      value={{
        isOpen,
        knowledgeCenterType,
        openKnowledgeCenter,
        closeKnowledgeCenter
      }}
    >
      {children}
    </KnowledgeCenterContext.Provider>
  )
}

export const useKnowledgeCenter = () => {
  const context = useContext(KnowledgeCenterContext)
  if (context === undefined) {
    throw new Error(
      'useKnowledgeCenter must be used within a KnowledgeCenterProvider'
    )
  }
  return context
}
