import { Box, Grid, Paper, Skeleton } from '@mui/material'
import { StyleHelper } from '../style-helper'

export default function EventCardLoading () {
  return (
    <Paper>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Skeleton variant='rectangular' height={140} animation='wave' />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Skeleton width='20%' height={40} style={{ marginTop: 8 }} />
        </Box>
        <Box p={1}>
          <StyleHelper.VerticalSpace />
          <Skeleton width='60%' height={44} />
          <StyleHelper.VerticalSpace />

          <Skeleton width='50%' height={34} />
          <StyleHelper.VerticalSpace />

          <Skeleton width='80%' height={24} style={{ marginBottom: 16 }} />
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item>
              <Skeleton width={'34px'} height={34} />
            </Grid>
            <Grid item>
              <Skeleton width={'100px'} height={44} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}
