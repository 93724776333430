import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
  Button,
  Modal,
  Box,
  useMediaQuery,
  Typography,
  Grid,
  useTheme,
  Skeleton,
  Paper,
  Tooltip,
  IconButton,
  CircularProgress
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { StyleHelper } from '../style-helper'
import LoadingButton from '../controls/loading-btn'
import { CarnivoreAlert } from '../../_types/alert.type'
import { useTheme as useCarnivoreTheme } from '../../context/theme'
import ThemeSwitch from '../theme-switch'
import {
  ArrowOutwardRounded,
  BookmarkAdd,
  BookmarkRemove,
  Campaign,
  Close,
  Insights
} from '@mui/icons-material'
import DOMPurify from 'dompurify'
import { AlertService } from '../../services/alert.service'
import { useError } from '../../context/error'
import { useGlobalState } from '../../context/global'
import useBoolean from '../../hooks/useBoolean'

interface ViewAlertModalProps extends CarnivoreModalProps {
  alert: CarnivoreAlert | null
}

const ViewAlertModal: React.FC<ViewAlertModalProps> = ({
  open,
  onClose,
  size = 'lg',
  alert
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context
  const theme = useTheme()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [loading, setLoading] = useState(true)
  const { setError } = useError()
  const { addToViewAlerts, user, setUser } = useGlobalState()
  const alertService = new AlertService(setError)

  const [bookmarkLoading, { toggle: toggleBMLoading }] = useBoolean(false)
  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
      const iframeDoc =
        iframeRef?.current?.contentDocument ||
        iframeRef?.current?.contentWindow?.document
      if (iframeDoc) {
        iframeDoc.open('text/html', 'replace')
        iframeDoc.write(
          DOMPurify.sanitize(alert?.contentHTML ?? '<h1>test</h1>')
        )
        iframeDoc.close()
        iframeDoc.body.style.color = mode === 'dark' ? 'white' : 'black'
        iframeDoc.body.style.fontFamily = 'Roboto, sans-serif'

        const links: any = iframeDoc.getElementsByTagName('a')
        for (let link of links) {
          link.style.color = theme.palette.primary.main // Get the primary color from the theme
          // Apply primary color to links

          link.target = '_blank'
        }
        const tableTextElements = iframeDoc.querySelectorAll('table, th, td')
        tableTextElements.forEach((element: any) => {
          element.style.color = mode === 'dark' ? 'white' : 'black' // Apply text color to table text
        })
      }
      setLoading(false)
    }, 200)
    alertService.viewAlert(alert?.id ?? '').then(() => {
      addToViewAlerts([{ userID: user?.id ?? '', alertID: alert?.id ?? '' }])
    })
  }, [mode, open])

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
    }
  }, [iframeRef.current])

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  const _formateTime = (timestamp: number) => {
    const date = new Date(timestamp)
    return `${date.toLocaleDateString('en-US', {
      dateStyle: 'full'
    })} at ${date.toLocaleTimeString('en-US', { timeStyle: 'short' })}`
  }

  const _bookmarkAlert = async () => {
    toggleBMLoading()
    const res = await alertService.bookmarkAlert(alert?.id ?? '')
    if (res.user) {
      setUser(res.user)
    }
    toggleBMLoading()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Grid container  alignItems='center' justifyContent={'center'}>
          <Grid item xs={12} md={8} sx={{ textAlign: 'left' }}>
            <Box display='flex'>
              {alert?.alertType === 'alert' ? (
                <Campaign color='primary' sx={{ fontSize: 36, mr : 2 }} />
              ) : (
                <Insights color='primary' sx={{ fontSize: 36, mr : 2 }} />
              )}
              <Typography color={'primary'} variant='h5' fontWeight={'bold'}>
                {alert?.subject}
              </Typography>
            </Box>

            {/* Adjust margin */}
          </Grid>
          <Grid item md={4} xs={12} sx={{ textAlign: 'right' }} alignItems={'flex-end'}>
            <ThemeSwitch />

            <Tooltip
              title={`${
                user?.bookmarkedAlerts?.includes(alert?.id ?? '')
                  ? 'Remove from'
                  : 'Add to'
              } bookmarks`}
            >
              <IconButton onClick={_bookmarkAlert} disabled={bookmarkLoading}>
                {bookmarkLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {!user?.bookmarkedAlerts?.includes(alert?.id ?? '') ? (
                      <BookmarkAdd />
                    ) : (
                      <BookmarkRemove />
                    )}
                  </>
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title='Open in new tab'>
              <IconButton
                href={`/alerts/${alert?.id}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <ArrowOutwardRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title='Close'>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Tooltip>

            {/* Adjust margin */}
          </Grid>
        </Grid>

        <StyleHelper.VerticalSpace />
        <Typography variant='body2' sx={{ ml: 1 }}>
          {_formateTime(alert?.timeStamp ?? 0)}
        </Typography>
        <StyleHelper.VerticalSpace />

        <iframe
          ref={iframeRef}
          style={{
            width: '100%',
            height: loading ? 0 : '50vh',
            border: 'none'
          }}
          title='Content Frame'
        />
        {loading && (
          <Box>
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='100%' />

            <Skeleton variant='text' width='100%' />
          </Box>
        )}
        <Typography variant='body2' fontWeight={'bold'} color={'primary'}>
          Alerts Adjusted to Your Portfolio Balance Coming Soon.
        </Typography>
        <StyleHelper.DoubleVerticalSpace />
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button variant='text' sx={{ ml: 1 }} onClick={onClose}>
              Close
            </Button>
            {/* Adjust margin */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ViewAlertModal
