import { useState } from 'react'

interface UseTextInputProps {
  initialValue?: string
  validate?: (value: string) => boolean
  errMsg?: string
}

interface UseTextInputReturn {
  value: string
  error: boolean
  helperText: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  reset: () => void
}

export const useTextInput = ({
  initialValue = '',
  errMsg = 'Invalid input',
  validate = () => true // By default, always valid
}: UseTextInputProps): UseTextInputReturn => {
  const [value, setValue] = useState<string>(initialValue)
  const [error, setError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState<string>('')

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const isValid = validate(newValue)

    setValue(newValue)
    setError(!isValid)
    setHelperText(isValid ? '' : errMsg) // Customize your error message
  }

  const reset = () => setValue(initialValue)

  return { value, error, helperText, onChange, reset }
}
