export class ResponseHelper {
  static async handleError (e: any, setErr: (message: string | null) => void) {
 
    if (e.response) {
      if (e.response.status === 401 || e.response.status === 403) {
        const loc = window.location.href
        if (!loc.includes('/login') && !loc.includes('/signup')) {
          window.location.href = '/login'
          return
        }
      }
    }
    const err = e.response?.data?.message ?? 'an error occurred'
    setErr(err)
    return {
      errMsg: err
    }
  }
}
