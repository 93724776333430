import { useEffect, useState } from 'react'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useError } from '../context/error'
import { PaymentService } from '../services/payment.service'
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { Subscription } from '../_types/subscription.type'
import {
  AccountCircle,
  Notifications,
  Payment,
  SupportAgent
} from '@mui/icons-material'
import ComingSoon from '../components/comingsoon'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import SubscriptionPage from './settings/subscription.page'
import NotificationsPage from './settings/notifications.page'
import { Elements } from '@stripe/react-stripe-js'
import ProfilePage from './settings/profile.page'
import { loadStripe } from '@stripe/stripe-js'
import { useTheme as useCarnivoreTheme } from '../context/theme'
import { StyleHelper } from '../components/style-helper'

enum SettingsPageType {
  profile,
  subscription,
  notifications,
  support
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string)

export default function SettingsPage (props: PageProps) {
  useDocumentTitle(props.title)
  const location = useLocation()

  const { setError } = useError()
  const navigate = useNavigate()
  const paymentService = new PaymentService(setError)
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context
  const [settingsPageType, setSettingsPageType] = useState<SettingsPageType>(
    SettingsPageType.profile
  )

  const [clientSecret, setClientSecret] = useState('')
  const theme = useTheme()
  useEffect(() => {
    _load()
  }, [])
  const _load = async () => {
    const res = await paymentService.setupPaymentMethod()
    if (res.clientSecret) {
      setClientSecret(res.clientSecret)
    }
  }
  const pages = [
    {
      path: 'profile',
      text: 'Profile',
      settingsPageType: SettingsPageType.profile,
      icon: <AccountCircle />
    },
    {
      path: 'subscription',
      text: 'Subscription',
      settingsPageType: SettingsPageType.subscription,
      icon: <Payment />
    },
    {
      path: 'notifications',
      text: 'Notifications',
      settingsPageType: SettingsPageType.notifications,
      icon: <Notifications />
    }
  ]
  useEffect(() => {
    console.log(mode)
  }, [mode])

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box display='flex' alignItems='center' gap={2}>
        <Typography variant='h4'>Settings</Typography>
      </Box>
      <StyleHelper.VerticalSpace />
      <Box display={'flex'} flexWrap={'wrap'}>
        {pages?.map((p: any, index: number) => (
          <Chip
            sx={{ mr: 1 }}
            color={window.location.href.includes(p.path) ? 'primary' : undefined}
            onClick={() => navigate(`/settings/${p.path}`)}
            id={p.path}
            label={p.text}
            icon={p.icon}
          />
        ))}
      </Box>
      <StyleHelper.VerticalSpace />
      <Divider />
      {clientSecret !== '' && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: mode === 'dark' ? 'night' : 'flat'
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              p: 0,
              m: 0,
              width: '100%'
            }}
          >
            <Routes>
              <Route
                path='notifications'
                element={<NotificationsPage title='Notifications' />}
              />
              <Route
                path='profile'
                element={<ProfilePage title='Subscription' />}
              />
              <Route
                path='subscription'
                element={<SubscriptionPage title='Subscription' />}
              />
              <Route path='*' element={<ComingSoon />} />
            </Routes>
          </Box>
        </Elements>
      )}
    </Container>
  )
}
