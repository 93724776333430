import { useEffect, useState } from 'react'
import { StockService } from '../services/stock.service'
import { useError } from '../context/error'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import StockLogo from './imgs/stock-logo'
import { StyleHelper } from './style-helper'
import { ArrowRightAlt, ExpandMore } from '@mui/icons-material'

interface StockCommentaryProps {
  ticker: string
  name: string
  commentary: string
}
export default function StockCommentary (props: StockCommentaryProps) {
  const [loading, setLoading] = useState(true)
  const [logoSrc, setLogoSrc] = useState<string | null>(null)
  const { setError } = useError()
  const stockService = new StockService(setError)

  useEffect(() => {
    _getLogo()
  }, [])

  const _getLogo = async () => {
    const res = await stockService.getStockLogo(props.ticker)
    setLogoSrc(res?.url)
    setLoading(false)
  }

  return (
    <Paper elevation={3} sx={{ my: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1-content'
          id={props.ticker}
        >
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={3} sm={3} md={2} lg={1} xl={1}>
              {!loading && (
                <StockLogo src={logoSrc ?? ''} alt={props.ticker ?? ''} />
              )}
            </Grid>
            <Grid item xs={9} sm={9} md={10} lg={11} xl={11}>
              <Typography variant='h4'>{props.name}</Typography>
              <Typography variant='subtitle1'>{props.ticker}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {props.commentary.split('\n')?.map(line => (
            <Typography variant='body2' mt={2}>
              {line}
            </Typography>
          ))}
          <StyleHelper.VerticalSpace />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button variant='outlined' endIcon={<ArrowRightAlt />} onClick={() => {
                window.open(`/stock/${props.ticker}`, '_blank', 'noopener,noreferrer')
            }}>View Stock</Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* <StyleHelper.VerticalSpace />
        <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
          Commentary
        </Typography> */}
    </Paper>
  )
}
