import { Box, Skeleton, useTheme } from '@mui/material'
import { useTheme as useCarnivoreTheme } from '../context/theme'
import DOMPurify from 'dompurify'
import { useEffect, useRef, useState } from 'react'

interface IFrameHTMLContentProps {
  content: string
  center?: boolean
  loadBootstrap?: boolean
}
export default function IFrameHTMLContent (props: IFrameHTMLContentProps) {
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context
  const theme = useTheme()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [loading, setLoading] = useState(true)

  const _wrapImages = (html: string) => {
    // Regular expression to find image tags in HTML
    const imgRegex = /<img.*?src="(.*?)".*?>/g

    // Replace image tags with FullScreenImg component
    const wrappedHtml = html.replace(imgRegex, (match, src) => {
      return `<img width="100%" src="${src}" />`
    })

    return wrappedHtml
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
      const iframeDoc =
        iframeRef?.current?.contentDocument ||
        iframeRef?.current?.contentWindow?.document
      if (iframeDoc) {
        iframeDoc.open('text/html', 'replace')
        if (props.loadBootstrap) {
          var headContent =
            '<head>' +
            '<meta name="viewport" content="width=device-width,initial-scale=1" />' +
            '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous">' +
            '</head>'
          const sanitizedContent = DOMPurify.sanitize(props.content)
          var fullContent =
            headContent + '<body>' + sanitizedContent + '</body>'

          iframeDoc.write(fullContent)
          iframeDoc.body.style.backgroundColor = 'transparent'
        } else {
          iframeDoc.write(DOMPurify.sanitize(props.content))
        }

        iframeDoc.close()
        iframeDoc.body.style.color = mode === 'dark' ? 'white' : 'black'
        iframeDoc.body.style.fontFamily = 'Roboto, sans-serif'
        if (props.center) {
          iframeDoc.body.style.textAlign = 'center !important'
        }
        const links: any = iframeDoc.getElementsByTagName('a')
        for (let link of links) {
          link.style.color = theme.palette.primary.main // Get the primary color from the theme
          // Apply primary color to links

          link.target = '_blank'
        }
        const txts: any = iframeDoc.getElementsByTagName('p')
        for (let txt of txts) {
          if (txt.style.color !== '#FF6600') {
            if (txt.parentElement?.nodeName.toLowerCase() === 'font') {
              if (txt.parentElement?.style.color !== '#FF6600') {
                txt.style.color = mode === 'dark' ? 'white' : 'black' // Get the primary color from the theme
              }
            }
          } else {
            txt.style.color = mode === 'dark' ? 'white' : 'black' // Get the primary color from the theme
          }
        }

        const strongs: any = iframeDoc.getElementsByTagName('strong')
        for (let txt of strongs) {
          if (txt.style.color !== '#FF6600') {
            if (txt.parentElement?.nodeName.toLowerCase() === 'font') {
              if (txt.parentElement?.style.color !== '#FF6600') {
                txt.style.color = mode === 'dark' ? 'white' : 'black' // Get the primary color from the theme
              }
            }
          } else {
            txt.style.color = mode === 'dark' ? 'white' : 'black' // Get the primary color from the theme
          }
        }

        const imgs: any = iframeDoc.getElementsByTagName('img')
        for (let img of imgs) {
          img.style.width = '100%'
        }

        const fonts: any = iframeDoc.getElementsByTagName('font')
        for (let font of fonts) {
          if (font.style.color === '#FF6600') {
            if (font.firstChild) {
              font.firstChild.style.color = '#FF6600'
            } else {
              font.style.color = mode === 'dark' ? 'white' : 'black'
            }
          } else {
            font.style.color = mode === 'dark' ? 'white' : 'black'
          }
        }

        const tableTextElements = iframeDoc.querySelectorAll('table, th, td')
        tableTextElements.forEach((element: any) => {
          element.style.color = mode === 'dark' ? 'white' : 'black' // Apply text color to table text
        })
      }
      setLoading(false)
    }, 200)
  }, [mode, props.content])

  return (
    <Box>
      <iframe
        ref={iframeRef}
        style={{
          width: '100%',
          height: loading ? 0 : '50vh',
          border: 'none'
        }}
        title='Content Frame'
      />
      {loading && (
        <Box>
          <Skeleton variant='text' width='100%' />
          <Skeleton variant='text' width='100%' />

          <Skeleton variant='text' width='100%' />
        </Box>
      )}
    </Box>
  )
}
