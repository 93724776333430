import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class PaymentService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async resumeStripeSubscription () {
    return axiosInstance
      .put('/payment/subscription/resume')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async switchSubDuration (duration : string) {
    return axiosInstance
      .put('/payment/subscription/switch', {duration})
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async setupPaymentMethod () {
    return axiosInstance
      .post('/payment/pm-setup')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getWooSubscription () {
    return axiosInstance
      .get('/payment/woo/subscription')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getSubLink () {
    return axiosInstance
      .get('/payment/sub-link')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getWooInvoices () {
    return axiosInstance
      .get('/payment/woo/invoices')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async createSubscription (
    packageType: string,
    cycle: string,
    paymentMethod: string,
    address: any,
    coupon?: string
  ) {
    return axiosInstance
      .post('/payment/subscription', {
        packageType,
        cycle,
        paymentMethod,
        address,
        coupon
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async checkValidCoupon (coupon: string) {
    return axiosInstance
      .post('/payment/validate-coupon', { coupon })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getSubInfo () {
    return axiosInstance
      .get('/payment/subscription')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getInvoiceHistory () {
    return axiosInstance
      .get('/payment/invoices')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getPaymentMethods () {
    return axiosInstance
      .get('/payment/payment-methods')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async cancelSubscription (reason: string, comment: string) {
    return axiosInstance
      .post('/payment/subscription/cancel', {
        reason,
        comment
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async cancelWooSubscription (reason: string, comment: string) {
    return axiosInstance
      .post('/payment/woo/cancel', {
        reason,
        comment
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
