import { Box, Button, Container, Grid, Typography } from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import { StyleHelper } from '../../components/style-helper'
import CarnivoreBox from '../../components/carnivore-box'
import { useGlobalState } from '../../context/global'
import { useState } from 'react'
import { useError } from '../../context/error'
import { useSuccess } from '../../context/success'
import { UserService } from '../../services/user.service'
import LoadingButton from '../../components/controls/loading-btn'
import ComingSoon from '../../components/comingsoon'
import WebPush from '../../components/webpush'
import { LocalStorageHelper } from '../../_helpers/localstorage.helper'

export default function NotificationsPage (props: PageProps) {
  const { user, setUser } = useGlobalState()
  const { setError } = useError()

  const { setSuccess } = useSuccess()

  const [runningHealth, setRunningHealth] = useState(false)
  const [runningRejoin, setRunningRejoin] = useState(false)
  const [runningReSub, setRunningReSub] = useState(false)

  const userService = new UserService(setError)

  const _formatTxt = (value?: boolean) => {
    if (value === null || value === undefined) {
      return 'unknown'
    }

    return value === true ? 'YES' : 'NO'
  }

  const _runResub = async () => {
    setRunningReSub(true)
    const res = await userService.klaviyoResubscribe()
    if (res.user) {
      setUser(res.user)
      setSuccess('Links Sent, Check your email & phone for activation links')
    }

    setRunningReSub(false)
  }

  const _runHealthCheck = async () => {
    setRunningHealth(true)
    const res = await userService.klaviyoHealthCheck()
    if (res.user) {
      setUser(res.user)
      setSuccess('Email & SMS Health Updated')
    }

    setRunningHealth(false)
  }

  const _rejoinList = async (type: 'email' | 'sms') => {
    setRunningRejoin(true)
    const res = await userService.rejoinList(type)
    if (res.user) {
      setUser(res.user)
      setSuccess(`Successfully rejoined ${type} list`)
    }

    setRunningRejoin(false)
  }

  return (
    <Container sx={{ p: 3 }}>
      <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
        Notifications
      </Typography>
      <Typography variant='body2'>
        Manage notification channels and settings
      </Typography>
      <StyleHelper.VerticalSpace />

      <CarnivoreBox>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Email & SMS
            </Typography>
            <Typography variant='caption'>
              Last Checked{' '}
              {user?.klaviyoStatus?.lastCheck
                ? new Date(user?.klaviyoStatus?.lastCheck).toLocaleString(
                    'en-US',
                    {
                      dateStyle: 'short',
                      timeStyle: 'short'
                    }
                  )
                : 'unknown'}
            </Typography>
          </Box>
          <LoadingButton
            disabled={runningHealth}
            loading={runningHealth}
            onClick={_runHealthCheck}
            variant='outlined'
          >
            Refresh Status
          </LoadingButton>
        </Box>

        <StyleHelper.DoubleVerticalSpace />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              On Email List
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {_formatTxt(user?.klaviyoStatus?.isOnEmailList)}
            </Typography>
            {user?.klaviyoStatus?.isOnEmailList === false && (
              <>
                <StyleHelper.SmallVerticalSpace />
                <LoadingButton
                  variant='outlined'
                  loading={runningRejoin}
                  disabled={runningRejoin}
                  onClick={() => _rejoinList('email')}
                >
                  Join List
                </LoadingButton>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Email Subscribed
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {_formatTxt(user?.klaviyoStatus?.isEmailSubscribed)}
            </Typography>
            {user?.klaviyoStatus?.isEmailSubscribed === false && (
              <>
                <StyleHelper.SmallVerticalSpace />
                <LoadingButton
                  variant='outlined'
                  loading={runningReSub}
                  disabled={runningReSub}
                  onClick={() => _runResub()}
                >
                  Send Invite Link
                </LoadingButton>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              On SMS List
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {_formatTxt(user?.klaviyoStatus?.isOnSMSList)}
            </Typography>
            {user?.klaviyoStatus?.isOnSMSList === false && (
              <>
                <StyleHelper.SmallVerticalSpace />
                <LoadingButton
                  variant='outlined'
                  loading={runningRejoin}
                  disabled={runningRejoin}
                  onClick={() => _rejoinList('sms')}
                >
                  Join List
                </LoadingButton>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              SMS Subscribed
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {' '}
              {_formatTxt(user?.klaviyoStatus?.isSMSSubscribed)}
            </Typography>
            {user?.klaviyoStatus?.isSMSSubscribed === false && (
              <>
                <StyleHelper.SmallVerticalSpace />
                <LoadingButton
                  variant='outlined'
                  loading={runningReSub}
                  disabled={runningReSub}
                  onClick={() => _runResub()}
                >
                  Send Invite Link
                </LoadingButton>
              </>
            )}
          </Grid>
        </Grid>
      </CarnivoreBox>

      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Browser Alerts
            </Typography>
            <Typography variant='caption'>
              Receive Notifications When Your Browser Is Open
            </Typography>
          </Box>
          <WebPush btnDisplay={true} />
        </Box>
        <StyleHelper.DoubleVerticalSpace />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Enabled
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {user?.browserNotification ? 'YES' : 'NO'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            height={'100%'}
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Receiving on this Device
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {user?.browserNotification?.deviceId ===
              LocalStorageHelper.getDeviceID()
                ? 'YES'
                : 'NO'}
            </Typography>
          </Grid>
        </Grid>
      </CarnivoreBox>

      <StyleHelper.VerticalSpace />
    </Container>
  )
}
