import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class EventService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async searchEvents (
    page: number,
    startDate?: string,
    endDate?: string,
    eventType?: string,
    limit?: number
  ) {
    return axiosInstance
      .get('/event/search', {
        params: {
          startDate,
          endDate,
          page,
          eventType,
          limit
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getEventById (id: string) {
    return axiosInstance
      .get('/event', {
        params: {
          id
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
