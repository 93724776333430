import { CalendarMonth } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'

interface AddToCalendarBtnProps {
  title: string
  description: string
  startTime: string
  endTime: string
  fileName: string
  url?: string
}
export default function AddToCalendarBtn (props: AddToCalendarBtnProps) {
  const _downloadIcsFile = (data: string) => {
    const blob = new Blob([data], { type: 'text/calendar' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = props.fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function _generateIcsEvent () {
    const formatDate = (dateStr: string) => {
      return (
        new Date(dateStr)
          .toISOString()
          .replace(/-|:|\.\d{3}/g, '')
          .slice(0, 15) + 'Z'
      )
    }

    const icsData = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `UID:${Date.now()}@carnivoretrading.com`,
      `DTSTAMP:${formatDate(new Date().toISOString())}`,
      `DTSTART:${formatDate(props.startTime)}`,
      `DTEND:${formatDate(props.endTime)}`,
      `SUMMARY:${props.title}`,
      props.url ? `URL:${props.url}` : '',
      `DESCRIPTION:${props.description}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n')

    return icsData
  }

  const _handleAddToCalendar = () => {
    const icsData = _generateIcsEvent()
    _downloadIcsFile(icsData)
  }

  return (
    <Tooltip title='Add to Calendar'>
      <IconButton onClick={_handleAddToCalendar}>
        <CalendarMonth />
      </IconButton>
    </Tooltip>
  )
}
