import { KnowledgePiece } from '../../_types/knowledge.interface'
import { BuyHoldKnowledge } from './pages/buy-hold.knowledge'
import { SectorHeatersKnowlege } from './pages/sector-heaters.knowledge'
import { AccountBalanceKnowledge } from './pieces/account-balance.knowledge'

const Knowledges: KnowledgePiece[] = [
  SectorHeatersKnowlege,
  AccountBalanceKnowledge,
  BuyHoldKnowledge
]

export default Knowledges
