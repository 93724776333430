import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode
} from 'react'
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  Theme
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

interface ThemeContextType {
  toggleColorMode: () => void
  mode: string
}

const defaultState = {
  toggleColorMode: () => {},
  mode: 'light' // Default mode
}

const ThemeContext = createContext<ThemeContextType>(defaultState)

export const useTheme = () => useContext(ThemeContext)

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [mode, setMode] = useState<'light' | 'dark'>(
    (localStorage.getItem('carnivore-theme') as 'light' | 'dark') ?? 'dark'
  )

  const colorMode = useMemo<ThemeContextType>(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => {
          let value =
            prevMode === 'light' ? 'dark' : ('light' as 'light' | 'dark')
          localStorage.setItem('carnivore-theme', value)
          return value
        })
      },
      mode
    }),
    [mode]
  )

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#e25a0b'
      },
      secondary: {
        main: '#ffffff'
      },
      info: {
        main: '#e25a0b'
      }
    },
   
    // components: {
    //   MuiModal: {
    //     styleOverrides: {
    //       root: {
    //         '& .MuiBox-root': {
    //           backgroundColor: '#252525' // Set your desired background color here
    //         }
    //       }
    //     }
    //   }
    // }
  })

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#e25a0b'
      },
      secondary: {
        main: '#000000'
      },
      info: {
        main: '#e25a0b'
      }
    }
  })

  const theme: Theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                // Light mode colors
                background: {
                  default: '#f7f7f7', // Light gray for page background
                  paper: '#ffffff' // White for component backgrounds
                },
                text: {
                  primary: '#2e2e2e', // Dark gray for primary text
                  secondary: '#575757' // Medium gray for secondary text
                }
              }
            : {
                // Dark mode colors
                background: {
                  default: '#121212', // Very dark gray (almost black) for page background
                  paper: '#1e1e1e' // Slightly lighter gray for component backgrounds
                },
                text: {
                  primary: '#F5F5F5', // White for primary text
                  secondary: '#b3b3b3' // Light gray for secondary text
                }
              })
        }
      }),
    [mode]
  )

  return (
    <ThemeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />

        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  )
}
