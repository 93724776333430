import { Box, Typography } from '@mui/material'
import { KnowledgePiece } from '../../../_types/knowledge.interface'
import { StyleHelper } from '../../style-helper'

export const AccountBalanceKnowledge: KnowledgePiece = {
  id: 'account-balance',
  title: 'Account Balance',
  content: (
    <Box>
      <Typography variant='body1'>
        We use this number to prioritize your financial journey. When we ask for
        your estimated account balance, it's all about tailoring your
        experience.
      </Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body1'>
        This information helps us personalize our recommendations and match our
        trades to your account balance ensuring a seamless and customized
        experience every step of the way.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1'>
        Rest assured, your privacy is paramount, and your data is kept
        confidential. Plus, you can always update your balance as it changes.
      </Typography>
    </Box>
  )
}
