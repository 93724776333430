import {
  Alert,
  Box,
  Modal,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { CarnivoreModalProps } from '../_types/modal-props.interface'
import { FormEvent, useEffect, useState } from 'react'
import { Email } from '@mui/icons-material'
import { StyleHelper } from './style-helper'
import { useGlobalState } from '../context/global'
import LoadingButton from './controls/loading-btn'
import { useError } from '../context/error'
import { UserService } from '../services/user.service'
import { useTextInput } from '../hooks/useTextField'
import { useSuccess } from '../context/success'

interface VerifyEmailProps {
  onComplete: () => void
  onSkip?: () => void
}
export default function VerifyEmail (props: VerifyEmailProps) {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [countdown, setCountdown] = useState<number>(0)
  const [showTimer, setShowTimer] = useState<boolean>(false)
  const [showResend, setShowResend] = useState<boolean>(false)

  const verificationCodeInput = useTextInput({
    initialValue: '',
    validate: (value: string) => value.length === 6,
    errMsg: 'Verification Code must be 6 digits long'
  })
  const { user, setUser } = useGlobalState()
  const { setError } = useError()
  const { setSuccess } = useSuccess()

  const [err, setErr] = useState('')
  const userService = new UserService(setError)
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (showTimer && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else if (showTimer && countdown === 0) {
      setShowTimer(false)
      setShowResend(true)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [showTimer, countdown])

  useEffect(() => {
    _sendVerificationCode()
  }, [])

  const _sendVerificationCode = async () => {
    setLoading(true)

    await userService.sendVerificationCode()
    _startCountdown()
    setLoading(false)
  }

  const _startCountdown = () => {
    setShowResend(false)
    setCountdown(45)
    setShowTimer(true)
  }

  const _verifyCode = async (e: FormEvent) => {
    e.preventDefault()
    setErr('')
    setSubmitting(true)
    const res = await userService.verifyEmail(verificationCodeInput.value)
    if (!res.user) {
      setErr('Email Code Invalid')
    } else {
      props.onComplete()
      setSuccess('Email Verified, you will now start receiving email alerts')
    }
    setSubmitting(false)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Email sx={{ fontSize: 75 }} />

      <Typography variant='h5' fontWeight={'bold'}>
        Verify Your Email
      </Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body1'>
        In order to receive email alerts, you need to verify your email
      </Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body1'>
        We just send a verification email to <strong>{user?.email}</strong>.
        Please enter the verification
      </Typography>
      <StyleHelper.VerticalSpace />
      <Alert color='info'>
        <Typography variant='body1' fontWeight={'bold'}>
          Once verified, we will send you opt in links to receive email and text
          alerts.
        </Typography>
      </Alert>
      <StyleHelper.DoubleVerticalSpace />
      
      <form onSubmit={_verifyCode}>
        <Box display={'flex'} flexDirection={'column'}>
          <TextField
            label='Verification Code'
            value={verificationCodeInput.value}
            onChange={verificationCodeInput.onChange}
            error={verificationCodeInput.error}
            inputProps={{
              maxLength: 6
            }}
          />
          <StyleHelper.VerticalSpace />

          <LoadingButton
            loading={submitting}
            disabled={submitting || verificationCodeInput.value.length !== 6}
            variant='contained'
            sx={{ alignSelf: 'center' }}
            type='submit'
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
      <StyleHelper.VerticalSpace />
      {showTimer && !showResend && (
        <Typography variant='body2'>
          Didn't receive an email? Resend in{' '}
          <strong>{countdown} seconds.</strong>{' '}
        </Typography>
      )}
      {showResend && (
        <LoadingButton loading={loading} onClick={_sendVerificationCode}>
          Resend Verification Code
        </LoadingButton>
      )}
    </Box>
  )
}
