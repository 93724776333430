import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useGlobalState } from '../../../context/global'
import { useEffect, useState } from 'react'
import { useError } from '../../../context/error'
import { PaymentService } from '../../../services/payment.service'
import CarnivoreBox from '../../../components/carnivore-box'
import { StyleHelper } from '../../../components/style-helper'
import { Formatters } from '../../../_helpers/formatter.helper'
import { AddCircle } from '@mui/icons-material'
import WooSubLoadingPage from '../../../components/loading/woo-sub.loading'
import useBoolean from '../../../hooks/useBoolean'
import AddCardModal from '../../../components/modals/add-card.modal'
import CancellationModal from '../../../components/modals/cancellation.modal'

export default function WooSubPage () {
  const { settings, user } = useGlobalState()
  const { setError } = useError()

  const [subscriptionInfo, setSubscriptionInfo] = useState<null | any>(null)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const [invoices, setInvoices] = useState<any[]>([])

  const [loading, setLoading] = useState(true)
  const paymentService = new PaymentService(setError)
  const [showAddCard, { toggle: toggleShowCard }] = useBoolean(false)
  const [showCancellation, { toggle: toggleCancellation }] = useBoolean(false)

  const _fetchSubInfo = async () => {
    setLoading(true)
    const res = await paymentService.getWooSubscription()
    if (res.subscription) {
      setSubscriptionInfo(res.subscription)
    }
    const pmRes = await paymentService.getPaymentMethods()
    if (pmRes) {
      setPaymentMethods(pmRes)
    }

    const invoiceRes = await paymentService.getWooInvoices()
    if (invoiceRes?.invoices) {
      setInvoices(invoiceRes?.invoices ?? [])
    }
    setLoading(false)
  }

  const _refreshPaymentMethods = () => {
    setLoading(true)
    paymentService
      .getPaymentMethods()
      .then(pm => {
        setPaymentMethods(pm ?? [])
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }

  useEffect(() => {
    _fetchSubInfo()
  }, [])
  if (loading) {
    return <WooSubLoadingPage />
  }
  return (
    <Container sx={{ p: 3 }}>
      <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
        Billing
      </Typography>
      <Typography variant='body2'>
        Manage Your Subscription, billing and payment details.
      </Typography>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
          Overview
        </Typography>
        <StyleHelper.VerticalSpace />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              PLAN
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.billing_period === 'month'
                ? 'Monthly'
                : 'Annual'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Status
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.status?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              p: 2,

              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              {subscriptionInfo?.status === 'active'
                ? 'Next Billing Date'
                : 'Subscription Ends'}
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.next_payment_date_gmt
                ? new Date(
                    subscriptionInfo?.next_payment_date_gmt
                  ).toLocaleDateString('en-US', { dateStyle: 'medium' })
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ p: 2 }}>
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Next Payment Amount
              <Typography variant='h5' fontWeight={'bold'}>
                $
                {Formatters.formatMoney(
                  subscriptionInfo?.total ? Number(subscriptionInfo?.total) : 0
                )}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Payment
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} alignItems={'flex-end'}>
            {/* <Box display='flex' justifyContent='flex-end'>
              <Button
                color='secondary'
                endIcon={<AddCircle />}
                variant='outlined'
                onClick={toggleShowCard}
              >
                Add Payment Method
              </Button>
            </Box> */}
          </Grid>
        </Grid>

        <StyleHelper.SmallVerticalSpace />
        <TableContainer>
          <Table aria-label='payment methods'>
            <TableHead>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods?.map((pm: any, index: number) => (
                <TableRow key={pm?.id}>
                  <TableCell>{pm?.card?.brand.toUpperCase()}</TableCell>
                  <TableCell>XXXX XXXX XXXX {pm?.card?.last4}</TableCell>
                  <TableCell>
                    {pm?.card?.exp_month}/{pm?.card?.exp_year}
                  </TableCell>
                  <TableCell>
                    {/* <Chip label='Make Default' onClick={() => {}} /> */}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
          Past Invoices
        </Typography>
        <StyleHelper.VerticalSpace />

        <TableContainer>
          <Table aria-label='payment methods'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice: any) => (
                <TableRow key={invoice.id}>
                  <TableCell>
                    {new Date(invoice.date_completed)?.toLocaleDateString(
                      'en-US',
                      { dateStyle: 'medium' }
                    )}
                  </TableCell>
                  <TableCell>
                    ${Formatters.formatMoney(Number(invoice.total))}
                  </TableCell>
                  <TableCell>
                    {invoice?.line_items
                      ?.map((li: any) => `${li.quantity} x ${li.name}`)
                      ?.join(', ')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
      <StyleHelper.DoubleVerticalSpace />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant='outlined' color='primary' onClick={toggleCancellation}>
          Cancel Subscription
        </Button>
      </Box>
      <AddCardModal
        open={showAddCard}
        onClose={toggleShowCard}
        size='md'
        onComplete={_refreshPaymentMethods}
      />
      {showCancellation && (
        <CancellationModal
          onCancel={() => _fetchSubInfo()}
          open={showCancellation}
          onClose={toggleCancellation}
          size='md'
        />
      )}
    </Container>
  )
}
