import {
  Box,
  Grid,
  Modal,
  Skeleton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { CarnivoreEvent } from '../../_types/carnivore-event.type'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { useEffect, useState } from 'react'
import { useError } from '../../context/error'
import { FileService } from '../../services/files.service'
import {
  Alarm,
  ArrowRightAltRounded,
  CalendarMonth,
  Campaign,
  LiveTv,
  School,
  VideoCall,
  Videocam
} from '@mui/icons-material'
import { StyleHelper } from '../style-helper'
interface EventModalProps extends CarnivoreModalProps {
  event: CarnivoreEvent
}

export default function EventModal (props: EventModalProps) {
  const { event } = props
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [imageUrl, setImageUrl] = useState('')
  const { setError } = useError()
  const fileService = new FileService(setError)
  const [hasImg, setHasImg] = useState(props.event.imgID ? true : false)
  const [loading, setLoading] = useState(true) // Assume loading by default

  useEffect(() => {
    _fetchImage()
  }, [])
  const _fetchImage = async () => {
    if (event.imgID) {
      const res = await fileService.getPresignedUploadURL(event.imgID)
      if (res.url) {
        setImageUrl(res.url)
        setHasImg(true)
      }
    }
    setLoading(false)
  }

  const _getIcon = () => {
    switch (event.eventType) {
      case 'news':
        return <Campaign />
      case 'member-call':
        return <VideoCall />
      case 'academy':
        return <School />
      case 'virtual-academy':
        return <Videocam />
      case 'livestream':
        return <LiveTv />
      default:
        return <CalendarMonth />
    }
  }

  const _getImgSrc = () => {
    const fontSize = 60
    switch (event.eventType) {
      case 'news':
        return require('../../assets/imgs/events/news-appearance.webp')
      case 'member-call':
        return require('../../assets/imgs/events/zoom.webp')
      case 'academy':
        return require('../../assets/imgs/events/academy.webp')
      case 'virtual-academy':
        return require('../../assets/imgs/events/virutal-academy.webp')
      case 'livestream':
        return require('../../assets/imgs/events/live.webp')
      default:
        return require('../../assets/imgs/events/general.webp')
    }
  }

  function _isToday (date: Date): boolean {
    const today = new Date()

    // Compare the year, month, and day components
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (props.size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          overflowY: 'scroll',
          maxHeight: '80vh', // Adjust as needed
          p: 4
        }}
        display={'flex'}
        flexDirection={'column'}
   
      >
        {loading ? (
          <Skeleton
            variant='rectangular'
            width={'100%'}
            height={300}
            animation='wave'
          />
        ) : (
          <Box
            component='img'
            width={'100%'}
            src={hasImg ? imageUrl : _getImgSrc()}
            alt='Dynamic Content'
            onLoad={() => setLoading(false)} // Ensure to set loading false when the image is loaded
          />
        )}
        <StyleHelper.DoubleVerticalSpace />
        <Typography variant='body1'>
          {new Date(event.startTime).toLocaleString('en-US', {
            dateStyle: 'short',
            timeStyle: 'short'
          })}
          &nbsp;-&nbsp;
          {new Date(event.endTime).toLocaleString('en-US', {
            dateStyle: 'short',
            timeStyle: 'short'
          })}
        </Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='h3'>{event.title}</Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='body2'>{event.shortDescription}</Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='body1'>{event.longDescription}</Typography>
      </Box>
    </Modal>
  )
}
