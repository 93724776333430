import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
  Typography
} from '@mui/material'
import { useTheme } from '../context/theme'
import ThemeSwitch from '../components/theme-switch'
import CarnivoreLogoTxt from '../components/imgs/carnivore-logo-txt'
import styled from '@emotion/styled'
import { useTextInput } from '../hooks/useTextField'
import { StyleHelper } from '../components/style-helper'
import useBoolean from '../hooks/useBoolean'
import { ArrowRightAlt, Visibility, VisibilityOff } from '@mui/icons-material'
import LegalBanner from '../components/legal'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useNavigate } from 'react-router-dom'
import { FormEvent, useState } from 'react'
import { UserService } from '../services/user.service'
import { useError } from '../context/error'
import { useGlobalState } from '../context/global'
import useGetPortfolioReturns from '../hooks/useGetPortfolioReturns'
import { AnalyticsActions, AnalyticsCategories, AnalyticsHelper } from '../_helpers/analytics.helper'

const ResponsiveCircleGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 0,
    width: '80%', // Adjust size accordingly
    height: '80%', // Adjust size accordingly
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translate(50%, -50%)' // Adjust to control the positioning
  }
}))

export default function LoginPage(props: PageProps) {
  const { mode } = useTheme()
  useDocumentTitle(props.title)

  const emailInput = useTextInput({
    initialValue: ''
  })

  const passwordInput = useTextInput({
    initialValue: ''
  })

  const { setError } = useError()
  const [showPassword, { toggle }] = useBoolean()
  const [loading, setLoading] = useState(false)
  const userService = new UserService(setError)
  const navigate = useNavigate()
  const { errorMessage, loading: isLoadingReturns, returnPercentage } = useGetPortfolioReturns();

  const _submitLogin = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    AnalyticsHelper.logEvent(AnalyticsActions.btn_click, AnalyticsCategories.auth, "Login")

    const resp = await userService.auth(emailInput.value, passwordInput.value)

    if (resp.user) {

      if (resp.needsSync) {
        navigate('/signup/sync')
      } else {
        window.location.reload()

      }
    }
    setLoading(false)
  }
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Box
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Box sx={{ width: '50%' }}>
              <CarnivoreLogoTxt />
            </Box>
            <form onSubmit={_submitLogin}>
              <Box sx={{ width: '100%', p: 4 }}>
                <Typography
                  variant='subtitle1'
                  sx={{ mb: 2 }}
                  fontWeight={'bold'}
                >
                  Login
                </Typography>
                <TextField
                  label='Email'
                  required
                  variant='outlined'
                  type='email'
                  fullWidth
                  value={emailInput.value}
                  error={emailInput.error}
                  helperText={emailInput.helperText}
                  onChange={emailInput.onChange}
                />
                <StyleHelper.DoubleVerticalSpace />
                <TextField
                  label='Password'
                  variant='outlined'
                  required
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  value={passwordInput.value}
                  error={passwordInput.error}
                  helperText={passwordInput.helperText}
                  onChange={passwordInput.onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={toggle}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <StyleHelper.DoubleVerticalSpace />

                <Grid container sx={{ alignItems: 'center' }}>
                  <Grid item xs={6}>
                    <Button
                      disabled={loading}
                      variant='contained'
                      type='submit'
                      endIcon={
                        loading ? (
                          <CircularProgress size={16} />
                        ) : (
                          <ArrowRightAlt />
                        )
                      }
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid item xs={6} sx={{ alignItems: 'flex-end' }}>
                    <Button
                      variant='text'
                      sx={{ alignSelf: 'flex-end', color: 'text.primary' }}
                      onClick={() => {
                        AnalyticsHelper.logEvent(AnalyticsActions.btn_click, AnalyticsCategories.auth, "Forgot Password Start")
                        navigate('/forgot')
                      }}
                    >
                      Forgot Your Password?
                    </Button>
                  </Grid>
                </Grid>
                {/* <StyleHelper.DoubleVerticalSpace />
                <Button
                  onClick={() => navigate('/signup')}
                  variant='contained'
                  color='secondary'
                  fullWidth
                >
                  Sign Up Now
                </Button> */}
              </Box>
            </form>
            <LegalBanner />
          </Box>
        </Grid>
        <ResponsiveCircleGrid
          item
          xs={12}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            bgcolor: 'primary.main',
            p: 3
          }}
        >
          <Box sx={{ alignSelf: 'flex-end' }}>
            <ThemeSwitch />
          </Box>
          <Box sx={{ px: 6 }}>
            <Paper elevation={3} sx={{ p: 2, zIndex: 5, position: 'relative' }}>
              <Grid container>
                <Grid item xs={8}>
                  <Box pl={3} pt={2}>


                    <Typography variant='h4' fontWeight={'bold'}>
                      Welcome to Carnivore Trading
                    </Typography>
                    <StyleHelper.VerticalSpace />

                    <Typography variant='body1'>
                      Hedgefund quality research. High-conviction stock ideas.
                      Daily market analysis. Real-time trade alerts.
                    </Typography>
                    <StyleHelper.VerticalSpace />

                    <Typography variant='body1'>
                      A place for traders of all levels to win, learn, and
                      celebrate together.
                    </Typography>
                    <StyleHelper.DoubleVerticalSpace />
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() =>
                        window.open('https://carnivoretrading.com', '_blank')
                      }
                    >
                      Learn More
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ p: 5 }}>
                    <CarnivoreLogo />
                  </Box>
                </Grid>
              </Grid>
              {/* Additional Paper component positioned relative to the first Paper */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translateY(50%)'
                }}
              >
                <Paper elevation={8} sx={{ p: 2 }}>
                  {/* Content for the bottom right Paper */}
                  {isLoadingReturns ? <Skeleton variant='text' height={'1rem'} width={'4rem'} /> : !errorMessage ?
                    <Typography
                      variant='body1'
                      fontWeight={'bold'}
                      color={'primary'}
                    >
                      {returnPercentage > 0 ? "+" : "+-"} {returnPercentage}%
                    </Typography> : ""
                  }
                </Paper>
              </Box>
            </Paper>
          </Box>
          <Box />
          {/* <Typography variant='h3'>
            Wall Street's Best {' '}
            <TypewriterText
              textArray={[
                'Stock Ideas',
                'Investment Research',
                'Stock Reports',
                'Long-term Buys',
                'Market Reports',
                'Macro Analysis',
                'Technical Analysis',
                'Swing Trades'
              ]}
              typingSpeed={100}
            />
            {' '}
            for Traders and Active Investors
          </Typography> */}
        </ResponsiveCircleGrid>
      </Grid>
    </Box>
  )
}
