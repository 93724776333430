import { Box } from '@mui/material'
import { useTheme } from '../../context/theme'

export default function SP500Img () {
  const { mode } = useTheme() // Now mode is correctly provided from the context

  return (
    <Box
      component='img'
      sx={{
        width: '100%', // Makes the image responsive
        height: 'auto',
        objectFit: 'contain' // Maintains aspect ratio
        // Maintains aspect ratio
      }}
      src={
        mode === 'light'
          ? require('../../assets/imgs/wrap/sp500-light.png')
          : require('../../assets/imgs/wrap/sp500-dark.png')
      }
      alt='Description'
    />
  )
}
