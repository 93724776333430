import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class StockService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async getStockLogo (
    ticker : string
  ) {
    return axiosInstance
      .get('/stock/logo', {
        params: {
          ticker
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getStockInfo (
    ticker : string
  ) {
    return axiosInstance
      .get('/stock/info', {
        params: {
          ticker
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
