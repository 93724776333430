import React, { useEffect, useState, useRef } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { WrapNav } from '../_types/wrap-nave.type'
import { useTheme } from '@mui/material'

interface ScrollableListProps {
  items: WrapNav
}

const WrapNavigation: React.FC<ScrollableListProps> = ({ items }) => {
  const [activeId, setActiveId] = useState<string | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)
  const observedElements = useRef<HTMLElement[]>([])

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }

    observer.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    )

    const elements = items.sections.flatMap(item => [
      document.getElementById(item.targetId),
      ...item.subSections.map(sub => document.getElementById(sub.targetId))
    ])

    elements.forEach(element => {
      if (element) {
        observer.current?.observe(element)
        observedElements.current.push(element)
      }
    })

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observedElements.current = []
    }
  }, [items])

  const handleClick = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      const top = element.getBoundingClientRect().top + window.pageYOffset - 75 // Offset by 75px
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    }
  }

  const theme = useTheme()
  return (
    <List>
      {items.sections.map((item, i) => (
        <React.Fragment key={`wrap-${i}`}>
          <ListItem disablePadding>
            <ListItemButton
              color='primary'
              onClick={() => handleClick(item.targetId)}
              selected={activeId === item.targetId}
            >
              <ListItemText
                sx={{ color: theme.palette.primary.main }}
                primary={item.title}
              />
            </ListItemButton>
          </ListItem>
          {item.subSections.map((sub, d) => (
            <ListItem key={`wrap-${i}-${d}`} disablePadding>
              <ListItemButton
                onClick={() => handleClick(sub.targetId)}
                selected={activeId === sub.targetId}
              >
                <ListItemText primary={sub.title} sx={{ pl: 2 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </List>
  )
}

export default WrapNavigation
