import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import SignupSideBar, { ImgType } from '../../components/signup-sidebar'
import SignupHeader from '../../components/signup-header'
import { StyleHelper } from '../../components/style-helper'
import { useTextInput } from '../../hooks/useTextField'
import { ArrowRightAlt } from '@mui/icons-material'

export default function SignupEmailVerify (props: PageProps) {
  useDocumentTitle(props.title)

  const fNameInput = useTextInput({
    initialValue: ''
  })

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Box
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',

              height: '100%'
            }}
          >
            <SignupHeader
              header='Verify your email'
              body={
                'Please enter the verification code sent to tyler@techitchlabs.com to continue with your signup process.'
              }
            />
            <StyleHelper.DoubleVerticalSpace />
            <Divider />
            <StyleHelper.DoubleVerticalSpace />

            <Grid container justifyContent={'center'}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Verification Code'
                  variant='outlined'
                  fullWidth
                  value={fNameInput.value}
                  error={fNameInput.error}
                  helperText={fNameInput.helperText}
                  onChange={fNameInput.onChange}
                />
                <StyleHelper.DoubleVerticalSpace />
                <Box
                  sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button variant='contained' endIcon={<ArrowRightAlt />}>
                    Verify
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            bgcolor: 'action.hover',

            height: '100%'
          }}
        >
          <SignupSideBar imgType={ImgType.email} />
        </Grid>
      </Grid>
    </Box>
  )
}
