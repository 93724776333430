import { Box, Typography, Button } from '@mui/material'
import { DashboardLink } from '../_types/dashboard-link.type'
import { StyleHelper } from './style-helper'
import { ArrowRightAlt } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AnalyticsActions, AnalyticsCategories, AnalyticsHelper } from '../_helpers/analytics.helper'

interface DashboardLinkProps {
  link: DashboardLink
}

export default function DashboardLinkView(props: DashboardLinkProps) {

  const navigate = useNavigate()
  return (
    <Box height={"100%"} display={'flex'} flexDirection={"column"} justifyContent={'space-between'}>
      {props.link.icon}
      <Typography variant='h6' fontWeight={'bold'}>
        {props.link.title}
      </Typography>
      <Typography variant='body2'>{props.link.supportingTxt}</Typography>
      <StyleHelper.DoubleVerticalSpace />
      <Button variant='contained' onClick={() => {
        AnalyticsHelper.logEvent(AnalyticsActions.btn_click, AnalyticsCategories.dashboard, 'Dashboard Quick Link')
        navigate(props.link.link)
      }} endIcon={<ArrowRightAlt />}>
        {props.link.btnTxt}
      </Button>
    </Box>
  )
}
