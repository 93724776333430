import { Box, Typography } from '@mui/material'
import { KnowledgePiece } from '../../../_types/knowledge.interface'
import { StyleHelper } from '../../style-helper'

export const SectorHeatersKnowlege: KnowledgePiece = {
  id: 'sector-heaters',
  title: 'Sector Heaters',
  content: (
    <Box>
      <Typography variant='body1'>
        Below you see will see several sector boxes on stocks in a sector. The
        sector is picked because we think it's an "up and coming" sector that
        will see an inflow of capital over the next quarter or so.
      </Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body1'>
        When we initiate it, we pick what we think are quality stocks
        representing the sector. What we would do, if it were us, is track the
        performance of all the stocks which you can do by watching this group
        every day.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1'>
        We would buy an equal amount of each stock.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1'>
        When there is a new "box" issued, we buy equal amount of each stock and
        wait 2 weeks. Then we take the 3-4-5 that are down, and sell them. We
        take that money, and we add to the winning top 3-4-5 stocks. We then do
        this EVERY week after that. For 3-4-5 weeks. Maybe a bit longer if the
        sector continues to rise.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1'>
        That will leave us with a smaller group of stocks which theoretically
        should be doing well as the sector advances. The “best behaving”
        strongest stocks in the group. At some point, we sell the group and move
        to a new sector or "box" that has been issued.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1' fontWeight={'bold'}>
        This methodology of selling the losers and buying more of the winners
        can enhance the overall return substantially versus simply leaving all
        the positions we initiated in place without applying this technique.
      </Typography>

      <StyleHelper.DoubleVerticalSpace />
    </Box>
  )
}
