import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import KnowledgeBtn from '../../components/controls/knowledge-btn'
import { StyleHelper } from '../../components/style-helper'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'
import { useEffect, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
import LoadingButton from '../../components/controls/loading-btn'
import { Alarm, ArrowRightAltRounded, Close, DateRange, FilterAlt, GridView, TableView } from '@mui/icons-material'
import { WrapService } from '../../services/wrap.service'
import { useError } from '../../context/error'
import EventCardLoading from '../../components/loading/event-card.loading'
import WrapCard from '../../components/wrapcard'
import { useNavigate } from 'react-router-dom'
import LoadingTable from '../../components/loading/table.loading'
import { AnalyticsActions, AnalyticsCategories, AnalyticsHelper } from '../../_helpers/analytics.helper'

interface WrapPageParams {
  startDate?: string
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function WrapPage(props: PageProps) {
  useDocumentTitle(props.title)
  const { setError } = useError()

  const [queryParams, setQueryParams] = useQueryParamsV2<WrapPageParams>()
  const [loading, setLoading] = useState(true)
  const [wraps, setWraps] = useState<DailyWrap[]>([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const [view, setView] = useState<string>(window.localStorage.getItem('wrap-view') ?? 'grid');

  const theme = useTheme()
  const wrapService = new WrapService(setError)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const navigate = useNavigate()

  const [startDate, setStartDate] = useState<string>(
    queryParams.startDate ?? formatDate(yesterday)
  )

  useEffect(() => {
    _fetchWraps(1, startDate)
  }, [])

  const _fetchWraps = async (p: number, startD: string) => {
    setPage(p)
    const start = new Date(startD)
    setLoading(true)

    setQueryParams({
      startDate: startD
    })
    const results = await wrapService.searchWraps(p, start.toISOString())
    if (results.docs) {
      setWraps(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
    }
    setLoading(false)

  }

  function _isToday(date: string): boolean {
    const today = new Date()
    const formatted = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}-${today.getFullYear().toString().slice(-2)}`;

    return date === formatted
  }

  function formatDayOfWeek(date: string) {
    try {
      const day = new Date(date).toLocaleDateString('en-US', { weekday: 'long' })
      return day
    } catch (e) {
      return ""
    }

  }

  function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box display='flex' alignItems='center' gap={2}>
        <Typography variant='h4'>Daily Wraps</Typography>
      </Box>
      <StyleHelper.VerticalSpace />
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item lg={6}>
          <Paper
            sx={{
              border: `1px solid ${theme.palette.divider}`
            }}
          >
            <Box p={2}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Filters
              </Typography>
              <StyleHelper.VerticalSpace />
              <Grid container spacing={2} alignItems='center'>
                <Grid item lg={6}>
                  <TextField
                    label='Start Date'
                    type='date'
                    fullWidth
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item lg={6}>
                  <LoadingButton
                    variant='contained'
                    endIcon={<FilterAlt />}
                    onClick={() => _fetchWraps(1, startDate)}
                    loading={loading}
                    disabled={loading}
                  >
                    Apply Filter
                  </LoadingButton>
                  <IconButton
                    onClick={() => {
                      setStartDate(formatDate(yesterday))
                      _fetchWraps(1, formatDate(yesterday))
                    }}
                    disabled={loading}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>

            </Box>
          </Paper>
        </Grid>
        <Grid item lg={2}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(event, next) => {
              window.localStorage.setItem('wrap-view', next)
              setView(next)
              AnalyticsHelper.logEvent(AnalyticsActions.toggle, AnalyticsCategories.preference, `Wrap View ${next}`)

            }}
            aria-label="view toggle"
          >
            <Tooltip title="Switch to Table View" arrow>

              <ToggleButton value="table" aria-label="table view">
                <TableView />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Switch to Grid View" arrow>

              <ToggleButton value="grid" aria-label="grid view">
                <GridView />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />

      {view === 'table' && <>
        {loading && <LoadingTable rowCount={5} columnCount={5} />}

        {!loading && wraps && <><TableContainer component={Paper} sx={{ maxHeight: '800px', overflow: 'auto' }}>
          <Table aria-label='wrap table'>
            <TableHead
              sx={{
                '& th': {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  position: 'sticky',  // Make the headers sticky
                  top: 0,              // Set it to stick at the top
                  zIndex: 20
                }
              }}
            >
              <TableRow>
                <TableCell  >Date</TableCell>
                <TableCell  >Day</TableCell>
                <TableCell></TableCell>
              </TableRow>

            </TableHead>
            <TableBody>
              {wraps?.map(wrap => (
                <StyledTableRow key={wrap.id}>
                  <TableCell>{new Date(wrap.date ?? "").toLocaleDateString('en-US', { dateStyle: 'medium' })} {_isToday(wrap.formattedDate ?? "") && <Chip
                    label="Today's Wrap"
                    icon={<Alarm />}
                    color="primary"
                    size="small"
                    sx={{ ml: 2 }}
                  />}</TableCell>
                  <TableCell >{formatDayOfWeek(wrap.date ?? "")}</TableCell>
                  <TableCell><Button
                    variant="outlined"
                    size="small"
                    onClick={() => { navigate(`/daily-wrap/view?id=${wrap.id}`) }}
                    endIcon={<ArrowRightAltRounded />}
                    sx={{ mt: 1, textTransform: 'none', fontWeight: 'bold' }}
                  >
                    Read Now
                  </Button></TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          <StyleHelper.VerticalSpace />
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event: unknown, page: number) => {
                _fetchWraps(page, startDate)
              }}
              variant='outlined'
              shape='rounded'
            />
          </Stack>
          <StyleHelper.VerticalSpace />

        </>}
      </>}
      {view === 'grid' && <>

        <Grid container flexWrap={'wrap'} spacing={2}>

          {loading &&
            Array.from(new Array(8)).map((_, index) => (
              <Grid item lg={4} xs={12}>
                <EventCardLoading key={index} />
              </Grid>
            ))}
          {!loading &&
            wraps?.map(wrap => (
              <Grid item lg={4} xs={12} key={wrap.id}>
                <WrapCard
                  wrap={wrap}
                  navigateToWrap={() =>
                    navigate(`/daily-wrap/view?id=${wrap.id}`)
                  }
                />
              </Grid>
            ))}
        </Grid>

        <StyleHelper.VerticalSpace />
        <Stack spacing={2} justifyContent='center' alignItems='center'>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event: unknown, page: number) => {
              _fetchWraps(page, startDate)
            }}
            variant='outlined'
            shape='rounded'
          />
        </Stack>
      </>}
    </Container>
  )
}
