import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import PageProps from "../_types/page-props.interface";
import { StyleHelper } from "../components/style-helper";
import { useState } from "react";
import VideoEducation from "../components/education/video-education";
import EbookEducation from "../components/education/ebook-education";

export default function EducationPage(props: PageProps) {


    const [activeTab, setActiveTab] = useState(0)
    return <Container sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4">Education</Typography>
        <StyleHelper.SmallVerticalSpace />
        <Typography variant="body2">Discover our growing library of stock insights with videos, e-books, and blogs - constantly updated to keep you informed!</Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
                setActiveTab(newValue);
            }} aria-label="basic tabs example">
                <Tab label="Videos" />
                <Tab label="E-Book" />
            </Tabs>
        </Box>
        <StyleHelper.VerticalSpace />
        {activeTab === 0 && <VideoEducation />}
        {activeTab === 1 && <EbookEducation />}
    </Container>

}