import { useEffect, useState } from 'react'
import { CarnivoreEvent } from '../_types/carnivore-event.type'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { useError } from '../context/error'
import { FileService } from '../services/files.service'
import {
  Alarm,
  AlarmOff,
  ArrowRightAltRounded,
  CalendarMonth,
  Campaign,
  LiveTv,
  Newspaper,
  School,
  VideoCall,
  Videocam
} from '@mui/icons-material'
import { StyleHelper } from './style-helper'
import AddToCalendarBtn from './controls/add-to-calendar-btn'
import { Formatters } from '../_helpers/formatter.helper'

interface EventCardProps {
  event: CarnivoreEvent
  openEventModal: () => void
}

export default function EventCard(props: EventCardProps) {
  const [imageUrl, setImageUrl] = useState('')
  const [hasImg, setHasImg] = useState(props.event.imgID ? true : false)
  const [loading, setLoading] = useState(true) // Assume loading by default
  const { event } = props
  const theme = useTheme()
  const { setError } = useError()

  const fileService = new FileService(setError)

  useEffect(() => {
    _fetchImage()
  }, [])
  const _fetchImage = async () => {
    if (event.imgID) {
      const res = await fileService.getPresignedUploadURL(event.imgID)
      if (res.url) {
        setImageUrl(res.url)
        setHasImg(true)
      }
    }
    setLoading(false)
  }

  function _isToday(date: Date): boolean {
    const today = new Date()

    // Compare the year, month, and day components
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const _getIcon = () => {
    switch (event.eventType) {
      case 'news':
        return <Campaign />
      case 'member-call':
        return <VideoCall />
      case 'academy':
        return <School />
      case 'virtual-academy':
        return <Videocam />
      case 'livestream':
        return <LiveTv />
      default:
        return <CalendarMonth />
    }
  }

  const _getImgSrc = () => {
    const fontSize = 60
    switch (event.eventType) {
      case 'news':
        return require('../assets/imgs/events/news-appearance.webp')
      case 'member-call':
        return require('../assets/imgs/events/zoom.webp')
      case 'academy':
        return require('../assets/imgs/events/academy.webp')
      case 'virtual-academy':
        return require('../assets/imgs/events/virutal-academy.webp')
      case 'livestream':
        return require('../assets/imgs/events/live.webp')
      default:
        return require('../assets/imgs/events/general.webp')
    }
  }

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <Skeleton
          variant='rectangular'
          width={210}
          height={140}
          animation='wave'
        />
      ) : (
        <CardMedia
          component='img'
          height='140'
          image={hasImg ? imageUrl : _getImgSrc()}
          alt='Dynamic Content'
          onLoad={() => setLoading(false)} // Ensure to set loading false when the image is loaded
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          {new Date(event.endTime) < new Date() && (
            <Box mr={1}>
              <Chip label={'Ended'} icon={<AlarmOff />} color='error' />
            </Box>
          )}
          {_isToday(new Date(event.startTime)) && (
            <Box mr={1}>
              <Chip label={'Today'} icon={<Alarm />} color='primary' />
            </Box>
          )}
          <Chip
            label={Formatters.removeHyphensAndUppercase(event.eventType)}
            icon={_getIcon()}
          />
        </Box>
        <StyleHelper.VerticalSpace />
        <Typography variant='h5' component='div' fontWeight={'bold'}>
          {event.title}
        </Typography>

        <StyleHelper.VerticalSpace />

        <Typography variant='body1'>
          {new Date(event.startTime).toLocaleString('en-US', {
            dateStyle: 'short',
            timeStyle: 'short'
          })}
          &nbsp;-&nbsp;
          {new Date(event.endTime).toLocaleString('en-US', {
            dateStyle: 'short',
            timeStyle: 'short'
          })}
        </Typography>
        <StyleHelper.VerticalSpace />

        <Typography variant='body2'>{event.shortDescription}</Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display={'flex'}>
          <AddToCalendarBtn
            url={event.link}
            title={event.title}
            description={event.longDescription}
            startTime={event.startTime}
            endTime={event.endTime}
            fileName={`carnivore-${event.eventType}-${new Date(
              event.startTime
            ).toLocaleDateString('en-US', { dateStyle: 'short' })}.ics`}
          />

          {event.recordedLink && <Tooltip title='View Recording'><IconButton onClick={() => {
            window.open(event.recordedLink, '_blank');

          }}>
            <Videocam />
          </IconButton></Tooltip>}
        </Box>
        <Button
          variant='contained'
          size='small'
          onClick={props.openEventModal}
          endIcon={<ArrowRightAltRounded />}
        >
          Learn More
        </Button>
        {/* {event.link && <Button variant='contained' size='small' endIcon={<ArrowRightAltRounded />}>{event.linkTxt ?? 'Link'}</Button>} */}
      </CardActions>
    </Card>
  )
}
