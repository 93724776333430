import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class WrapService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async searchWraps (page: number, startDate?: string, limit?: number) {
    return axiosInstance
      .get('/wrap/webapp/query', {
        params: {
          startDate,
          page,
          limit
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getWrapById (id: string) {
    return axiosInstance
      .get('/wrap/webapp/id', {
        params: {
          id
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getPortfolioReturns () {
    return axiosInstance
      .get('/wrap/portfolio-return')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
