import {
  Box,
  Container,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { StyleHelper } from '../style-helper'
import { Fragment } from 'react/jsx-runtime'

export default function StockPageLoadingState () {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container>
      <Paper elevation={3}>
        <Box p={3}>
          <Skeleton variant='text' width='80%' height={40} />
          <Skeleton variant='text' width='60%' />
          <Skeleton variant='text' width='40%' />
        </Box>
      </Paper>
      <Box my={2} />
      <StyleHelper.VerticalSpace />
      <Grid container spacing={4}  sx={{
          alignItems: {
            lg: 'center',
            sm: 'start'
          }
        }}>
        <Grid item xs={12} sm={2} lg={1}>
          <Skeleton variant='rectangular' width={'100%'} height={isMobile ? 400 : 80} />
        </Grid>
        <Grid item xs={12} sm={10} lg={5}>
          <Skeleton variant='text' width='90%' height={60} />
          <Skeleton variant='text' width='50%' height={40} />
        </Grid>
        <Grid item xs={12} lg={6} alignItems={'flex-end'}>
          <Skeleton variant='text' width='100%' height={20} />
          <Skeleton variant='text' width='100%' height={60} />
        </Grid>
      </Grid>
      <Box my={2} />
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container spacing={4}>
            {Array.from(new Array(8)).map((_, index) => (
              <Fragment key={index}>
                <Grid item xs={12} lg={6}>
                  <Grid container py={1}>
                    <Grid item xs={12} lg={3}>
                      <Skeleton variant='text' width='90%' height={20} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <Skeleton variant='text' width='60%' height={20} />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Paper>
      <Box my={2} />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={7}>
          <Paper elevation={3}>
            <Box p={3}>
              <Skeleton variant='text' width='50%' height={30} />
              <Skeleton variant='text' width='100%' height={20} />
              <Skeleton variant='text' width='100%' height={120} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Paper elevation={3}>
            <Box p={3}>
              <Skeleton variant='text' width='50%' height={30} />
              <Skeleton variant='text' width='100%' height={20} />
              {Array.from(new Array(9)).map((_, index) => (
                <Box key={index} py={1}>
                  <Skeleton variant='text' width='90%' height={20} />
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
