// KnowledgeCenterModal.tsx
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useKnowledgeCenter } from '../../context/knowledgecenter-context'
import useMediaQuery from '@mui/material/useMediaQuery'
import Knowledges from '../knowledge/knowledges'
import {
  Bookmark,
  Close,
  QuestionMark,
  QuestionMarkRounded
} from '@mui/icons-material'
import { StyleHelper } from '../style-helper'
import CarnivoreLogo from '../imgs/carnivore-logo'
import { useError } from '../../context/error'
import { AlertService } from '../../services/alert.service'
import { useGlobalState } from '../../context/global'
import { CarnivoreAlert } from '../../_types/alert.type'
import AlertViewLoadingState from '../loading/alert-view.loading'
import AlertView from '../alert-view'
import ViewAlertModal from './view-alert.modal'

interface BookmarkSidebarProps {
  isOpen: boolean
  onClose: () => void
}

export function BookmarkSidebar (props: BookmarkSidebarProps) {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [isLoading, setIsLoading] = useState(true)
  const [bookmarks, setBookmarks] = useState<CarnivoreAlert[]>([])

  const { setError } = useError()
  const { user, setUser, addToViewAlerts } = useGlobalState()
  const alertService = new AlertService(setError)
  const [currentAlert, setCurrentAlert] = useState<CarnivoreAlert | null>(null)

  const _fetchBookmarks = async () => {
    setIsLoading(true)
    const res = await alertService.getBookmarkedAlerts()
    setBookmarks(res.alerts)
    addToViewAlerts(res.viewedAlerts ?? [])
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchBookmarks()
  }, [user, props.isOpen])
  return (
    <Drawer
      anchor='right'
      open={props.isOpen}
      sx={{
        width: isMobile ? '100%' : '33%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '33%',
          boxSizing: 'border-box'
        }
      }}
      onClose={props.onClose}
    >
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2 // Padding bottom to add some space between the button and content
          }}
        >
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </Box>
        

        <Typography variant='h5' fontWeight={'bold'} textAlign={'center'}>
          Bookmarked Alerts
        </Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='body1' textAlign={'center'}>
          For quick access, you may have up to 15 bookmarked alerts
        </Typography>
        <StyleHelper.DoubleVerticalSpace />
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <AlertViewLoadingState key={index} />
          ))}

        {!isLoading &&
          bookmarks?.map(alert => (
            <AlertView
              hideIcon={true}
              alert={alert}
              key={alert.id}
              showAlert={(alert: CarnivoreAlert) => setCurrentAlert(alert)}
            />
          ))}

        {!isLoading && bookmarks?.length === 0 && (
          <Box>
            <StyleHelper.VerticalSpace />
            <Typography variant='body2' textAlign={'center'}>
              You have not bookmarked any alerts yet
            </Typography>
          </Box>
        )}
      </Box>

      {currentAlert && (
        <ViewAlertModal
          open={currentAlert != null}
          onClose={() => setCurrentAlert(null)}
          alert={currentAlert}
          size='lg'
        />
      )}
    </Drawer>
  )
}
