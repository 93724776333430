import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import useBoolean from '../../hooks/useBoolean'
import { StyleHelper } from '../style-helper'
import { Cancel } from '@mui/icons-material'
import { FormEvent, useEffect, useState } from 'react'
import { useTextInput } from '../../hooks/useTextField'
import LoadingButton from '../controls/loading-btn'
import { useError } from '../../context/error'
import { CommentService } from '../../services/comment.service'
import { useSuccess } from '../../context/success'
import { AlertService } from '../../services/alert.service'
import CarnivoreLogo from '../imgs/carnivore-logo'
import CarnivoreBox from '../carnivore-box'
import CarnivoreLogoTxt from '../imgs/carnivore-logo-txt'
import { PaymentService } from '../../services/payment.service'
import { useGlobalState } from '../../context/global'

const STAY_REASONS = [
  'Pre-Market Reports',
  'Live Updates & Alerts',
  'Mid Day Reports',
  'Daily Wraps',
  'Sector Heaters',
  'Long Term Reports',
  'Member Calls'
]

const CANCEL_REASONS = [
  `I'm trying to cut down on expenses.`,
  `I don't have enough time to follow the alerts.`,
  `I didn't see the results I expected.`,
  `I'm overwhelmed by the amount of information.`,
  `My investment strategy has changed.`,
  `I don't think the service is worth the cost.`,
  `I'm taking a break from investing.`,
  `I found another service that suits me better.`,
  `I had issues with the platform's usability`,
  `Other`
]

interface CancellationModalProps {
  onCancel: () => void
}
const CancellationModal: React.FC<
  CarnivoreModalProps & CancellationModalProps
> = ({ open, onClose, size = 'md', onCancel }) => {
  const { user } = useGlobalState()
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [loading, setLoading] = useState(false)
  const [cancelling, setCancelling] = useState(false)

  const [count, setCount] = useState<number | null>(null)
  const [step, setStep] = useState(0)

  const { setError } = useError()
  const messageInput = useTextInput({
    initialValue: ''
  })
  const [reason, setReason] = useState('')
  const alertService = new AlertService(setError)
  const paymentService = new PaymentService(setError)

  useEffect(() => {
    _fetchCount()
  }, [])

  const _fetchCount = async () => {
    setLoading(true)

    const res = await alertService.getNumberInLastMonth()
    if (res.numAlerts) {
      setCount(res.numAlerts)
    }
    setLoading(false)
  }

  const _cancelSubscription = async (e: FormEvent) => {
    e.preventDefault()
    setCancelling(true)
    if (user?.paymentSource === 'stripe') {
      const res = await paymentService.cancelSubscription(
        reason,
        messageInput.value
      )
    } else {
      const res = await paymentService.cancelWooSubscription(
        reason,
        messageInput.value
      )
    }

    onClose()
    onCancel()
  }

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          {/* <Typography variant='subtitle1' fontWeight={'bold'}>
            Cancellation Request
          </Typography> */}
          <IconButton onClick={onClose}>
            <Cancel />
          </IconButton>
        </Box>

        {!loading && step === 0 && (
          <Box textAlign={'center'}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={3} md={1}>
                <CarnivoreLogo />
              </Grid>
            </Grid>
            <Typography variant='h4' fontWeight={'bold'}>
              Don't Miss Out!
            </Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='body1'>
              Last month, you received{' '}
              <Typography
                variant='body1'
                fontWeight={'bold'}
                display={'inline'}
                color='primary'
              >
                {count}
              </Typography>{' '}
              valuable stock alerts that could make a difference in your
              portfolio. Leaving now means missing out on crucial insights that
              keep you ahead in the market.
            </Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='body1'>
              Let's continue building your financial future together.
            </Typography>
            <StyleHelper.DoubleVerticalSpace />
            <Button variant='contained' onClick={onClose}>
              OK
            </Button>
            <StyleHelper.VerticalSpace />
            <Button variant='text' color='secondary' onClick={() => setStep(1)}>
              Continue
            </Button>
          </Box>
        )}

        {!loading && step === 1 && (
          <>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ backgroundColor: 'action.hover' }}
              >
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  If you go, You'll be missing out on...
                </Typography>

                <List dense={true}>
                  {STAY_REASONS.map((item: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <Cancel color='error' />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box display={'flex'} justifyContent={'center'}>
                  <Box width={'60%'} textAlign={'center'}>
                    <CarnivoreLogoTxt />
                  </Box>
                </Box>

                <Typography
                  variant='subtitle1'
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Before You Go...
                </Typography>
                <Typography variant='body2'>
                  Please answer a few questions about your leaving.
                </Typography>
                <StyleHelper.VerticalSpace />
                <form onSubmit={_cancelSubscription}>
                  <FormControl fullWidth>
                    <InputLabel id='cancel-reason'>
                      Cancellation Reason
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='cancel-reason'
                      required
                      value={reason}
                      onChange={e => setReason(e.target.value)}
                      label='Cancellation Reason'
                    >
                      {CANCEL_REASONS?.map(r => (
                        <MenuItem value={r} key={r}>
                          {r}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <StyleHelper.VerticalSpace />
                  <TextField
                    label='Comments'
                    required
                    placeholder='Let us know what we can do better or clarify your cancellation reason'
                    variant='outlined'
                    type='text'
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 500 }}
                    rows={7}
                    value={messageInput.value}
                    error={messageInput.error}
                    helperText={messageInput.helperText}
                    onChange={messageInput.onChange}
                  />
                  {messageInput.value && messageInput.value.length < 25 && (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Typography variant='caption' color={'error'}>
                        Must be at least 25 characters long
                      </Typography>
                    </Box>
                  )}
                  <StyleHelper.VerticalSpace />
                  <Box display='flex' justifyContent={'flex-end'}>
                    <LoadingButton
                      loading={cancelling}
                      type='submit'
                      variant='text'
                      disabled={messageInput.value.length < 25 || cancelling}
                    >
                      I want to Cancel
                    </LoadingButton>
                    <StyleHelper.HorizontalSpace />
                    <Button variant='contained' onClick={onClose}>
                      Never Mind, I changed My Mind
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default CancellationModal
