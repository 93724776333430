import { useLocation } from 'react-router-dom'
import PageProps from '../../_types/page-props.interface'
import { useEffect, useState } from 'react'
import { CarnivoreEvent } from '../../_types/carnivore-event.type'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'
import { useError } from '../../context/error'
import EventCard from '../../components/eventcard'
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { FileService } from '../../services/files.service'
import {
  Alarm,
  AlarmOff,
  ArrowRightAlt,
  ArrowRightAltRounded,
  CalendarMonth,
  Campaign,
  LiveTv,
  Newspaper,
  School,
  VideoCall,
  Videocam
} from '@mui/icons-material'
import { useTheme } from '../../context/theme'
import { StyleHelper } from '../../components/style-helper'
import BackButton from '../../components/controls/back-btn'
import { Formatters } from '../../_helpers/formatter.helper'
import { EventService } from '../../services/events.service'
import AddToCalendarBtn from '../../components/controls/add-to-calendar-btn'
interface EventsViewPageQueryParms {
  id?: string
  endDate?: string
  eventType?: string
}

export default function EventViewPage (props: PageProps) {
  const location = useLocation()
  const { setError } = useError()

  const [event, setEvent] = useState<CarnivoreEvent | null>(
    location.state?.event
  )
  const [hasImg, setHasImg] = useState(event?.imgID ? true : false)

  const [loading, setLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState('')
  const fileService = new FileService(setError)
  const eventService = new EventService(setError)
  const [notFound, setNotFound] = useState(false)
  const { mode } = useTheme()

  const [queryParams, setQueryParams] =
    useQueryParamsV2<EventsViewPageQueryParms>()

  useEffect(() => {
    if (event) {
      setQueryParams({ id: event.id })
      _fetchImage()
    } else {
      _getEvent()
    }
  }, [])

  const _getEvent = async () => {
    setLoading(true)
    const res = await eventService.getEventById(queryParams.id ?? '')
    if (res.event) {
      setEvent(res.event)
      console.log(res)
    } else {
      setNotFound(true)
    }
    setLoading(false)
  }

  const _fetchImage = async () => {
    if (event) {
      if (event.imgID) {
        const res = await fileService.getPresignedUploadURL(event.imgID)
        if (res.url) {
          setImageUrl(res.url)
          setHasImg(true)
        }
      }
      setLoading(false)
    }
  }

  function _isToday (date: Date): boolean {
    const today = new Date()

    // Compare the year, month, and day components
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const _getIcon = () => {
    switch (event?.eventType) {
      case 'news':
        return <Campaign />
      case 'member-call':
        return <VideoCall />
      case 'academy':
        return <School />
      case 'virtual-academy':
        return <Videocam />
      case 'livestream':
        return <LiveTv />
      default:
        return <CalendarMonth />
    }
  }

  const _getImgSrc = () => {
    const fontSize = 60
    switch (event?.eventType) {
      case 'news':
        return require('../../assets/imgs/events/news-appearance.webp')
      case 'member-call':
        return require('../../assets/imgs/events/zoom.webp')
      case 'academy':
        return require('../../assets/imgs/events/academy.webp')
      case 'virtual-academy':
        return require('../../assets/imgs/events/virutal-academy.webp')
      case 'livestream':
        return require('../../assets/imgs/events/live.webp')
      default:
        return require('../../assets/imgs/events/general.webp')
    }
  }

  return (
    <Container>
      <Box
        sx={{
          backgroundImage: `url(${hasImg ? `"${imageUrl}"` : _getImgSrc()})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box
          sx={{
            backdropFilter: 'blur(8px)', // Standard syntax
            WebkitBackdropFilter: 'blur(8px)',
            backgroundColor:
              mode === 'dark'
                ? 'rgba(0, 0, 0, 0.3)'
                : 'rgba(255, 255, 255, 0.3)' // Adds a white layer with 50% opacity
          }}
        >
          <Grid container display={'flex'} justifyContent={'center'}>
            <Grid item xs={10} lg={9}>
              <Box
                component={'img'}
                alt='cover'
                sx={{
                  width: '100%',
                  height: '100%'
                }}
                src={hasImg ? imageUrl : _getImgSrc()}
              ></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <StyleHelper.VerticalSpace />
      <BackButton fallbackUrl='/calendar' />
      <StyleHelper.VerticalSpace />

      {/* <Typography variant='body1'>
        {new Date(event?.startTime ?? '').toLocaleString('en-US', {
          dateStyle: 'full',
          timeStyle: 'short'
        })}
        &nbsp;-&nbsp;
        {new Date(event?.endTime ?? '').toLocaleString('en-US', {
          dateStyle: 'full',
          timeStyle: 'short'
        })}
      </Typography> */}
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Typography variant='h3' fontWeight={'bold'}>
            {event?.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            {new Date(event?.endTime ?? '') < new Date() && (
              <Box mr={1}>
                <Chip label={'Ended'} icon={<AlarmOff />} color='error' />
              </Box>
            )}
            {_isToday(new Date(event?.startTime ?? '')) && (
              <Box mr={1}>
                <Chip label={'Today'} icon={<Alarm />} color='primary' />
              </Box>
            )}
            <Chip
              label={Formatters.removeHyphensAndUppercase(
                event?.eventType ?? ''
              )}
              icon={_getIcon()}
            />
          </Box>
        </Grid>
      </Grid>

      <StyleHelper.VerticalSpace />

      <Typography variant='body2'>{event?.shortDescription}</Typography>

      <StyleHelper.DoubleVerticalSpace />
      <Paper elevation={3}>
        <Box p={3}>
          <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
            Event Information
          </Typography>
          <StyleHelper.VerticalSpace />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4} py={1}>
                <Grid item xs={12} lg={3}>
                  <Typography variant='subtitle2'>Start Time</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography variant='body1' textAlign={'end'}>
                    {new Date(event?.startTime ?? '').toLocaleString('en-US', {
                      dateStyle: 'full',
                      timeStyle: 'short'
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={4} py={1}>
                <Grid item xs={12} lg={3}>
                  <Typography variant='subtitle2'>Members</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography variant='body1' textAlign={'end'}>
                    {Formatters.removeHyphensAndUppercase(event?.package ?? '')}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4} py={1}>
                <Grid item xs={12} lg={3}>
                  <Typography variant='subtitle2'>End Time</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography variant='body1' textAlign={'end'}>
                    {new Date(event?.endTime ?? '').toLocaleString('en-US', {
                      dateStyle: 'full',
                      timeStyle: 'short'
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={4} py={1}>
                <Grid item xs={12} lg={3}>
                  <Typography variant='subtitle2'>Event Type</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography variant='body1' textAlign={'end'}>
                    {Formatters.removeHyphensAndUppercase(
                      event?.eventType ?? ''
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />

          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <AddToCalendarBtn
              url={event?.link}
              title={event?.title ?? ''}
              description={event?.longDescription ?? ''}
              startTime={event?.startTime ?? ''}
              endTime={event?.endTime ?? ''}
              fileName={`carnivore-${event?.eventType ?? ''}-${new Date(
                event?.startTime ?? ''
              ).toLocaleDateString('en-US', { dateStyle: 'short' })}.ics`}
            />
            <StyleHelper.HorizontalSpace />
            {event?.link && (
              <Button
                variant='contained'
                endIcon={<ArrowRightAlt />}
                color='secondary'
                onClick={() => window.open(event.link, "_blank")}
              >
                {event.linkTxt ?? 'View'}
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
      <StyleHelper.DoubleVerticalSpace />

      <Paper elevation={3}>
        <Box p={3}>
          <Typography variant='subtitle1' color={'primary'} fontWeight={'bold'}>
            Description
          </Typography>
          <StyleHelper.VerticalSpace />
          <Typography variant='body1'>{event?.longDescription}</Typography>
        </Box>
      </Paper>
      <StyleHelper.DoubleVerticalSpace />
      {event?.recordedLink && (
        <>
          <Paper elevation={3}>
            <Box p={3}>
              <Typography
                variant='subtitle1'
                color={'primary'}
                fontWeight={'bold'}
              >
                Member Call Recording
              </Typography>
              <StyleHelper.VerticalSpace />

              <Typography variant='body1'>
                Couldn't make the call? Click the link below to view a
                recording.
              </Typography>
              <StyleHelper.VerticalSpace />
              <Button
                variant='outlined'
                endIcon={<ArrowRightAlt />}
                color='secondary'
                onClick={() => {
                  window.open(event.recordedLink, '_blank')
                }}
              >
                View Recording
              </Button>
            </Box>
          </Paper>
        </>
      )}
      <StyleHelper.DoubleVerticalSpace />
    </Container>
  )
}
