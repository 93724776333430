import { IconButton, Tooltip } from '@mui/material'
import { useKnowledgeCenter } from '../../context/knowledgecenter-context'
import { Info, SupportAgent } from '@mui/icons-material'
import { useState } from 'react'
import SupportModal from '../modals/support.modal'

export default function SupportBtn () {
  const [showSupport, setShowSupport] = useState(false)
  return (
    <>
      <Tooltip title='Contact Support'>
        <IconButton id='support-btn' onClick={() => setShowSupport(true)}>
          <SupportAgent />
        </IconButton>
      </Tooltip>
      <SupportModal
        onClose={() => setShowSupport(false)}
        open={showSupport}
        size='lg'
      />
    </>
  )
}
