import { Box } from '@mui/material'
import { useTheme } from '../../context/theme'

export default function NASDAQImg () {
  const { mode } = useTheme() // Now mode is correctly provided from the context

  return (
    <Box
      component='img'
      sx={{
        height: 'auto', // Makes the image responsive
        width: '100%',
        objectFit : 'contain' // Maintains aspect ratio
      }}
      src={mode === 'light' ? require('../../assets/imgs/wrap/nasdaq-light.png') : require('../../assets/imgs/wrap/nasdaq-dark.png')}
      alt='Description'
    />
  )
}
