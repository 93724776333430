import React from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import { StyleHelper } from '../components/style-helper'
import useDocumentTitle from '../hooks/useDocumentTitle'

const MaintenancePage: React.FC = () => {
  useDocumentTitle('Down for Maintenance')
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={1}>
          {/* Your image goes here */}
          <CarnivoreLogo />
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <Typography variant='h3'>We'll Be Back Soon</Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body2'>
        We're down for maintenance currently. Please come back later.
      </Typography>
    </Box>
  )
}

export default MaintenancePage
