import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ErrorProvider } from './context/error'
import { GlobalStateProvider } from './context/global'
import { SuccessProvider } from './context/success'
import { KnowledgeCenterProvider } from './context/knowledgecenter-context'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import 'intro.js/introjs.css'
import { TickerDrawerProvider } from './context/ticker-drawer-context'
import { WebSocketProvider } from './context/websocket.context'
import { AnalyticsHelper } from './_helpers/analytics.helper'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('SW registered: ', registration)
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError)
      })
  })
}
AnalyticsHelper.initialize()

root.render(
  <GlobalStateProvider>
    <KnowledgeCenterProvider>
      <TickerDrawerProvider>
        <Router>
          <SuccessProvider>
            <ErrorProvider>
              <WebSocketProvider>
                <App />
              </WebSocketProvider>
            </ErrorProvider>
          </SuccessProvider>
        </Router>
      </TickerDrawerProvider>
    </KnowledgeCenterProvider>
  </GlobalStateProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
