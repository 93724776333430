import { Box, Typography } from '@mui/material'
import { KnowledgePiece } from '../../../_types/knowledge.interface'
import { StyleHelper } from '../../style-helper'

export const BuyHoldKnowledge: KnowledgePiece = {
  id: 'buy-hold',
  title: 'Intermediate Portfolio',
  content: (
    <Box>
      <Typography variant='body1'>
        The intermediate portfolio is Carnivore Trading’s way of putting a
        handful of high-quality stock picks in your hands and, in turn, putting
        money in your pockets. Each of these power stocks has been hand-selected
        by Trader Z for the purpose of long-term holding and growth. Throughout
        the year, we will continue to add and alert you of new stocks and any
        changes we might make!
      </Typography>
      <StyleHelper.VerticalSpace />
      <Typography variant='body1' fontWeight={'bold'}>
        Note: In the Stop-Loss column, it has OUR stop loss for tracking - yours
        will depend on your entry price. For ours, we use a 12% Stop-Loss by
        using the formula: Entry Price * .88 = Stop Loss. However, as always
        with Carnivore, the final choice belongs to you.
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body1'>
        Below the portfolio is a detailed explanation of why we like the stocks,
        we hope you like them too.
      </Typography>
    </Box>
  )
}
