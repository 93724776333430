import {
  Box,
  Divider,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js'
import { useGlobalState } from '../context/global'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import useQueryParams from '../hooks/useQueryParams'
import { FormEvent, useEffect, useState } from 'react'
import { useError } from '../context/error'
import { useSuccess } from '../context/success'
import { SettingsService } from '../services/settings.service'
import { PaymentService } from '../services/payment.service'
import { useTextInput } from '../hooks/useTextField'
import LoadingButton from './controls/loading-btn'
import { StyleHelper } from './style-helper'
import { Formatters } from '../_helpers/formatter.helper'
import { useNavigate } from 'react-router-dom'
import useQueryParamsV2 from '../hooks/userQueryParamsV2'

interface CreditCardFormParams {
  p: string
  c: string
}

interface CreditCardFormProps {
  pmId?: string
  onComplete?: () => void
}
export default function CreditCardForm (props: CreditCardFormProps) {
  const { user, setUser } = useGlobalState()
  const stripe = useStripe()
  const elements = useElements()
  const [couponLoading, setCouponLoading] = useState(false)
  const { settings } = useGlobalState()
  const { setError } = useError()
  const { setSuccess } = useSuccess()
  const [processing, setProcessing] = useState(false)
  const [queryParams, setQueryParams] = useQueryParamsV2<CreditCardFormParams>()

  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [subType, setSubType] = useState('')
  const [coupon, setCoupon] = useState<any>()

  const settingsService = new SettingsService(setError)
  const paymentService = new PaymentService(setError)
  const couponInputValue = useTextInput({ initialValue: '' })
  const [address, setAddress] = useState<{
    line1: string
    line2: string | null
    city: string
    state: string
    postal_code: string
    country: string
  } | null>()
  const navigate = useNavigate()
  useEffect(() => {
    _getTotal()
  }, [settings])

  const _getTotal = async () => {
    setLoading(true)
    let price = ''
    if (queryParams.p === 'pro') {
      if (queryParams.c === 'monthly') {
        price = settings?.proMonthlyPriceID ?? ''
        setSubType('Carnivore Pro Monthly')
      } else {
        price = settings?.proYearlyPriceID ?? ''
        setSubType('Carnivore Pro Annual')
      }
    } else {
      if (queryParams.c === 'monthly') {
        price = settings?.basicMonthlyPriceID ?? ''
        setSubType('Carnivore Basic Monthly')
      } else {
        price = settings?.basicYearlyPriceID ?? ''
        setSubType('Carnivore Basic Annual')
      }
    }
    const response = await settingsService.getPrice(price)
    setTotal(response.price)
    setLoading(false)
  }

  const _checkCoupon = async () => {
    setCouponLoading(true)
    const res = await paymentService.checkValidCoupon(
      couponInputValue.value?.toUpperCase()
    )

    if (res.coupon) {
      setSuccess('Coupon Code Applied')
    }
    setCoupon(res.coupon)

    setCouponLoading(false)
  }

  const _createSubscription = async (e: FormEvent) => {
    e.preventDefault()
    if (elements) {
      setProcessing(true)
      if (!props.pmId || props.pmId === 'create_new') {
        if (
          !address ||
          !address?.line1 ||
          !address?.city ||
          !address?.state ||
          !address?.postal_code ||
          !address?.country
        ) {
          setProcessing(false)

          setError('Please complete your billing address information')
          return
        }
        const result = await stripe?.confirmSetup({
          elements,
          redirect: 'if_required'
        })

        if (result?.error) {
          setError(
            result?.error?.message ||
              'An occurred processing your payment method'
          )
        } else {
          if (result?.setupIntent) {
            if (result.setupIntent?.payment_method) {
              console.log(result.setupIntent?.payment_method)
              const paymentResponse = await paymentService.createSubscription(
                queryParams.p ?? '',
                queryParams.c ?? '',
                result.setupIntent?.payment_method.toString() ?? '',
                address,
                coupon?.id
              )
              if (paymentResponse.user) {
                setUser(paymentResponse.user)
                navigate('/dashboard')
              }
            }
          }
        }
      } else {
        const paymentResponse = await paymentService.createSubscription(
          queryParams.p ?? '',
          queryParams.c ?? '',
          props.pmId,
          address,
          coupon?.id
        )
        if (paymentResponse.user) {
          setUser(paymentResponse.user)
          if (props.onComplete) {
            props.onComplete()
          }
        }
      }
    }
    setProcessing(false)
  }

  return (
    <Box p={3}>
      <form onSubmit={_createSubscription}>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            {(!props.pmId || props.pmId === 'create_new') && (
              <>
                <PaymentElement />
                <AddressElement
                  options={{
                    mode: 'billing',
                    defaultValues: {
                      name: `${user?.fName} ${user?.lName}`
                    }
                  }}
                  onChange={event => {
                    // Extract potentially complete address
                    setAddress(event.value.address)
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item lg={4} xs={12}>
            <FormLabel>Coupon</FormLabel>
            <TextField
              fullWidth
              variant='outlined'
              placeholder='coupon code'
              onChange={couponInputValue.onChange}
              value={couponInputValue.value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <LoadingButton
                      onClick={_checkCoupon}
                      variant='text'
                      loading={couponLoading}
                    >
                      Apply
                    </LoadingButton>
                  </InputAdornment>
                )
              }}
            />
            <StyleHelper.DoubleVerticalSpace />
            <Typography fontWeight={'bold'} variant='body1'>
              Total
            </Typography>
            <Divider />
            <StyleHelper.VerticalSpace />
            <Grid container alignItems='center' py={1}>
              <Grid item xs>
                <Typography variant='body2' fontWeight='bold'>
                  {subType}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' textAlign='end'>
                  ${Formatters.formatMoney(total / 100)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            {coupon && (
              <Box>
                <Grid container alignItems='center' py={1}>
                  <Grid item xs>
                    <Typography variant='body2' fontWeight='bold'>
                      Promo Discount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' textAlign='end'>
                      -$
                      {Formatters.formatMoney(
                        (total * (coupon.percent_off / 100)) / 100
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            )}
            <Grid container alignItems='center' py={1}>
              <Grid item xs>
                <Typography variant='body2' fontWeight='bold'>
                  Due Today
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' textAlign='end'>
                  $
                  {Formatters.formatMoney(
                    total / 100 -
                      (coupon ? (total * (coupon.percent_off / 100)) / 100 : 0)
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container alignItems='center' py={1}>
              <Grid item xs>
                <Typography variant='body2' fontWeight='bold'>
                  Next {queryParams.c === 'monthly' ? "Month's" : "Year's"} Bill
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' textAlign='end'>
                  $
                  {Formatters.formatMoney(
                    total / 100 -
                      (coupon && coupon?.duration !== 'once'
                        ? (total * (coupon.percent_off / 100)) / 100
                        : 0)
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <StyleHelper.DoubleVerticalSpace />
            <LoadingButton
              disabled={processing}
              loading={processing}
              variant='contained'
              type='submit'
              fullWidth
            >
              Checkout
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
