import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography
} from '@mui/material'
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import CarnivoreLogoTxt from '../../components/imgs/carnivore-logo-txt'
import PageProps from '../../_types/page-props.interface'
import Package from '../../components/package'
import { useGlobalState } from '../../context/global'
import useBoolean from '../../hooks/useBoolean'
import { useError } from '../../context/error'
import { PaymentService } from '../../services/payment.service'
import BaseModal from '../../components/modals/base.modal'
import { DoNotDisturb } from '@mui/icons-material'
import { StyleHelper } from '../../components/style-helper'
import LoadingButton from '../../components/controls/loading-btn'
import { useSuccess } from '../../context/success'
import WooSubPage from './subscriptions/woosub.page'
import StripeSubPage from './subscriptions/stripe.page'

export default function SubscriptionPage (props: PageProps) {
  const { settings, user } = useGlobalState()

  return (
    <>{user?.paymentSource === 'stripe' ? <StripeSubPage /> : <WooSubPage />}</>
  )
}
