import { ArrowOutwardRounded, Close } from '@mui/icons-material'
import {
  Box,
  Drawer,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useTickerDrawer } from '../context/ticker-drawer-context'
import StockPage from '../pages/stock.pages'

export default function TickerDrawer () {
  const { isOpen, ticker, toggleDrawer } = useTickerDrawer()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      sx={{
        width: isMobile ? '100%' : '55%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '55%',
          boxSizing: 'border-box'
        }
      }}
      onClose={toggleDrawer}
    >
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2 // Padding bottom to add some space between the button and content
          }}
        >
          <Tooltip title='Open in new tab'>
            <IconButton
              onClick={toggleDrawer}
              href={`/stock/${ticker}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ArrowOutwardRounded />
            </IconButton>
          </Tooltip>
          <IconButton onClick={toggleDrawer}>
            <Close />
          </IconButton>
        </Box>

        <StockPage title={''} layout={'drawer'} ticker={ticker} />
      </Box>
    </Drawer>
  )
}
