import { Circle } from '@mui/icons-material'
import { Grid, Link } from '@mui/material'

export default function LegalBanner () {
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={5} sx={{ textAlign: 'right' }}>
        <Link
          color={'text.primary'}
          target='_blank'
          rel='noopener noreferrer'
          href='https://carnivoretrading.com/privacy-policy/'
        >
          Privacy policy
        </Link>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Circle sx={{ fontSize: 10 }} />
      </Grid>
      <Grid item xs={5} sx={{ textAlign: 'left' }}>
        <Link
          target='_blank'
          rel='noopener noreferrer'
          color={'text.primary'}
          href='https://carnivoretrading.com/terms-of-service/'
        >
          Terms And Conditions
        </Link>
      </Grid>
    </Grid>
  )
}
