import React, { ReactNode, createContext, useContext, useState } from 'react'
import { IconButton, Snackbar } from '@mui/material'
import { Close } from '@mui/icons-material'

// Define the shape of your error context
interface SuccessContextType {
  successMessage: string | null
  setSuccess: (message: string | null) => void
}

// Create the context
const SuccessContext = createContext<SuccessContextType | undefined>(undefined)
interface SuccessProps {
  children: ReactNode
}
// Create the provider component
export const SuccessProvider = ({ children }: SuccessProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  // Function to set error message
  const setSuccess = (message: string | null) => {
    setSuccessMessage(message)
  }

  // Function to clear error message
  const clearSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return // Prevents the Snackbar from closing when the user clicks away
    }
    setSuccessMessage(null)
  }

  // Value for the context
  const value: SuccessContextType = {
    successMessage,
    setSuccess
  }

  return (
    <SuccessContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => clearSuccess()}
        message={successMessage || ''}
        ContentProps={{
          sx: {
            backgroundColor: 'success.main',
            color: 'white'
          }
        }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={clearSuccess}
          >
            <Close fontSize='small' />
          </IconButton>
        }
      />
    </SuccessContext.Provider>
  )
}

// Custom hook to use the context
export const useSuccess = () => {
  const context = useContext(SuccessContext)
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider')
  }
  return context
}
