import { Container, Typography } from '@mui/material'
import { useEffect } from 'react'

export default function useDocumentTitle (title: string) {


  
  useEffect(() => {
    document.title = `${title} | Carnivore Trading`
  }, [title])

  return (
    <Container>
      <Typography variant='h4'>Profile</Typography>
    </Container>
  )
}
