import React, { useState, useEffect } from 'react'
import { Typography, Box, Paper } from '@mui/material'

interface CountdownProps {
  targetDate: string // The target date as a string, e.g., "2024-12-31"
}

const CountdownTimer: React.FC<CountdownProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const now = new Date()
    const countdownDate = new Date(targetDate)
    const difference = countdownDate.getTime() - now.getTime()

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTimeLeft({ days, hours, minutes, seconds })
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    }

    const timer = setInterval(() => {
      const now = new Date()
      const countdownDate = new Date(targetDate)
      const difference = countdownDate.getTime() - now.getTime()

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        setTimeLeft({ days, hours, minutes, seconds })
      } else {
        clearInterval(timer)
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [targetDate])

  return (
    <Typography variant='h3' textAlign={'end'}>
      {timeLeft.days}
      <Typography variant='caption'>d</Typography> {timeLeft.hours}
      <Typography variant='caption'>h</Typography> {timeLeft.minutes}
      <Typography variant='caption'>m</Typography> {timeLeft.seconds}
      <Typography variant='caption'>s</Typography>
    </Typography>
  )
}

export default CountdownTimer
