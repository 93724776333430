import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import CarnivoreLogo from '../../components/imgs/carnivore-logo'
import { StyleHelper } from '../../components/style-helper'
import CarnivoreBox from '../../components/carnivore-box'
import LoadingButton from '../../components/controls/loading-btn'
import CarnivoreLogoTxt from '../../components/imgs/carnivore-logo-txt'
import { useNavigate } from 'react-router-dom'
import { FormEvent, useEffect, useState } from 'react'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'
import { useTextInput } from '../../hooks/useTextField'
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'

type ForgotVerifyQP = {
  token?: string
}
export default function ForgotVerify (props: PageProps) {
  useDocumentTitle(props.title)

  const { setError } = useError()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isValidLink, setIsValidLink] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [queryParams, setQueryParams] = useQueryParamsV2<ForgotVerifyQP>()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const pwInput = useTextInput({
    initialValue: '',
    errMsg:
      'Passwords must contain at least one uppercase letter, one number, one special character and be at least 8 characters long',
    validate: (value: string) => {
      return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
        value
      )
    }
  })
  const confirmInput = useTextInput({
    initialValue: '',
    errMsg: 'Confirm password must match password',
    validate: (value: string) => {
      return value === pwInput.value
    }
  })

  const userService = new UserService(setError)

  useEffect(() => {
    _validateToken()
  }, [])

  const _validateToken = async () => {
    if (queryParams.token) {
      const res = await userService.verifyForgotToken(queryParams.token)
      if (res.isValid !== true) {
        setIsValidLink(false)
      } else {
        setIsValidLink(true)
      }
      setIsLoading(false)
    } else {
      setIsValidLink(false)
      setIsLoading(false)
    }
  }
  const _resetPassword = async (e: FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    const res = await userService.forgotReset(
      queryParams.token ?? '',
      pwInput.value
    )

    if (res.success === true) {
      setIsSuccess(true)
    }else{
      setIsValidLink(false)
    }
    setIsSubmitting(true)
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={3}>
          {/* Your image goes here */}
          <CarnivoreLogoTxt />
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={11} md={4}>
          <Paper elevation={3}>
            <Box p={4}>
              {isSuccess && (
                <>
                  <Box display={'flex'} justifyContent={'center'}>
                    <CheckCircle color='success' fontSize='large' />
                  </Box>
                  <StyleHelper.DoubleVerticalSpace />
                  <Typography variant='body2' textAlign={'center'}>
                    Password Reset. You can now login with your new password.
                  </Typography>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button variant='text' onClick={() => navigate('/login')}>
                      Back To Login
                    </Button>
                  </Box>
                </>
              )}
              {!isSuccess && (
                <>
                  {isLoading && (
                    <Box display='flex' justifyContent={'center'}>
                      <CircularProgress />
                    </Box>
                  )}
                  {!isLoading && !isValidLink && (
                    <>
                      <Typography
                        variant='subtitle1'
                        textAlign={'center'}
                        fontWeight={'bold'}
                        color={'primary'}
                        gutterBottom
                      >
                        Invalid Link
                      </Typography>
                      <StyleHelper.VerticalSpace />
                      <Typography variant='body2' textAlign={'center'}>
                        This link is either invalid or expired
                      </Typography>
                      <StyleHelper.VerticalSpace />

                      <Box display={'flex'} justifyContent={'center'}>
                        <Button
                          variant='text'
                          onClick={() => navigate('/login')}
                        >
                          Back To Login
                        </Button>
                      </Box>
                    </>
                  )}
                  {!isLoading && isValidLink && (
                    <>
                      <Typography
                        variant='subtitle1'
                        fontWeight={'bold'}
                        color={'primary'}
                        gutterBottom
                      >
                        Reset Password
                      </Typography>
                      <StyleHelper.VerticalSpace />

                      <Divider />
                      <StyleHelper.DoubleVerticalSpace />
                      <form onSubmit={_resetPassword}>
                        <TextField
                          fullWidth
                          required
                          variant='outlined'
                          label='New Password'
                          placeholder='New Password'
                          type={showPassword ? 'text' : 'password'}
                          helperText={pwInput.helperText}
                          error={pwInput.error}
                          value={pwInput.value}
                          onChange={pwInput.onChange}
                          InputProps={{
                            tabIndex: 7,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowPassword(show => !show)}
                                  edge='end'
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <StyleHelper.DoubleVerticalSpace />
                        <TextField
                          fullWidth
                          required
                          variant='outlined'
                          label='Confirm New Password'
                          placeholder='Confirm New Password'
                          type={showConfirm ? 'text' : 'password'}
                          helperText={confirmInput.helperText}
                          error={confirmInput.error}
                          value={confirmInput.value}
                          onChange={confirmInput.onChange}
                          InputProps={{
                            tabIndex: 7,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowConfirm(show => !show)}
                                  edge='end'
                                >
                                  {showConfirm ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <StyleHelper.DoubleVerticalSpace />

                        <LoadingButton
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          variant='contained'
                          type='submit'
                          fullWidth
                        >
                          Reset My Password
                        </LoadingButton>
                      </form>
                    </>
                  )}
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
