import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useTheme } from '../context/theme'
import { DarkMode, LightMode } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

const ThemeSwitch = () => {
  const { toggleColorMode, mode } = useTheme() // Now mode is correctly provided from the context
  const [, forceUpdate] = React.useState(false)

  const handleModeChange = () => {
    toggleColorMode()
    forceUpdate(s => !s)
  }

  return (
    <Tooltip title={`Switch to ${mode === 'dark' ? 'light' : 'dark'} mode`}>
      <IconButton
      id='theme-btn'
        onClick={handleModeChange}
        aria-label={`switch to ${mode === 'dark' ? 'light' : 'dark'} mode`}
      >
        {mode === 'dark' ? <LightMode /> : <DarkMode />}
      </IconButton>
    </Tooltip>
  )
}

export default ThemeSwitch
