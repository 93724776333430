import { WrapNav } from '../_types/wrap-nave.type'

const WRAPNAVLINKS: WrapNav = {
  sections: [
    {
      title: 'Overview',
      targetId: 'wrap-overview',
      subSections: [
        {
          title: 'Headlines',
          targetId: 'wrap-headlines'
        },
        {
          title: 'Index',
          targetId: 'wrap-index'
        },
        {
          title: 'Sectors',
          targetId: 'wrap-sectors'
        }
      ]
    },
    {
      title: 'Portfolio',
      targetId: 'wrap-portfolio',
      subSections: [
        {
          title: 'Performance',
          targetId: 'wrap-performance'
        },
        {
          title: 'Positions',
          targetId: 'wrap-positions'
        }
      ]
    },
    {
      title: 'Commentary',
      targetId: 'wrap-commentary',
      subSections: [
        {
          title: 'Trader Z',
          targetId: 'wrap-traderz'
        },
        {
          title: 'Dutch',
          targetId: 'wrap-dutch'
        }
      ]
    }
  ]
}

export default WRAPNAVLINKS
