import { Box, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js'
import { useError } from '../context/error'
import { PaymentService } from '../services/payment.service'
import { loadStripe } from '@stripe/stripe-js'
import CreditCardForm from './creditcard-form'
import { useTheme as useCarnivoreTheme } from '../context/theme'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string)

export default function PaymentForm () {
  const { setError } = useError()
  const paymentService = new PaymentService(setError)
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context

  const [couponCode, setCouponCode] = useState<string>('')
  const [discountMessage, setDiscountMessage] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    _load()
  }, [])
  const _load = async () => {
    setLoading(true)
    const res = await paymentService.setupPaymentMethod()
    if (res.clientSecret) {
      setClientSecret(res.clientSecret)
    }
  }
  return (
    <Box>
      {clientSecret !== '' && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: mode === 'dark' ? 'night' : 'flat'
            }
          }}
        >
          <CreditCardForm />
        </Elements>
      )}
    </Box>
  )
}
