import { useEffect, useState } from 'react'
import { useError } from '../../../context/error'
import { PaymentService } from '../../../services/payment.service'
import useBoolean from '../../../hooks/useBoolean'
import WooSubLoadingPage from '../../../components/loading/woo-sub.loading'
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { StyleHelper } from '../../../components/style-helper'
import CarnivoreBox from '../../../components/carnivore-box'
import { Formatters } from '../../../_helpers/formatter.helper'
import { AddCircle } from '@mui/icons-material'
import AddCardModal from '../../../components/modals/add-card.modal'
import CancellationModal from '../../../components/modals/cancellation.modal'
import AlertModal from '../../../components/modals/alert.modal'
import { useGlobalState } from '../../../context/global'
import { SettingsService } from '../../../services/settings.service'

export default function StripeSubPage () {
  const { setError } = useError()

  const [subscriptionInfo, setSubscriptionInfo] = useState<null | any>(null)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])

  const [nextInvoice, setNextInvoice] = useState<any | null>(null)
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const paymentService = new PaymentService(setError)
  const settingsService = new SettingsService(setError)

  const [showAddCard, { toggle: toggleShowCard }] = useBoolean(false)
  const [showCancellation, { toggle: toggleCancellation }] = useBoolean(false)

  const [switchType, setSwitchType] = useState('year')
  const [showSwitch, setShowSwitch] = useState(false)
  const [annualPrice, setAnnualPrice] = useState<number | null>(null)
  const [monthlyPrice, setMonthlyPrice] = useState<number | null>(null)

  const { settings } = useGlobalState()
  const _fetchSubInfo = async () => {
    setLoading(true)
    const res = await paymentService.getSubInfo()
    if (res.subInfo?.subscription) {
      setSubscriptionInfo(res.subInfo?.subscription)
    }
    if (res.subInfo?.invoice) {
      setNextInvoice(res.subInfo?.invoice)
    }

    const pmRes = await paymentService.getPaymentMethods()
    if (pmRes) {
      setPaymentMethods(pmRes)
    }
    const invoicesRes = await paymentService.getInvoiceHistory()
    setInvoices(invoicesRes.data ?? [])

    const monthlyRes = await settingsService.getPrice(
      settings?.proMonthlyPriceID ?? ''
    )

    const annualRes = await settingsService.getPrice(
      settings?.proYearlyPriceID ?? ''
    )

    if (monthlyRes.price) {
      setMonthlyPrice(monthlyRes.price)
    }
    if (annualRes.price) {
      setAnnualPrice(annualRes.price)
    }
    setLoading(false)
  }

  const _refreshPaymentMethods = () => {
    setLoading(true)
    paymentService
      .getPaymentMethods()
      .then(pm => {
        setPaymentMethods(pm ?? [])
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }

  const _resumeSubscription = async () => {
    setLoading(true)
    await paymentService.resumeStripeSubscription()
    _fetchSubInfo()
  }

  const _switchDuration = async (newDuration: string) => {
    setSwitchType(newDuration)
    setShowSwitch(true)
  }

  const _submitSwitch = async () => {
    setLoading(true)
    await paymentService.switchSubDuration(switchType)
    _fetchSubInfo()
  }

  useEffect(() => {
    _fetchSubInfo()
  }, [])

  if (loading) {
    return <WooSubLoadingPage />
  }

  return (
    <Container sx={{ p: 3 }}>
      <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
        Billing
      </Typography>
      <Typography variant='body2'>
        Manage Your Subscription, billing and payment details.
      </Typography>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
          Overview
        </Typography>
        <StyleHelper.VerticalSpace />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              PLAN
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.plan?.interval === 'month'
                ? 'Monthly'
                : 'Annual'}
            </Typography>
            <Button
              variant='text'
              onClick={() =>
                _switchDuration(
                  subscriptionInfo?.plan?.interval === 'month'
                    ? 'year'
                    : 'month'
                )
              }
            >
              Switch
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            height={'100%'}
            sx={{
              p: 2,
              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Status
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.cancel_at_period_end ? 'CANCELED' : 'ACTIVE'}
            </Typography>
            {subscriptionInfo?.cancel_at_period_end && (
              <Button variant='text' onClick={_resumeSubscription}>
                Resume
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              p: 2,

              borderRight: (theme: any) => `2px solid ${theme.palette.divider}`
            }}
          >
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              {!subscriptionInfo?.cancel_at_period_end
                ? 'Next Billing Date'
                : 'Subscription Ends'}
            </Typography>
            <Typography variant='h5' fontWeight={'bold'}>
              {subscriptionInfo?.current_period_end
                ? new Date(
                    subscriptionInfo?.current_period_end * 1000
                  ).toLocaleDateString('en-US', { dateStyle: 'medium' })
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ p: 2 }}>
            <Typography
              variant='body2'
              textTransform={'uppercase'}
              gutterBottom
            >
              Next Payment Amount
              <Typography variant='h5' fontWeight={'bold'}>
                $
                {!subscriptionInfo?.cancel_at_period_end
                  ? Formatters.formatMoney(
                      nextInvoice?.amount_due
                        ? Number(nextInvoice?.amount_due / 100)
                        : 0
                    )
                  : `0.00`}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Payment
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} alignItems={'flex-end'}>
            {/* <Box display='flex' justifyContent='flex-end'>
              <Button
                color='secondary'
                endIcon={<AddCircle />}
                variant='outlined'
                onClick={toggleShowCard}
              >
                Add Payment Method
              </Button>
            </Box> */}
          </Grid>
        </Grid>

        <StyleHelper.SmallVerticalSpace />
        <TableContainer>
          <Table aria-label='payment methods'>
            <TableHead>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell></TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods?.map((pm: any, index: number) => (
                <TableRow key={pm?.id}>
                  <TableCell>{pm?.card?.brand.toUpperCase()}</TableCell>
                  <TableCell>XXXX XXXX XXXX {pm?.card?.last4}</TableCell>
                  <TableCell>
                    {pm?.card?.exp_month}/{pm?.card?.exp_year}
                  </TableCell>
                  <TableCell>
                    {/* <Chip label='Make Default' onClick={() => {}} /> */}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      <CarnivoreBox>
        <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
          Past Invoices
        </Typography>
        <StyleHelper.VerticalSpace />

        <TableContainer>
          <Table aria-label='payment methods'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Receipt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice: any) => (
                <TableRow key={invoice.id}>
                  <TableCell>
                    {new Date(
                      invoice.status_transitions.paid_at * 1000
                    )?.toLocaleDateString('en-US', { dateStyle: 'medium' })}
                  </TableCell>
                  <TableCell>
                    ${Formatters.formatMoney(Number(invoice.total / 100))}
                  </TableCell>
                  <TableCell>
                    {invoice?.lines?.data
                      ?.map((li: any) => li?.description)
                      ?.join(', ')}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='text'
                      color='secondary'
                      onClick={() => {
                        window.open(invoice?.hosted_invoice_url, '_blank')
                      }}
                    >
                      View Receipt
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CarnivoreBox>
      <StyleHelper.VerticalSpace />
      {!subscriptionInfo?.cancel_at_period_end && (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant='outlined'
            color='primary'
            onClick={toggleCancellation}
          >
            Cancel Subscription
          </Button>
        </Box>
      )}
      <AddCardModal
        open={showAddCard}
        onClose={toggleShowCard}
        size='md'
        onComplete={_refreshPaymentMethods}
      />
      {showCancellation && (
        <CancellationModal
          onCancel={() => _fetchSubInfo()}
          open={showCancellation}
          onClose={toggleCancellation}
          size='md'
        />
      )}

      <AlertModal
        size='md'
        open={showSwitch}
        onClose={() => setShowSwitch(false)}
        header={`Are you sure you want to switch?`}
        message={
          switchType === 'year'
            ? `By clicking 'I understand', you agree to be charged immediately for the annual subscription ($${Formatters.formatMoney(
                (annualPrice ?? 0) / 100
              )}), with the amount adjusted for the remaining days of the current billing cycle.`
            : `By clicking 'I understand', you acknowledge that it's best to complete this action the day before your next payment. Your subscription will switch to monthly immediately at $${Formatters.formatMoney(
              (monthlyPrice ?? 0) / 100
            )} /month, you will be charged immediately, and you understand that no refund will be provided for your annual subscription.`
        }
        customAction={_submitSwitch}
        customActionText='I understand'
      />
    </Container>
  )
}
