export class LocalStorageHelper {
  static getDeviceID (): string {
    let deviceID = localStorage.getItem('carnivore-device')
    if (!deviceID) {
      deviceID = window.crypto.randomUUID()
      localStorage.setItem('carnivore-device', deviceID)
    }
    return deviceID
  }
}
