import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Switch,
  Typography,
  useMediaQuery
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import SignupSideBar, { ImgType } from '../../components/signup-sidebar'
import SignupHeader from '../../components/signup-header'
import Package from '../../components/package'
import { StyleHelper } from '../../components/style-helper'
import useBoolean from '../../hooks/useBoolean'
import { useGlobalState } from '../../context/global'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useError } from '../../context/error'
import { SettingsService } from '../../services/settings.service'
import { Formatters } from '../../_helpers/formatter.helper'
import { CheckCircle } from '@mui/icons-material'

const FEATURES = [
  'Morning Pre-Market reports',
  'Live Trade Alerts (SMS, Email, Mobile, & Browser)',
  'Mid Day Reports',
  'Daily Wraps',
  'Weekly Zoom Calls'
]
export default function SignupPackagePage (props: PageProps) {
  useDocumentTitle(props.title)

  const { settings } = useGlobalState()
  const navigate = useNavigate()
  const { setError } = useError()
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const settingsService = new SettingsService(setError)

  const [annualPrice, setAnnualPrice] = useState<number | null>(null)
  const [monthlyPrice, setMonthlyPrice] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    _fetchPrice()
  }, [])

  const _fetchPrice = async () => {
    setIsLoading(true)
    const monthlyRes = await settingsService.getPrice(
      settings?.proMonthlyPriceID ?? ''
    )

    const annualRes = await settingsService.getPrice(
      settings?.proYearlyPriceID ?? ''
    )

    if (monthlyRes.price) {
      setMonthlyPrice(monthlyRes.price)
    }
    if (annualRes.price) {
      setAnnualPrice(annualRes.price)
    }
    setIsLoading(false)
  }

  return (
    <Box sx={{ height: isSmallScreen ? undefined : '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 3
          }}
        >
          <SignupHeader
            header='Select a package'
            body={
              'Choose which package best fits your needs. Choose an annual rate to save!'
            }
          />
          <StyleHelper.DoubleVerticalSpace />
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={6} height={'100%'}>
              <StyleHelper.VerticalSpace />

              <Paper
                sx={{
                  width: '100%',
                  height: '100%',
                  p: 3
                }}
                elevation={3}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  height={'100%'}
                >
                  <Box>
                    <Typography
                      variant='h6'
                      sx={{
                        backgroundColor: 'action.hover',
                        borderRadius: '10px',
                        px: 2,
                        py: 1,
                        textTransform: 'uppercase',
                        display: 'inline-block'
                      }}
                    >
                      Monthly
                    </Typography>

                    <StyleHelper.VerticalSpace />
                    {isLoading ? (
                      <Skeleton height={56} />
                    ) : (
                      <Typography variant='h3' textAlign={'center'}>
                        ${Formatters.formatMoney((monthlyPrice ?? 0) / 100)}{' '}
                        <small style={{ fontSize: '0.9rem' }}>/month</small>
                      </Typography>
                    )}
                  </Box>

                  <List>
                    {FEATURES.map((item, index) => (
                      <ListItem key={index} disablePadding sx={{ mt: 1 }}>
                        <ListItemIcon>
                          <CheckCircle />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant='contained'
                    onClick={() => navigate(`/signup/payment?p=pro&c=monthly`)}
                  >
                    Select Monthly
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} height='100%'>
              <StyleHelper.VerticalSpace />

              <Paper
                sx={{
                  width: '100%',
                  height: '100%',
                  p: 3,
                  backgroundColor: 'primary.main'
                }}
                elevation={3}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  height={'100%'}
                >
                  <Box>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography
                        variant='h6'
                        sx={{
                          backgroundColor: 'action.hover',
                          borderRadius: '10px',
                          px: 2,
                          py: 1,
                          textTransform: 'uppercase',
                          display: 'inline-block'
                        }}
                      >
                        Annual
                      </Typography>
                      <Chip label="2 Months Free!"  variant='filled' color='secondary' />
                    </Box>
                    <StyleHelper.VerticalSpace />
                    {isLoading ? (
                      <Skeleton height={56} />
                    ) : (
                      <Typography variant='h3' textAlign={'center'}>
                        ${Formatters.formatMoney((annualPrice ?? 0) / 100)}{' '}
                        <small style={{ fontSize: '0.9rem' }}>/year</small>
                      </Typography>
                    )}
                  </Box>

                  <List>
                    <ListItem disablePadding sx={{ mt: 1 }}>
                      <ListItemIcon>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ fontWeight: 'bold' }}
                        primary={`$${Formatters.formatMoney(
                          ((monthlyPrice ?? 0) * 12 - (annualPrice ?? 1)) / 100
                        )} In Annual Savings`}
                      />
                    </ListItem>
                    {FEATURES.map((item, index) => (
                      <ListItem key={index} disablePadding sx={{ mt: 1 }}>
                        <ListItemIcon>
                          <CheckCircle />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>

                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => navigate(`/signup/payment?p=pro&c=yearly`)}
                  >
                    Select Annual
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={4}>
              <StyleHelper.VerticalSpace />
              <Package
                onClick={() =>
                  navigate(
                    `/signup/payment?p=basic&c=${
                      isAnnual ? 'yearly' : 'monthly'
                    }`
                  )
                }
                name='basic'
                description='For weekend warriors and long term investors. Fewer alerts and less maintenance.'
                priceID={
                  isAnnual
                    ? settings?.basicYearlyPriceID ?? ''
                    : settings?.basicMonthlyPriceID ?? ''
                }
                isMonthly={!isAnnual}
                includedFeatures={['Long Term Portfolio']}
                excludedFeatures={[
                  'Live Alerts',
                  'Live Calls',
                  'Sector Heaters',
                  'Daily Wrap'
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Package
                isPrimary={true}
                name='pro'
                onClick={() =>
                  navigate(
                    `/signup/payment?p=pro&c=${isAnnual ? 'yearly' : 'monthly'}`
                  )
                }
                description='For weekend warriors and long term investors. Fewer alerts and less maintenance.'
                priceID={
                  isAnnual
                    ? settings?.proYearlyPriceID ?? ''
                    : settings?.proMonthlyPriceID ?? ''
                }
                isMonthly={!isAnnual}
                includedFeatures={['Long Term Portfolio']}
                excludedFeatures={['Live Calls']}
              />
            </Grid>
            <Grid item xs={4}>
              <StyleHelper.VerticalSpace />
              <Package
                onClick={() =>
                  navigate(
                    `/signup/payment?p=basic&c=${
                      isAnnual ? 'yearly' : 'monthly'
                    }`
                  )
                }
                name='basic'
                description='For weekend warriors and long term investors. Fewer alerts and less maintenance.'
                priceID={
                  isAnnual
                    ? settings?.basicYearlyPriceID ?? ''
                    : settings?.basicMonthlyPriceID ?? ''
                }
                isMonthly={!isAnnual}
                includedFeatures={['Long Term Portfolio']}
                excludedFeatures={['Live Calls']}
              />
            </Grid>
          </Grid> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            bgcolor: 'action.hover',
            p: 3
          }}
        >
          <SignupSideBar
            imgType={ImgType.package}
            component={
              <Box>
                <Typography variant='h5' color={'primary'}>
                  Become a Pro Trader
                </Typography>
                <StyleHelper.VerticalSpace />
                <Typography variant='h4'>
                  Daily Stock Market Analysis. Weekly Zoom Calls w/ Lifetime
                  Traders. Real-Time Trade Alerts.
                </Typography>
                <StyleHelper.DoubleVerticalSpace />
              </Box>
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
