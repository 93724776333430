import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useState } from 'react'
import CarnivoreLogo from '../imgs/carnivore-logo'
import { StyleHelper } from '../style-helper'
import {
  AccessAlarm,
  ArrowLeft,
  ArrowRightAlt,
  CalendarMonth,
  Call,
  Coffee,
  HourglassFullOutlined,
  Info,
  LocalFireDepartment,
  MenuBook,
  NotificationsActive,
  School,
  WbTwilight
} from '@mui/icons-material'
import CarnivoreLogoTxt from '../imgs/carnivore-logo-txt'
import GettingStartedInfo from '../getting-started-info'
import DownloadTheApp from '../download-app'
import VerifyEmail from '../verify-email'
import { useGlobalState } from '../../context/global'

interface WelcomeModalProps {
  open: boolean
  size: 'sm' | 'md' | 'lg'
  onClose: () => void
}

const INFO = [
  {
    title: 'Pre-market Report',
    content: `Start your day with our Pre-Market Report, your essential guide to navigating the day's trading landscape. Delivered before the market opens each day, our report provides expert commentary on anticipated market trends and highlights speculative stocks we're eyeing for potential trades.
     Stay ahead of the curve with our insights to optimize your trading strategy.`,
    icon: <Coffee fontSize={'inherit'} />
  },
  {
    title: 'Mid-day Update',
    content: `Check out our Mid-Day Update for a pulse on the market's performance and a closer look at stocks previously highlighted in our Pre-Market Report.
    Stay informed as we track how the day unfolds and provide valuable insights to guide your trading decisions.`,
    icon: <WbTwilight fontSize={'inherit'} />
  },
  {
    title: 'Daily Wrap',
    content: `Wrap up your trading day with our comprehensive Daily Wrap, featuring in-depth analysis of market movements, updates on our current positions, and insightful commentary from Trader Z.
    Gain valuable perspective on the day's events and strategic insights to refine your trading approach for tomorrow.`,
    icon: <MenuBook fontSize={'inherit'} />
  },
  {
    title: 'Market Alerts',
    content: `Receive timely updates throughout the day with our Market Alerts, notifying you of buy and sell actions from our model portfolio in real-time.
    Stay informed and agile in your trading decisions with our continuous stream of strategic updates.`,
    icon: <NotificationsActive fontSize={'inherit'} />
  },
  {
    title: 'Member Calls',
    content: `Join us for our exclusive Member Calls, held weekly, where we delve into market insights, conduct live Q&A sessions, and provide valuable opportunities for interactive discussion.
    Elevate your trading knowledge and stay ahead of the curve with direct access to our expert analysis and personalized guidance.`,
    icon: <Call fontSize={'inherit'} />
  },
  {
    title: 'Sector Heaters',
    content: `Discover potential winners across multiple sectors with our Sector Heaters, where we curate a list of stocks poised for success based on our expert analysis.
     Navigate the market landscape with confidence as we highlight sector-specific opportunities and provide actionable insights to fuel your investment strategies.`,
    icon: <LocalFireDepartment fontSize={'inherit'} />
  },
  {
    title: 'Buy & Hold',
    content: `Dive into our Buy & Hold Portfolio, meticulously crafted with a focus on long-term plays that stand the test of time.
    Gain access to insightful commentary from Trader Z as we navigate the ever-evolving market landscape, providing you with strategic insights to build a robust investment portfolio for sustained growth`,
    icon: <HourglassFullOutlined fontSize={'inherit'} />
  },
  {
    title: 'Events',
    content: `Stay informed and never miss a beat with our Events Page, your go-to hub for all our latest news appearances, upcoming call schedules, and exclusive events. Whether it's catching a glimpse of us in the media spotlight or planning your participation in our interactive calls, this page keeps you connected and engaged with our community.`,
    icon: <CalendarMonth fontSize={'inherit'} />
  },
  {
    title: 'Carnivore Academy',
    content: `Indulge in an exclusive experience unlike any other at the Carnivore Academy, where you can join the Carnivore team in our luxurious mansion for three days of gourmet dining and immersive learning about the stock market from the insightful minds of Dutch and Z. Elevate your trading skills while savoring fine cuisine and networking with fellow enthusiasts in an unforgettable setting.`,
    icon: <School fontSize={'inherit'} />
  }
]
export default function WelcomeModal(props: WelcomeModalProps) {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const { setShowIntro, setIsSidebarOpen, user } = useGlobalState()
  const [step, setStep] = useState(0)
  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (props.size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  const _complete = async () => { }

  return (
    <Modal
      open={props.open}
      aria-labelledby='getting-started-modal'
      aria-describedby='walk through carnivore trading'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        {step === 0 && (
          <>
            <Grid container justifyContent={'center'}>
              <Grid item xs={1} lg={4}>
                <CarnivoreLogoTxt />
              </Grid>
            </Grid>
            <Typography variant='h4' fontWeight={'bold'}>
              Welcome To Carnivore Trading
            </Typography>
            <StyleHelper.VerticalSpace />
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} lg={6}>
                <Typography variant='body1' textAlign={'center'}>
                  You are now a part of the Carnivore Family. Get hedge fund
                  quality research, high-conviction stock ideas, daily market
                  analysis and real-time trade alerts.
                </Typography>
                <StyleHelper.VerticalSpace />

                <Typography variant='body1' textAlign={'center'}>
                  Before you get started, we will walk you through a our core
                  product offerings, and ask you a few questions.
                </Typography>
                <StyleHelper.DoubleVerticalSpace />
                <Alert icon={<Info fontSize="inherit" />} severity="error">
                  Carnivore's Trading Desk is currently in early accecss beta. You will have a new password for this site independent from carnivoretrading.com. Please report any issues by emailing support@carnivoretrading.com or by clicking the support icon in the top right.
                </Alert>
                <StyleHelper.DoubleVerticalSpace />

              </Grid>
            </Grid>
          </>
        )}
        {step > 0 && step < 10 && (
          <>
            <GettingStartedInfo
              title={INFO[step - 1].title}
              content={INFO[step - 1].content}
              icon={INFO[step - 1].icon}
            />
          </>
        )}

        {step === 10 && <DownloadTheApp />}
        {step === 11 && (
          <VerifyEmail
            onComplete={() => {
              setTimeout(() => {
                setShowIntro(true)
              }, 400)
              props.onClose()
            }}
          />
        )}

        {step < 11 && (
          <Box display={'flex'} flexDirection={'row'}>
            {step > 0 && (
              <Button
                sx={{ mr: 2 }}
                variant='outlined'
                startIcon={<ArrowLeft />}
                onClick={() => setStep(prev => prev - 1)}
              >
                Back
              </Button>
            )}
            <Button
              variant='contained'
              endIcon={<ArrowRightAlt />}
              onClick={() =>
                setStep(prev => {
                  if (prev === 10 && user?.emailVerified === true) {
                    setTimeout(() => {
                      setShowIntro(true)
                    }, 400)
                    props.onClose()
                    return prev + 2
                  }
                  return prev + 1
                })
              }
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
