import { Notifications, NotificationsOff } from '@mui/icons-material'
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useError } from '../context/error'
import { useState } from 'react'
import { UserService } from '../services/user.service'
import { LocalStorageHelper } from '../_helpers/localstorage.helper'
import { useGlobalState } from '../context/global'
import AlertModal from './modals/alert.modal'
import { useSuccess } from '../context/success'
import SendTestPush from './test-push'

interface WebPushProps {
  btnDisplay?: boolean
  btnText? : string
}
export default function WebPush (props: WebPushProps) {
  const { setError } = useError()
  const { setSuccess } = useSuccess()

  const [loading, setLoading] = useState(false)
  const { setUser, user } = useGlobalState()
  const userService = new UserService(setError)
  const [showAlert, setShowAlert] = useState(false)
  const [showOverride, setShowOverride] = useState(false)

  const _handleChange = async () => {
    if (
      (navigator as any).brave &&
      (await (navigator as any).brave.isBrave())
    ) {
      setError(
        'Brave browser is unsupported. Please use Chrome, Firefox, or Safari for browser notifications'
      )
      return
    }

    if (window.navigator.userAgent.includes('Edg/')) {
      setError(
        'Edge browser is unsupported. Please use Chrome, Firefox, or Safari for browser notifications'
      )
      return
    }

    if (
      user?.browserNotification?.deviceId !== null &&
      user?.browserNotification?.deviceId !== LocalStorageHelper.getDeviceID()
    ) {
      setShowOverride(true)
      return
    }

    if (
      user?.browserNotification?.deviceId === LocalStorageHelper.getDeviceID()
    ) {
      setShowAlert(true)
      return
    }

    await _subscribeToAlerts()
  }

  const _subscribeToAlerts = async () => {
    if (
      (navigator as any).brave &&
      (await (navigator as any).brave.isBrave())
    ) {
      setError(
        'Brave browser is unsupported. Please use Chrome, Firefox, Edge or Safari for browser notifications'
      )
      return
    }
    setLoading(true)
    const permission = await Notification.requestPermission()
    if (permission === 'denied') {
      setError('Notification Permission is disabled')
      return
    }
    const notification = await navigator.serviceWorker
      .register('/service-worker.js')
      .then(function (registration) {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.REACT_APP_PUBLIC_VAPID_KEY as string
          )
        }

        return registration.pushManager.subscribe(subscribeOptions)
      })

    const res = await userService.updateUser({
      browserNotification: {
        p256dh: arrayBufferToBase64(notification.getKey('p256dh') ?? undefined),
        auth: arrayBufferToBase64(notification.getKey('auth') ?? undefined),
        endpoint: notification.endpoint,
        deviceId: LocalStorageHelper.getDeviceID()
      }
    })

    if (res.user) {
      console.log(res)
      setUser(res.user)
      setSuccess('Successfully subscribed to web notifications')
    }
    setLoading(false)
  }

  function arrayBufferToBase64 (buffer?: ArrayBuffer) {
    if (buffer) {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return window.btoa(binary)
    }
  }

  function urlBase64ToUint8Array (base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  return (
    <>
      <AlertModal
        size='md'
        open={showAlert}
        onClose={() => setShowAlert(false)}
        customContent={<SendTestPush />}
        header='Web Alerts Are Active'
        message='No action is required, you have web alerts enabled. You are allowed (1) browser to receive web alerts on.'
        customAction={_subscribeToAlerts}
        customActionText='Resubscribe Anyway'
      />

      <AlertModal
        size='md'
        open={showOverride}
        onClose={() => setShowOverride(false)}
        header='Switch Alerts to This Device?'
        message={`You're set up to get alerts on another device. Tap 'Confirm' to start getting alerts on this device instead. The other device will stop receiving alerts.`}
        customAction={_subscribeToAlerts}
        customActionText='Confirm'
      />
      {loading ? (
        <CircularProgress />
      ) : props.btnDisplay ? (
        <Button variant='outlined' onClick={_handleChange}>{props.btnText ?? "Manage"}</Button>
      ) : (
        <Tooltip title={`Browser Notifications`}>
          <IconButton
            id='notification-btn'
            onClick={_handleChange}
            aria-label='set chrome alerts'
          >
            {user?.browserNotification?.deviceId ===
            LocalStorageHelper.getDeviceID() ? (
              <Notifications />
            ) : (
              <NotificationsOff />
            )}
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}
