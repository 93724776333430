import React, { useEffect } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import { StyleHelper } from '../components/style-helper'
import { useError } from '../context/error'
import { UserService } from '../services/user.service'

const LogoutPage: React.FC = () => {
  useEffect(() => {
    console.log('GETTING CALLED')
    // _logout()
  }, [])

  const { setError } = useError()
  const userService = new UserService(setError)

  const _logout = () => {
    userService.logout()
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={1}>
          {/* Your image goes here */}
          <CarnivoreLogo />
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <CircularProgress size={24} />
    </Box>
  )
}

export default LogoutPage
