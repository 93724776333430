import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { CarnivoreAlert } from '../_types/alert.type'
import { Campaign, Insights } from '@mui/icons-material'
import { StyleHelper } from './style-helper'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import { useGlobalState } from '../context/global'

interface AlertViewProps {
  alert: CarnivoreAlert
  hideIcon?: boolean
  liveID?: string
  showAlert: (alert: CarnivoreAlert) => void
}

export default function AlertView (props: AlertViewProps) {
  const { alert } = props

  const { viewedAlerts } = useGlobalState()
  const _formateTime = (timestamp: number) => {
    const date = new Date(timestamp)
    return `${date.toLocaleDateString('en-US', {
      dateStyle: 'full'
    })} at ${date.toLocaleTimeString('en-US', { timeStyle: 'short' })}`
  }

  return (
    <Paper elevation={3} sx={{ m: 1, p: 2 }}>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Box
          className={props.liveID === alert.id ? 'dot-grow' : undefined}
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',

            backgroundColor: viewedAlerts
              ?.map(a => a.alertID)
              ?.includes(alert.id)
              ? 'transparent'
              : 'primary.main'
          }}
        />
      </Box>
      <Grid container alignItems='center'>
        {!props.hideIcon && (
          <Grid item xs={12} md={12} sm={12} lg={1}>
            {alert.alertType === 'alert' ? (
              <Campaign sx={{ fontSize: 36 }} />
            ) : (
              <Insights sx={{ fontSize: 36 }} />
            )}
          </Grid>
        )}
        <Grid item lg={props.hideIcon ? 12 : 8} md={12} xs={12}>
          <Typography color='primary' variant='h6' fontWeight={'bold'}>
            {alert.subject}
          </Typography>
          <Typography variant='body2'>
            {_formateTime(alert.timeStamp)}
          </Typography>
          <StyleHelper.VerticalSpace />

          <Typography variant='body1'>
            {alert.content.substring(0, 100)}...
          </Typography>
        </Grid>
        <Grid
          item
          lg={props.hideIcon ? 12 : 3}
          md={12}
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          alignItems='flex-end'
        >
          <Button onClick={() => props.showAlert(alert)} variant='contained'>
            View Alert
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
