import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { useGlobalState } from '../../context/global'
import { useTextInput } from '../../hooks/useTextField'
import { Cancel, SupportAgent } from '@mui/icons-material'
import { StyleHelper } from '../style-helper'
import { FormEvent, useState } from 'react'
import LoadingButton from '../controls/loading-btn'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'
import { useSuccess } from '../../context/success'

export default function SupportModal (props: CarnivoreModalProps) {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const emailInput = useTextInput({
    initialValue: ''
  })

  const messageInput = useTextInput({
    initialValue: ''
  })

  const { user } = useGlobalState()
  const {setError} = useError()
  const {setSuccess} = useSuccess()

  const userService = new UserService(setError)
  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (props.size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  const _sendSupport = async (e : FormEvent) => {
    e.preventDefault()
    setLoading(true)
    await userService.sendSupport(messageInput.value)
    setSuccess(`Support Message Received. A confirmation message will arrive shortly.`)
    setLoading(false)
    messageInput.reset()
    props.onClose()
  }


  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='support modal'
      aria-describedby='support modal'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: theme.palette.background.paper,
          border: `2px solid #000`,
          boxShadow: 24,
          p: 4
        }}
      >
        <Box display={'flex'} justifyContent={'flex-end'}>
          <IconButton onClick={props.onClose}>
            <Cancel />
          </IconButton>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant='h1'>
            <SupportAgent fontSize='inherit' color='primary' />
          </Typography>

          <Typography variant='h5' textAlign={'center'} fontWeight={'bold'}>
            Submit Support Message
          </Typography>
        </Box>
        <form onSubmit={_sendSupport}>
          <StyleHelper.DoubleVerticalSpace />
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} lg={7}>
              <Typography variant='body1' textAlign={'center'}>
                If you need assistance, please fill out the form below to send a
                message to our support team. We will get back to you within one
                business day.
              </Typography>
              <StyleHelper.VerticalSpace />
              <Typography
                variant='subtitle1'
                sx={{ mb: 2 }}
                fontWeight={'bold'}
              >
                Email
              </Typography>
              <TextField
                label='Email'
                required
                variant='outlined'
                type='email'
                disabled={user?.email !== null}
                fullWidth
                value={user?.email !== null ? user?.email : emailInput.value}
                error={emailInput.error}
                helperText={emailInput.helperText}
                onChange={emailInput.onChange}
              />

              <StyleHelper.DoubleVerticalSpace />
              <Typography
                variant='subtitle1'
                sx={{ mb: 2 }}
                fontWeight={'bold'}
              >
                Message
              </Typography>
              <TextField
                label='Message'
                required
                variant='outlined'
                type='text'
                fullWidth
                multiline
                rows={7}
                value={messageInput.value}
                error={messageInput.error}
                helperText={messageInput.helperText}
                onChange={messageInput.onChange}
              />
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />
          <Box display={'flex'} justifyContent={'center'}>
            <LoadingButton
              loading={loading}
              variant='contained'
              type='submit'
              disabled={messageInput.value === '' || loading}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}
