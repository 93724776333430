import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class FileService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async getPresignedUploadURL (
    key : string
  ): Promise<
    | { url: string; key: string; dlUrl: string }
    | { errMSg: string; url?: string; key?: string; dlUrl?: string }
  > {
    return axiosInstance
      .post('/file/download', {
        key
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
