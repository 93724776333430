import {
  CalendarMonth,
  HourglassFull,
  LocalFireDepartment,
  MenuBook,
  NotificationsActive,
  Videocam
} from '@mui/icons-material'
import { DashboardLink } from '../_types/dashboard-link.type'

const DASHBOARD_QUICK_LINKS: DashboardLink[] = [
  {
    icon: <MenuBook />,
    title: 'Read the Latest Wrap',
    supportingTxt:
      'Read the most recent market wrap and get caught up with previous wraps',
    btnTxt: 'Read It',
    link: '/daily-wrap'
  },
  {
    icon: <LocalFireDepartment />,
    title: 'View Sector Heaters',
    supportingTxt:
      'See our expert picks in a sector we think is going nowhere but through the roof',
    btnTxt: 'View',
    link: '/sector-heaters'
  },
  {
    icon: <NotificationsActive />,
    title: 'Live Market Alerts/Updates',
    supportingTxt:
      'Get alerts live right from this page, or view previous alerts',
    btnTxt: 'View',
    link: '/alerts'
  },
  // {
  //   icon: <Videocam />,
  //   title: 'Video Library',
  //   supportingTxt:
  //     "View recordings of member calls and access Carnivore's digital resources",
  //   btnTxt: 'Watch',
  //   link: '/videos'
  // },
  {
    icon: <CalendarMonth />,
    title: 'View Events',
    supportingTxt: 'See upcoming events in the Carnivore World',
    btnTxt: 'View',
    link: '/calendar'
  },
  {
    icon: <HourglassFull />,
    title: 'Buy & Hold Positions',
    supportingTxt: 'You asked, we answered. Check out our NEW stock portfolio for longer-term holdings',
    btnTxt: 'View',
    link: '/buy-hold'
  }
]

export default DASHBOARD_QUICK_LINKS
