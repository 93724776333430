import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

export interface SearchOptions {
  clear: boolean
  page: number
}

interface AlertSearchBarProps {
  onSearch: (ticker: string, options: SearchOptions) => void
}

const AlertSearchBar: React.FC<AlertSearchBarProps> = ({ onSearch }) => {
  const [searchText, setSearchText] = useState<string>('')

  const handleSearch = () => {
    onSearch(searchText, { clear: true, page: 1 })
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Trigger search on Enter key
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClear = () => {
    setSearchText('')
    onSearch('', { clear: true, page: 1 })
  }

  return (
    <Box display='flex' justifyContent='center' mx={1} mt={1} mb={2}>
      <TextField
        variant='outlined'
        placeholder='Search...'
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
              <IconButton onClick={handleClear} edge='end'>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        fullWidth
      />
    </Box>
  )
}

export default AlertSearchBar
