import React, { useState, MouseEvent } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home'

import {
  CalendarMonth,
  HourglassFullOutlined,
  LocalFireDepartment,
  MenuBook,
  NotificationsActive,
  School,
  Settings,
  Videocam
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, ListItemIcon } from '@mui/material'
import { StyleHelper } from './style-helper'
import LegalBanner from './legal'
import LogoutModal from './modals/logout.modal'
interface MenuItemType {
  text: string
  link: string
}

const MobileNavBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate() // Hook for navigation
  const location = useLocation() // Hook to get current location
  const [showLogout, setShowLogout] = useState(false)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavigation = (path: string) => {
    navigate(path) // Navigate to the clicked path
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === 'keydown' &&
          (event as React.KeyboardEvent).key === 'Tab') ||
        (event as React.KeyboardEvent).key === 'Shift'
      ) {
        return
      }
      setDrawerOpen(open)
    }

  const navItems = [
    {
      text: 'Dashboard',
      icon: <HomeIcon />,
      path: '/dashboard',
      id: 'dashboard-link'
    },
    {
      text: 'Trade Alerts',
      icon: <NotificationsActive />,
      path: '/alerts',
      id: 'alerts-link'
    },
    {
      text: 'Sector Heaters',
      icon: <LocalFireDepartment />,
      path: '/sector-heaters',
      id: 'heaters-link'
    },
    {
      text: 'Buy & Hold',
      icon: <HourglassFullOutlined />,
      path: '/buy-hold',
      id: 'longterm-link'
    },
    {
      text: 'Calendar',
      icon: <CalendarMonth />,
      path: '/calendar',
      id: 'calendar-link'
    },

    {
      text: 'Daily Wrap',
      icon: <MenuBook />,
      path: '/daily-wrap',
      id: 'dailywrap-link'
    },
    // { text: 'Videos', icon: <Videocam />, path: '/videos', id: 'videos-link' },
    // { text: 'Academy', icon: <School />, path: '/academy', id: 'academy-link' },

    {
      text: 'Settings',
      icon: <Settings />,
      path: '/settings',
      id: 'settings-link'
    }
  ]

  const renderDrawerItems = navItems.map((item, index) => (
    <ListItem
      id={item.id}
      button
      key={index}
      onClick={() => handleNavigation(item.path)}
      sx={{
        backgroundColor:
          location.pathname === item.path ? 'primary.main' : 'inherit', // Highlight the current page
        color:
          location.pathname === item.path ? 'primary.contrastText' : 'inherit'
      }}
    >
      <ListItemIcon
        sx={{
          color: location.pathname === item.path ? 'inherit' : 'action.active'
        }}
      >
        {item.icon}
      </ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  ))

  return (
    <AppBar position='sticky'>
      <Toolbar>
        <>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <StyleHelper.HorizontalSpace />

          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Box
              component='img'
              sx={{ width: 18 }}
              src={require('../assets/imgs/logo.png')}
              alt='Carnivore Logo'
            />
            <StyleHelper.SmallHorizontalSpace />

            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Carnivore Trading
            </Typography>
          </Box>

          <Drawer
            anchor='left'
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            sx={{
              boxSizing: 'border-box',
              transition: 'width 0.3s ease',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 'action.hover'
            }}
          >
            <Box sx={{ p: 1, alignSelf: 'center', textAlign: 'center' }}>
              {' '}
              {/* Add textAlign: 'center' */}
              <Box
                component='img'
                sx={{ width: 33 }}
                src={require('../assets/imgs/logo.png')}
                alt='Carnivore Logo'
              />
            </Box>
            <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              {renderDrawerItems}
            </List>

            <Button sx={{mt : 3}} variant='text' onClick={() => setShowLogout(true)}>
              Logout
            </Button>

            <LegalBanner />
            <LogoutModal
              size='md'
              open={showLogout}
              onClose={() => setShowLogout(false)}
            />
          </Drawer>
        </>
      </Toolbar>
    </AppBar>
  )
}

export default MobileNavBar
