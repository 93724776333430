import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import WrapCard from "../wrapcard";
import { StyleHelper } from "../style-helper";
import { ArrowRightAltRounded } from "@mui/icons-material";
import { useState } from "react";
import VideoModal from "../modals/content/video-display.modal";



const videos = [
    {
        title: 'Advanced Swing Trading - Module 1',
        key: 'videos/modules/module_1.mp4',
        img: require('../../assets/imgs/content/module_1.jpg')
    },
    {
        title: 'Advanced Swing Trading - Module 2',
        key: 'videos/modules/module_2.mp4',
        img: require('../../assets/imgs/content/module_2.jpg')

    },
    {
        title: 'Advanced Swing Trading - Module 3',
        key: 'videos/modules/module_3.mp4',
        img: require('../../assets/imgs/content/module_3.jpg')

    },
    {
        title: 'Advanced Swing Trading - Module 4',
        key: 'videos/modules/module_4.mp4',
        img: require('../../assets/imgs/content/module_4.jpg')

    },
    {
        title: 'Advanced Swing Trading - Module 5',
        key: 'videos/modules/module_5.mp4',
        img: require('../../assets/imgs/content/module_5.jpg')

    },
    {
        title: 'Advanced Swing Trading - Module 6',
        key: 'videos/modules/module_6.mp4',
        img: require('../../assets/imgs/content/module_6.jpg')

    },
]
export default function VideoEducation() {

    const [selectedVideo, setSelectedVideo] = useState<{ title: string, key: string } | null>()

    return <Box p={2}>

        <Grid container flexWrap={'wrap'} spacing={2}>



            {videos.map((v) => <Grid item lg={4} xs={12} key={v.key} >
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                        component='img'
                        height='140'
                        sx={{ objectPosition: 'center top' }}
                        image={v.img}
                        alt='wrap header'
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant='subtitle1'>
                                {v.title}
                            </Typography>


                        </Box>
                        <StyleHelper.VerticalSpace />
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => { setSelectedVideo(v) }}
                            endIcon={<ArrowRightAltRounded />}
                        >
                            View
                        </Button>
                    </CardContent>
                </Card>
            </Grid>)}

        </Grid>
        <VideoModal title={selectedVideo?.title ?? ""} open={selectedVideo != null} onClose={() => setSelectedVideo(null)} size="lg" objectKey={selectedVideo?.key ?? ""} />
    </Box>
}