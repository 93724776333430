import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import SignupSideBar, { ImgType } from '../../components/signup-sidebar'
import SignupHeader from '../../components/signup-header'
import Package from '../../components/package'
import { StyleHelper } from '../../components/style-helper'
import useBoolean from '../../hooks/useBoolean'
import { useGlobalState } from '../../context/global'
import { useNavigate } from 'react-router-dom'
import PaymentForm from '../../components/payment-form'
import { FormEvent, useEffect, useState } from 'react'
import { useError } from '../../context/error'
import { SettingsService } from '../../services/settings.service'
import useQueryParams from '../../hooks/useQueryParams'
import { Formatters } from '../../_helpers/formatter.helper'
import { PaymentService } from '../../services/payment.service'
import { useTextInput } from '../../hooks/useTextField'
import LoadingButton from '../../components/controls/loading-btn'
import { useSuccess } from '../../context/success'

export default function SignupPaymentPage (props: PageProps) {
  useDocumentTitle(props.title)

  const navigate = useNavigate()

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%', overflowY: 'scroll' }}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 3
          }}
        >
          <SignupHeader
            header='Enter Payment Information'
            body={
              "You're almost finished. One last step, we need your payment information"
            }
          />
          <StyleHelper.VerticalSpace />
          <Box display={'flex'} alignItems={'flex-start'} pl={3}>
            <Button variant='text' onClick={() => navigate('/signup/package')}>
              Back to Package Selection
            </Button>
          </Box>
          <StyleHelper.DoubleVerticalSpace />
          <Divider />
          <StyleHelper.DoubleVerticalSpace />
          <PaymentForm />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            bgcolor: 'action.hover',
            p: 3
          }}
        >
          <SignupSideBar
            imgType={ImgType.payment}
            component={
              <Box>
                <Typography variant='h5' color={'primary'}>
                  Performance Over Everything
                </Typography>
                <StyleHelper.VerticalSpace />
                <Typography variant='h4'>
                  Since we started years ago, Carnivore has never had a year
                  without <Typography variant='h4' display='inline' fontWeight={'bold'} color={'primary'}>three-digit returns</Typography>.
                </Typography>
                <StyleHelper.DoubleVerticalSpace />
              </Box>
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
