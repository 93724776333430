import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import CarnivoreLogo from '../../components/imgs/carnivore-logo'
import { StyleHelper } from '../../components/style-helper'
import CarnivoreBox from '../../components/carnivore-box'
import LoadingButton from '../../components/controls/loading-btn'
import CarnivoreLogoTxt from '../../components/imgs/carnivore-logo-txt'
import { useNavigate } from 'react-router-dom'
import { FormEvent, useState } from 'react'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'
import { useTextInput } from '../../hooks/useTextField'
import { CheckCircle } from '@mui/icons-material'

export default function ForgotFlowPage (props: PageProps) {
  useDocumentTitle(props.title)

  const { setError } = useError()
  const navigate = useNavigate()

  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const emailInput = useTextInput({
    initialValue: ''
  })

  const userService = new UserService(setError)

  const _sendLink = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await userService.sendForgot(emailInput.value)
    setIsLoading(false)
    setIsSent(true)
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={3}>
          {/* Your image goes here */}
          <CarnivoreLogoTxt />
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={11} md={4}>
          <Paper elevation={3}>
            <Box p={4}>
              {!isSent && (
                <>
                  <Typography
                    variant='subtitle1'
                    fontWeight={'bold'}
                    color={'primary'}
                    gutterBottom
                  >
                    Recover Password
                  </Typography>
                  <StyleHelper.VerticalSpace />

                  <Divider />
                  <StyleHelper.DoubleVerticalSpace />
                  <form onSubmit={_sendLink}>
                    <TextField
                      fullWidth
                      type='email'
                      required
                      variant='outlined'
                      label='Email'
                      placeholder='Email'
                      value={emailInput.value}
                      onChange={emailInput.onChange}
                    />
                    <StyleHelper.DoubleVerticalSpace />
                    <LoadingButton
                      loading={isLoading}
                      disabled={isLoading}
                      variant='contained'
                      type='submit'
                      fullWidth
                    >
                      Email me a recovery link
                    </LoadingButton>
                    <StyleHelper.VerticalSpace />
                    <Box display={'flex'} justifyContent={'center'}>
                      <Button variant='text' onClick={() => navigate('/login')}>
                        Back To Login
                      </Button>
                    </Box>
                  </form>
                </>
              )}
              {isSent && (
                <>
                  <Box display={'flex'} justifyContent={'center'}>
                    <CheckCircle color='success' fontSize='large' />
                  </Box>
                  <StyleHelper.DoubleVerticalSpace />
                  <Typography variant='body2'>
                    Your password recovery link has been sent. Please check your
                    inbox and allow 1-2 minutes for the email to arrive. This link will expire in 15 minutes.
                  </Typography>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button variant='text' onClick={() => navigate('/login')}>
                      Back To Login
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
