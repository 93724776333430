export class Formatters {
  static formatMoney(amount: number): string {
    try {

      try {
        amount = Number(amount)
      } catch (e) {

      }
      return (
        amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ?? 'unavailable'
      )
    } catch (e) {
      return "error"
    }
  }

  // Function to format a number into a percentage format
  static formatPercentage(percentage: number): string {
    try {
      try {
        percentage = Number(percentage)
      } catch (e) {

      }
      return `${percentage.toFixed(2)}%`
    } catch (e) {
      return "error"
    }
  }

  static formatCommas(num: number) {
    try {
      return num.toLocaleString()

    } catch (e) {
      return `${num}`
    }
  }

  static removeHyphensAndUppercase(input: string) {
    try {


      return input
        .replace(/-/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    } catch (e) {
      return ""
    }
  }
}
