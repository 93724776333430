import { useState } from 'react'
import FullScreenImgModal from './modals/fullscreen-img.modal'
import { Box } from '@mui/material'

interface FullScreenImgProps {
  imgSrc: string
}
export default function FullScreenImg (props: FullScreenImgProps) {
  const [showFull, setShowFull] = useState(false)
  return (
    <>
      <Box
        component='img'
        sx={{
          width: '100%', // Makes the image responsive
          height: 'auto',
          cursor: 'pointer' // Maintains aspect ratio
        }}
        borderRadius={2}
        src={props.imgSrc}
        onClick={() => setShowFull(true)}
        alt='placeholder'
      />

      <FullScreenImgModal
        onClose={() => setShowFull(false)}
        imgSrc={props.imgSrc}
        size='lg'
        open={showFull}
      />
    </>
  )
}
