import React, { useState, useEffect, useRef } from 'react'
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Box,
  FormLabel
} from '@mui/material'

interface PhoneNumberInputProps {
  onChange: (value: string) => void
  defaultValue?: string
}

const codes = [
  '1',
  '44',
  '91',
  '86',
  '81',
  '49',
  '33',
  '39',
  '7',
  '61',
  '55',
  '31',
  '34',
  '27',
  '82',
  '46',
  '47',
  '64',
  '51',
  '52',
  '53',
  '54',
  '56',
  '57',
  '58'
]
const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  onChange,
  defaultValue
}) => {
  const [areaCode, setAreaCode] = useState<string>('1')
  const [firstThree, setFirstThree] = useState<string>('')
  const [nextThree, setNextThree] = useState<string>('')
  const [lastFour, setLastFour] = useState<string>('')

  const firstThreeRef = useRef<HTMLInputElement>(null)
  const nextThreeRef = useRef<HTMLInputElement>(null)
  const lastFourRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (defaultValue) {
      const matches = defaultValue.match(/\+(\d{1,3})(\d{3})(\d{3})(\d{4})/)
      if (matches) {
        setAreaCode(matches[1])
        setFirstThree(matches[2])
        setNextThree(matches[3])
        setLastFour(matches[4])
      }
    }
  }, [defaultValue])

  useEffect(() => {
    if (areaCode && firstThree && nextThree && lastFour) {
      onChange(`+${areaCode}${firstThree}${nextThree}${lastFour}`)
    }
  }, [areaCode, firstThree, nextThree, lastFour, onChange])

  const handleAreaCodeChange = (event: any) => {
    setAreaCode(event.target.value as string)
  }

  const handleInputChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      nextRef?: React.RefObject<HTMLInputElement>
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const numericValue = event.target.value.replace(/\D/g, '') // Remove non-digit characters
      setter(numericValue)
      if (numericValue.length === event.target.maxLength && nextRef?.current) {
        nextRef.current.focus()
      }
    }

  return (
    <Box sx={{ py: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel>Phone *</InputLabel>
            <Select
              fullWidth
              value={areaCode}
              onChange={handleAreaCodeChange}
              label='Area Code'
              inputProps={{ tabIndex: 5 }}
              required
            >
              {codes.map(c => (
                <MenuItem key={c} value={c}>
                  +{c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label='First 3'
            variant='outlined'
            value={firstThree}
            onChange={handleInputChange(setFirstThree, nextThreeRef)}
            inputProps={{ maxLength: 3, tabIndex: 6 }}
            size='small'
            required
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label='Next 3'
            variant='outlined'
            value={nextThree}
            onChange={handleInputChange(setNextThree, lastFourRef)}
            inputProps={{ maxLength: 3, tabIndex: 7 }}
            size='small'
            required
            inputRef={nextThreeRef}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label='Last 4 Digits'
            variant='outlined'
            value={lastFour}
            onChange={handleInputChange(setLastFour)}
            inputProps={{ maxLength: 4, tabIndex: 8 }}
            size='small'
            required
            inputRef={lastFourRef}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PhoneNumberInput
