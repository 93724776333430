import {
    Box,
    Container,
    Grid,
    Paper,
    Skeleton,
    Typography
  } from '@mui/material'
  import { StyleHelper } from '../style-helper'
  import { Fragment } from 'react/jsx-runtime'
  
  export default function TickerDrawerLoadingState ({stacked} : {stacked?: boolean}) {
    return (
      <Container>
        <Paper elevation={3}>
          <Box p={3}>
            <Skeleton variant='text' width='80%' height={40} />
            <Skeleton variant='text' width='60%' />
            <Skeleton variant='text' width='40%' />
          </Box>
        </Paper>
        <Box my={2} />
        <Grid container spacing={4}>
          <Grid item sm={12} lg={2}>
            <Skeleton variant='rectangular' width={'100%'} height={100} />
          </Grid>
          <Grid item sm={12} lg={8}>
            <Skeleton variant='text' width='100%' height={60} />
            <Skeleton variant='text' width='20%' height={40} />
          </Grid>
          
          <Grid 
            item 
            container 
            justifyContent="flex-end"
            alignItems="flex-end" 
            flexDirection='column'>
            <Skeleton variant='text' width={200} height={20} />
            <Skeleton variant='text' width={150} height={60} />
            <Skeleton variant='text' width={100} height={20} />
          </Grid>
        </Grid>
        <Box my={2} />
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={4}>
              {Array.from(new Array(8)).map((_, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} lg={stacked ? 12 : 6}>
                    <Grid container py={1}>
                      <Grid item xs={12} lg={stacked ? 12 : 3}>
                        <Skeleton variant='text' width='90%' height={20} />
                      </Grid>
                      <Grid item xs={12} lg={stacked ? 12 : 9}>
                        <Skeleton variant='text' width='60%' height={20} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>
        </Paper>
    
        <Box my={2} />
            <Paper elevation={3}>
                <Box p={3}>
                    <Grid container spacing={4}>
                    {Array.from(new Array(8)).map((_, index) => (
                        <Fragment key={index}>
                        <Grid item xs={12} lg={stacked ? 12 : 6}>
                            <Grid container py={1}>
                            <Grid item xs={12} lg={stacked ? 12 : 3}>
                                <Skeleton variant='text' width='90%' height={20} />
                            </Grid>
                            <Grid item xs={12} lg={stacked ? 12 : 9}>
                                <Skeleton variant='text' width='60%' height={20} />
                            </Grid>
                            </Grid>
                        </Grid>
                        </Fragment>
                    ))}
                    </Grid>
                </Box>
            </Paper>
        <Box my={2} />
            <Paper elevation={3}>
                <Box p={3}>
                    <Grid container spacing={4}>
                    {Array.from(new Array(8)).map((_, index) => (
                        <Fragment key={index}>
                        <Grid item xs={12} lg={stacked ? 12 : 6}>
                            <Grid container py={1}>
                            <Grid item xs={12} lg={stacked ? 12 : 3}>
                                <Skeleton variant='text' width='90%' height={20} />
                            </Grid>
                            <Grid item xs={12} lg={stacked ? 12 : 9}>
                                <Skeleton variant='text' width='60%' height={20} />
                            </Grid>
                            </Grid>
                        </Grid>
                        </Fragment>
                    ))}
                    </Grid>
                </Box>
            </Paper>
          
         
        
      </Container>
    )
  }
  