import { ResponseHelper } from '../_helpers/response.helper'
import { CarnivoreUser } from '../_types/user.type'
import axiosInstance from './axios.service'

export class UserService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async auth (email: string, password: string) {
    return axiosInstance
      .post('/user/auth', { email, password })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async signup (user: CarnivoreUser) {
    return axiosInstance
      .post('/user', { user })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async sendForgot (email: string) {
    return axiosInstance
      .post('/user/forgot', { email })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async sendSupport (message: string) {
    return axiosInstance
      .post('/user/support', { message })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async verifyForgotToken (token: string) {
    return axiosInstance
      .post('/user/forgot/verify', { token })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async forgotReset (token: string, password : string) {
    return axiosInstance
      .post('/user/forgot/reset', { token, password })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async legacySync () {
    return axiosInstance
      .post('/user/legacy-sync', {})
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async sendVerificationCode () {
    return axiosInstance
      .post('/user/send-verify', {})
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async verifyEmail (code: string) {
    return axiosInstance
      .post('/user/verify-email', { code })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getWebsocketToken () {
    return axiosInstance
      .get('/user/ws-token')
      .then(res => res.data.data)
      .catch(e => e)
  }

  async getUser () {
    return axiosInstance
      .get('/user')
      .then(res => res.data.data)
      .catch(e => e)
  }
  async sendTestPush () {
    return axiosInstance
      .post('/user/test-web-push')
      .then(res => res.data.data)
      .catch(e => e)
  }

  async updateUser (user: any) {
    return axiosInstance
      .put('/user', { user })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async logout () {
    return axiosInstance
      .post('/user/logout')
      .then(res => res.data.data)
      .catch(e => e)
  }

  async klaviyoHealthCheck () {
    return axiosInstance
      .put('/user/klaviyo-health', {})
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async klaviyoResubscribe () {
    return axiosInstance
      .put('/user/klaviyo-resub', {})
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
  async rejoinList (type: 'email' | 'sms') {
    return axiosInstance
      .put('/user/klaviyo-rejoin', { type })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
