import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Typography
} from '@mui/material'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import { StyleHelper } from '../components/style-helper'
import { useEffect, useState } from 'react'
import { useGlobalState } from '../context/global'
import { useError } from '../context/error'
import { SettingsService } from '../services/settings.service'
import { Formatters } from '../_helpers/formatter.helper'
import useQueryParamsV2 from '../hooks/userQueryParamsV2'
import { PaymentService } from '../services/payment.service'
import LogoutModal from '../components/modals/logout.modal'
import CreditCardForm from '../components/creditcard-form'
import { useTheme as useCarnivoreTheme } from '../context/theme'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useNavigate } from 'react-router-dom'
import { useSuccess } from '../context/success'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string)

interface NoSubQueryParams {
  p?: string
  c?: string
  resub?: string
}

export default function NoSubPage (props: PageProps) {
  useDocumentTitle(props.title)
  const [loading, setLoading] = useState(true)
  const { settings } = useGlobalState()
  const [monthlyPrice, setMonthlyPrice] = useState('')
  const [annualPrice, setAnnualPrice] = useState('')
  const { setError } = useError()
  const {setSuccess} = useSuccess()
  const settingsService = new SettingsService(setError)
  const paymentService = new PaymentService(setError)
  const [showLogout, setShowLogout] = useState(false)
  const [total, setTotal] = useState(0)
  const { mode } = useCarnivoreTheme() // Now mode is correctly provided from the context

  const [queryParams, setQueryParams] = useQueryParamsV2<NoSubQueryParams>()
  const [showForm, setShowForm] = useState(false)
  const [selectedPM, setSelectedPM] = useState('')
  const [paymentMethods, setPaymentMethods] = useState([])
  const navigate = useNavigate()
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    _fetchPrices()
    setQueryParams({
      resub: 'true',
      p: 'pro'
    })
  }, [])

  useEffect(() => {
    if (queryParams.c) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
  }, [queryParams])

  const _fetchPrices = async () => {
    const res = await paymentService.setupPaymentMethod()
    if (res.clientSecret) {
      setClientSecret(res.clientSecret)
    }
    const monthlyRes = await settingsService.getPrice(
      settings?.proMonthlyPriceID ?? ''
    )
    const annualRes = await settingsService.getPrice(
      settings?.proYearlyPriceID ?? ''
    )

    if (monthlyRes.price) {
      setMonthlyPrice(Formatters.formatMoney(monthlyRes.price / 100))
    }

    if (annualRes.price) {
      setAnnualPrice(Formatters.formatMoney(annualRes.price / 100))
    }

    const pm = await paymentService.getPaymentMethods()
    setPaymentMethods(pm)
    setLoading(false)
  }

  const _resetQueryParms = () => {
    setQueryParams({
      p: undefined,
      c: undefined
    })
  }
  return (
    <Container maxWidth='xl' sx={{ p: 3 }}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={6} lg={6}>
          <Paper elevation={1}>
            <Box p={3}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button variant='outlined' onClick={() => setShowLogout(true)}>
                  Logout
                </Button>
              </Box>
              <Grid container justifyContent={'center'}>
                <Grid item xs={6} lg={1}>
                  <CarnivoreLogo />
                </Grid>
              </Grid>
              <StyleHelper.VerticalSpace />

              <Typography
                variant='subtitle1'
                color='primary'
                gutterBottom
                fontWeight={'bold'}
                textAlign={'center'}
              >
                No Active Subscription Found
              </Typography>
              <Divider />
              <StyleHelper.VerticalSpace />
              <Typography textAlign={'center'} variant='body2'>
                We could not find an active subscription for your account. You
                need an active subscription to access Carnivore Trading
              </Typography>
              <StyleHelper.VerticalSpace />
              <Typography textAlign={'center'} variant='body2'>
                Please select a package below to gain access to Carnivore.{' '}
                <em>
                  If you think this is a mistake please email{' '}
                  <Link href='mailto:support@carnivoretrading.com'>
                    support@carnivoretrading.com
                  </Link>
                </em>
              </Typography>
            </Box>
          </Paper>
          <StyleHelper.DoubleVerticalSpace />
          {!showForm && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} height={'100%'}>
                <Paper elevation={1} sx={{ height: '100%' }}>
                  <Box p={3} height={'100%'}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      gutterBottom
                      fontWeight={'bold'}
                      textAlign={'center'}
                    >
                      Carnivore Pro Monthly
                    </Typography>
                    {loading ? (
                      <Skeleton height={56} />
                    ) : (
                      <Typography variant='h3' textAlign={'center'}>
                        ${monthlyPrice}
                      </Typography>
                    )}
                    <Typography variant='body2' textAlign={'center'}>
                      per month
                    </Typography>

                    <StyleHelper.VerticalSpace />
                    <Button
                      variant='contained'
                      disabled={loading}
                      fullWidth
                      color='secondary'
                      onClick={() => setQueryParams({ c: 'monthly' })}
                    >
                      Select Monthly
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6} height={'100%'}>
                <Paper elevation={1}>
                  <Box p={3}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      gutterBottom
                      fontWeight={'bold'}
                      textAlign={'center'}
                    >
                      Carnivore Pro Annual
                    </Typography>
                    {loading ? (
                      <Skeleton height={56} />
                    ) : (
                      <Typography variant='h3' textAlign={'center'}>
                        ${annualPrice}
                      </Typography>
                    )}
                    <Typography variant='body2' textAlign={'center'}>
                      per year
                    </Typography>

                    <StyleHelper.VerticalSpace />
                    <Button
                      disabled={loading}
                      variant='contained'
                      fullWidth
                      color='primary'
                      onClick={() => setQueryParams({ c: 'annual' })}
                    >
                      Select Annual
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
          {showForm && (
            <Paper elevation={1}>
              <Box p={3}>
                <Box
                  display='flex'
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography
                    variant='subtitle1'
                    color={'primary'}
                    fontWeight={'bold'}
                  >
                    Checkout
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => setQueryParams({ c: undefined })}
                  >
                    Back
                  </Button>
                </Box>
                <StyleHelper.VerticalSpace />
                <Divider />
                <StyleHelper.VerticalSpace />
                <FormControl fullWidth>
                  <InputLabel id='pm-lbl'>Payment Method</InputLabel>
                  <Select
                  disabled={loading}
                    labelId='pm-lbl'
                    value={selectedPM}
                    label='Payment Method'
                    onChange={e => setSelectedPM(e.target.value)}
                  >
                    <MenuItem value={'create_new'}>Create New</MenuItem>
                    {paymentMethods?.map((pm: any) => (
                      <MenuItem
                        key={pm.id}
                        value={pm.id}
                      >{`${pm?.card?.brand?.toUpperCase()} Ending In ${
                        pm?.card.last4
                      }`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {clientSecret !== '' && (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret,
                      appearance: {
                        theme: mode === 'dark' ? 'night' : 'flat'
                      }
                    }}
                  >
                    <CreditCardForm pmId={selectedPM} onComplete={() => {
                      navigate('/dashboard')
                      setTimeout(() => {
                        setSuccess('Subscription Active')
                      }, 1000)
                    }} />
                  </Elements>
                )}
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
      <LogoutModal
        size='md'
        open={showLogout}
        onClose={() => setShowLogout(false)}
      />
    </Container>
  )
}
