import React, { useState } from 'react'
import {
  Button,
  Modal,
  Box,
  useMediaQuery,
  Typography,
  Grid
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { StyleHelper } from '../style-helper'
import LoadingButton from '../controls/loading-btn'
import useBoolean from '../../hooks/useBoolean'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'

const LogoutModal: React.FC<CarnivoreModalProps> = ({
  open,
  onClose,
  size = 'sm'
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const [loading, { toggle: toggleLoading }] = useBoolean()

  const { setError } = useError()
  const userService = new UserService(setError)
  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  const _logout = async () => {
    toggleLoading()
    await userService.logout()

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography variant='h5' fontWeight={'bold'}>
          Are you sure you want to logout?
        </Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='body2'>
          You will be logged out immediately
        </Typography>
        <StyleHelper.DoubleVerticalSpace />

        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          {' '}
          {/* Change justifyContent to flex-end */}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            {' '}
            {/* Change textAlign to right */}
            <LoadingButton
              onClick={_logout}
              loading={loading}
              variant='contained'
              sx={{ ml: 1 }}
            >
              Logout
            </LoadingButton>
            <Button variant='text' sx={{ ml: 1 }} onClick={onClose}>
              Cancel
            </Button>
            {/* Adjust margin */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default LogoutModal
