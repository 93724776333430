import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography
} from '@mui/material'
import CarnivoreLogo from './imgs/carnivore-logo'
import { StyleHelper } from './style-helper'

export default function NoResults () {
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={1} lg={1}>
            <CarnivoreLogo />
          </Grid>
          <StyleHelper.VerticalSpace />
        </Grid>
        <Typography variant='h5'>
          Hmmm, we didn't find anything for this search.
        </Typography>
        <StyleHelper.VerticalSpace />
        <Paper>
          <List>
            <ListItem>
              <Typography variant='body2'>
                Try adjusting your filter parameters
              </Typography>
            </ListItem>
            <Divider />

            <ListItem>
              <Typography variant='body2'>
                Try Clearing your filter parameters
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <Typography variant='body2'>Come back later</Typography>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  )
}
