import { Box, Divider, Paper, Typography } from '@mui/material'
import { StyleHelper } from './style-helper'
import React from 'react'

interface CarnivoreBoxProps {
  title?: string
  id? : string
}
const CarnivoreBox: React.FC<React.PropsWithChildren<CarnivoreBoxProps>> = ({
  children,
  title,
  id
}) => {
  return (
    <Paper elevation={3} id={id} >
      <Box p={3} height={'100%'}>
        {title && (
          <>
            <Typography
              variant='subtitle1'
              color={'primary'}
              fontWeight={'bold'}
            >
              {title}
            </Typography>
            <Divider />
            <StyleHelper.VerticalSpace />
          </>
        )}
        {children}
      </Box>
    </Paper>
  )
}

export default CarnivoreBox
