import { Box } from '@mui/material'
import { useTheme } from '../../context/theme'

export default function CarnivoreLogoTxt () {
  const { mode } = useTheme() // Now mode is correctly provided from the context

  return (
    <Box
      component='img'
      sx={{
        width: '100%', // Makes the image responsive
        height: 'auto' // Maintains aspect ratio
      }}
      src={mode === 'light' ? require('../../assets/imgs/logo-text.png') : require('../../assets/imgs/logo-text-inverted.png')}
      alt='Description'
    />
  )
}
