import { Box } from '@mui/material'

export default function CarnivoreLogo () {
  return (
    <Box
      component='img'
      sx={{
        width: '100%', // Makes the image responsive
        height: 'auto' // Maintains aspect ratio
      }}
      src={require('../../assets/imgs/logo.png')}
      alt='Carnivore Logo'
    />
  )
}
