import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material'
import CarnivoreLogo from '../../components/imgs/carnivore-logo'
import { StyleHelper } from '../../components/style-helper'
import PageProps from '../../_types/page-props.interface'
import { useError } from '../../context/error'
import { UserService } from '../../services/user.service'

const SignupSyncPage = (props: PageProps) => {
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(false)

  const { setError } = useError()
  const userService = new UserService(setError)

  useEffect(() => {
    _syncData()
  }, [])

  const _syncData = async () => {
    const res = await userService.legacySync()
    if (res.success) {
      window.location.href = '/dashboard'
    } else {
      setErr(true)
    }
    setLoading(false)
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container alignItems='center' justifyContent={'center'} spacing={2}>
        <Grid item xs={1}>
          {/* Your image goes here */}
          <CarnivoreLogo />
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      {!err && (
        <>
          <Typography
            variant='subtitle1'
            fontWeight={'bold'}
            textAlign={'center'}
          >
            We are getting your new experience ready.
          </Typography>
          <Typography variant='body2' textAlign={'center'}>
            This should only take a few seconds.
          </Typography>
          <StyleHelper.VerticalSpace />

          <CircularProgress size={24} />
        </>
      )}
      {err && (
        <>
          <Typography
            variant='subtitle1'
            fontWeight={'bold'}
            textAlign={'center'}
          >
            We Couldn't Migrate Your Account.
          </Typography>
          <Typography variant='body2' textAlign={'center'}>
            Please email{' '}
            <Link href='mailto:support@carnivoretrading.com'>
              support@carnivoretrading.com
            </Link>{' '}
            to resolve this issue.
          </Typography>
        </>
      )}
    </Box>
  )
}

export default SignupSyncPage
