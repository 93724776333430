import React, { ReactNode, createContext, useContext, useState } from 'react'
import { IconButton, Snackbar } from '@mui/material'
import { Close } from '@mui/icons-material'

// Define the shape of your error context
interface ErrorContextType {
  errorMessage: string | null
  setError: (message: string | null) => void
}

// Create the context
const ErrorContext = createContext<ErrorContextType | undefined>(undefined)
interface ErrorProps {
  children: ReactNode
}
// Create the provider component
export const ErrorProvider = ({ children }: ErrorProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // Function to set error message
  const setError = (message: string | null) => {
    setErrorMessage(message)
  }

  // Function to clear error message
  const clearError = () => {
    setErrorMessage(null)
  }

  // Value for the context
  const value: ErrorContextType = {
    errorMessage,
    setError
  }

  return (
    <ErrorContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={clearError}
        message={errorMessage || ''}
        ContentProps={{
          sx: {
            backgroundColor: 'error.main',
            color: 'white'
          }
        }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={clearError}
          >
            <Close fontSize='small' />
          </IconButton>
        }
      />
    </ErrorContext.Provider>
  )
}

// Custom hook to use the context
export const useError = () => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider')
  }
  return context
}
