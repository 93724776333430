import React from 'react'
import Box from '@mui/material/Box'

// Define a namespace that contains your components
export namespace StyleHelper {
  export const VerticalSpace: React.FC = () => (
    <Box sx={{ height: theme => theme.spacing(2), backgroundColor : 'transparent' }} />
  )
  export const HorizontalSpace: React.FC = () => (
    <Box sx={{ width: theme => theme.spacing(4), backgroundColor : 'transparent'  }} />
  )
  export const SmallHorizontalSpace: React.FC = () => (
    <Box sx={{ width: theme => theme.spacing(1), backgroundColor : 'transparent' }} />
  )
  export const SmallVerticalSpace: React.FC = () => (
    <Box sx={{ height: theme => theme.spacing(1), backgroundColor : 'transparent' }} />
  )

  export const DoubleVerticalSpace: React.FC = () => (
    <Box sx={{ height: theme => theme.spacing(4), backgroundColor : 'transparent' }} />
  )
}
