import { IconButton, Tooltip } from '@mui/material'
import { useKnowledgeCenter } from '../../context/knowledgecenter-context'
import { Info } from '@mui/icons-material'

interface KnowledgeBtnProps {
  id: string
}
export default function KnowledgeBtn (props: KnowledgeBtnProps) {
  const { openKnowledgeCenter } = useKnowledgeCenter()

  return (
    <Tooltip title='Learn More'>
      <IconButton onClick={() => openKnowledgeCenter(props.id)}>
        <Info />
      </IconButton>
    </Tooltip>
  )
}
