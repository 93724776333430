import { Grid, Paper, Skeleton } from '@mui/material'
import { StyleHelper } from '../style-helper'

export default function AlertViewLoadingState () {
  return (
    <Paper elevation={3} sx={{ m: 1, p: 2 }}>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} lg={1}>
          <Skeleton variant='rectangular' width={26} height={26} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Skeleton variant='text' width='80%' height={30} />
          <Skeleton variant='text' width='60%' />
          <StyleHelper.VerticalSpace />
          <Skeleton variant='text' width='90%' />
        </Grid>
        <Grid item xs={12} lg={3} container justifyContent='flex-end'>
          <Skeleton variant='rectangular' width={100} height={36} />
        </Grid>
      </Grid>
    </Paper>
  )
}
