import { QueryStats } from '@mui/icons-material'
import { Button, Chip, Tooltip } from '@mui/material'
import { useTickerDrawer } from '../context/ticker-drawer-context'

interface TickerLinkPros {
  display: 'link' | 'chip',
  ticker: string
}  
  
  export default function TickerLink({display, ticker}: TickerLinkPros) {
    const {setTicker, toggleDrawer} = useTickerDrawer()

    return (
      <Tooltip title="View Stock Information">
        {
          display === 'chip' ? 
          <Chip 
            sx={{
                ml: 2
              }}
              clickable
              onClick={() => {
                setTicker(ticker)
                toggleDrawer()
              }}
              label={`View ${ticker}`}
              variant='filled'
              color='primary'
              icon={<QueryStats />}
          /> :  
            <Button
              onClick={() => {
                setTicker(ticker)
                toggleDrawer()
              }}
              sx={{textDecoration : 'underline', '&:hover' : {textDecoration : 'underline'}}}
              variant='text'
              color="secondary"
            >
            {ticker}
          </Button>
        }
      </Tooltip>
    )
  }


