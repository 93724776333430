import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface BackButtonProps {
    fallbackUrl: string; // URL to navigate to if no history is available
}

const BackButton: React.FC<BackButtonProps> = ({ fallbackUrl }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {
        // This effect runs on mount and whenever the location changes.
        // Update the state based on whether the user can go back.
        setCanGoBack(location.key !== 'default');
    }, [location]);

    const handleBack = () => {
        if (canGoBack) {
            navigate(-1);
        } else {
            navigate(fallbackUrl);
        }
    };

    return (
        <Chip
            icon={<ArrowBackIcon />}
            label="Back"
            onClick={handleBack}
            color="primary"
            variant="outlined"
        />
    );
};

export default BackButton;
