import React, { useEffect, useState } from 'react'
import { useError } from '../context/error'
import { WrapService } from '../services/wrap.service'
import useBoolean from './useBoolean'

export default function useGetPortfolioReturns () {
  const [errorMessage, setErrMessage] = useState<string | null>(null)
  const { setError } = useError()
  const wrapService = new WrapService(setError)
  const [loading, { toggle }] = useBoolean(true)
  const [returnPercentage, setReturnPercentage] = useState(0)

  useEffect(() => {
    async function getYTD () {
      const res = await wrapService.getPortfolioReturns()
      try {
        if (res.errMsg) {
          setErrMessage('Could not fetch')
          toggle()
          return
        }
      } catch (e) {}
      if (res) {
        setReturnPercentage(res)
        toggle()
      }
    }

    getYTD()
  }, [])

  return { errorMessage, loading, returnPercentage }
}
