import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useTheme } from '../context/theme'
import { StyleHelper } from '../components/style-helper'
import CountdownTimer from '../components/coundown'
import { ArrowRightAltRounded } from '@mui/icons-material'

export default function AcademyPage (props: PageProps) {
  useDocumentTitle(props.title)
  const { mode } = useTheme()

  return (
    <Container maxWidth='xl'>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} lg={10}>
          <Box
            sx={{
              backgroundImage: `url(${require('../assets/imgs/events/academy.webp')})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <Box
              sx={{
                backdropFilter: 'blur(8px)', // Standard syntax
                WebkitBackdropFilter: 'blur(8px)',
                backgroundColor:
                  mode === 'dark'
                    ? 'rgba(0, 0, 0, 0.3)'
                    : 'rgba(255, 255, 255, 0.3)' // Adds a white layer with 50% opacity
              }}
            >
              <Grid container display={'flex'} justifyContent={'center'}>
                <Grid item xs={10} lg={6}>
                  <Box
                    component={'img'}
                    alt='cover'
                    sx={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={require('../assets/imgs/events/academy.webp')}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <StyleHelper.VerticalSpace />

          <Grid container>
            <Grid item lg={8} xs={12}>
              <Typography variant='h2' fontWeight={'bold'}>
                Carnivore Trading Academy
              </Typography>
              <Typography variant='body2' fontWeight={'bold'} color={'primary'}>
                Next Academy July 14th, 2024 - July 18th, 2024{' '}
              </Typography>
              <StyleHelper.VerticalSpace />

              <Button variant='contained' endIcon={<ArrowRightAltRounded />}>
                Register Today
              </Button>
            </Grid>
            <Grid item lg={4} xs={12} justifyContent={'flex-end'}>
              <Typography
                variant='subtitle1'
                textAlign={'right'}
                fontWeight={'bold'}
                color={'primary'}
              >
                Next Academy
              </Typography>
              <CountdownTimer targetDate='2024-07-03' />
              <StyleHelper.VerticalSpace />
              <Typography variant='subtitle2' textAlign={'right'}>
                Hurry! Only{' '}
                <Typography
                  color={'primary'}
                  fontWeight={'bold'}
                  display={'inline'}
                >
                  6
                </Typography>{' '}
                Spots Left
              </Typography>
            </Grid>
          </Grid>

          <StyleHelper.DoubleVerticalSpace />
          <Paper elevation={3}>
            <Box p={3}>
              <Typography
                variant='subtitle1'
                fontWeight={'bold'}
                color={'primary'}
              >
                About the Academy
              </Typography>
              <StyleHelper.VerticalSpace />
              <Typography variant='body1'>
                At the Academy, you are submerged in the exciting world of
                trading for living and learn how you can produce returns of 50%,
                100% or 300% a year trading the Carnivore Way.
              </Typography>
              <StyleHelper.VerticalSpace />

              <Typography variant='body1'>
                By the time you leave in just a few days' time, your skills will
                have been amplified to a whole new level. You will trade better,
                you will think about losses differently, and in general, you
                will learn that just about everything you've been taught and
                told about stock trading is wrong.
              </Typography>
              <StyleHelper.VerticalSpace />

              <Typography variant='body1'>
                You will discover exactly how to make triple digit returns in
                every kind of market without using penny stocks, options or any
                weird strategies.
              </Typography>
            </Box>
          </Paper>
          <StyleHelper.DoubleVerticalSpace />

          <Paper elevation={3}>
            <Box p={3}>
              <Typography
                variant='subtitle1'
                fontWeight={'bold'}
                color={'primary'}
              >
                A Typical Day At the Academy
              </Typography>
              <StyleHelper.VerticalSpace />
              <Typography variant='body1'>
                Sunday you arrive and check into your luxury hotel room which is
                covered as part of your tuition. You will be on the Las Vegas
                Strip, and in one of the nicest luxury hotels available.
              </Typography>
              <StyleHelper.VerticalSpace />

              <Typography variant='body1'>
                Monday starts at 6am PST with live trading and instruction
                before the market opens. Power up your laptop and log into your
                trading account. Coffee and light breakfast awaits!
              </Typography>
              <StyleHelper.VerticalSpace />

              <Typography variant='body1'>
                There is no need to dress up or be fancy, just be ready to learn
                and trade with your laptop and trading accounts in hand.
              </Typography>
            </Box>
          </Paper>
          <StyleHelper.DoubleVerticalSpace />
        </Grid>
      </Grid>
    </Container>
  )
}
