import { useEffect, useState } from 'react'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import StockPageLoadingState from '../components/loading/stock-page.loading'
import { useParams } from 'react-router-dom'
import { useError } from '../context/error'
import { StockService } from '../services/stock.service'
import { StockInfo } from '../_types/stock-info.type'
import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material'
import { StyleHelper } from '../components/style-helper'
import { Formatters } from '../_helpers/formatter.helper'
import StockLogo from '../components/imgs/stock-logo'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import { AlertService } from '../services/alert.service'
import { CarnivoreAlert } from '../_types/alert.type'
import AlertView from '../components/alert-view'
import ViewAlertModal from '../components/modals/view-alert.modal'
import TickerDrawerLoadingState from '../components/loading/ticker-drawer.loading'

const _calculatePercentage = (percent: string) => {
  let number = Number(percent)
  let p = number.toFixed(2)
  return (
    <Typography
      variant='h6'
      color={number > 0 ? 'success.main' : 'error.main'}
    >
      {p}%
    </Typography>
  )
}



interface StockInformationProps {
  layout: 'drawer' | 'page',
  logoSrc: string | null,
  ticker: string,
  lastUpdate: string,
  recentAlerts: CarnivoreAlert[],
  setCurrentAlert: React.Dispatch<React.SetStateAction<CarnivoreAlert | null>>,
  stockInfo: StockInfo
}
function StockInformation({layout, logoSrc, ticker, lastUpdate, recentAlerts, setCurrentAlert, stockInfo} : StockInformationProps) {
  return (
      <>
      
      <Paper elevation={3}>
        <Box p={1}>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={1} p={2}>
              <CarnivoreLogo />
            </Grid>
            <Grid item xs={11}>
              <Typography variant='body2'>
                The information provided is for{' '}
                <strong>information purposes only </strong>
                and is <strong>NOT</strong> intended for trading purposes.
                The stock information is provided by a third party service.
                Carnivore Trading does not provide information to this
                service.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <StyleHelper.VerticalSpace />
      <Grid container spacing={4}
        sx={{
          alignItems: {
            lg: 'center',
            sm: 'start'
          }
        }}
      >
        <Grid item xs={12} sm={layout === 'page' ? 2 : 12} lg={1}>
          <StockLogo src={logoSrc ?? ''} alt={ticker ?? ''} />
        </Grid>
        <Grid item xs={12} sm={layout === 'page' ? 10 : 12} lg={6}>
          <Typography variant='h3'>{stockInfo.profile.name}</Typography>
          <Typography variant='h4'>({stockInfo.profile.symbol})</Typography>
        </Grid>
        <Grid item xs={12} lg={5}  alignItems={'flex-end'}>
          <Typography
            fontStyle={'italic'}
            variant='body2'
            textAlign={'end'}
          >
            Last Updated: {new Date(lastUpdate).toLocaleString('en-US')}
          </Typography>
          <Typography variant='h4' textAlign={'end'}>
            ${Formatters.formatMoney(Number(stockInfo.currentPrice))}
            {_calculatePercentage(stockInfo.quote.percent_change)}
          </Typography>
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} lg={6}>
              <Typography
                variant='subtitle1'
                fontWeight={'bold'}
                color={'primary'}
              >
                Quote
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography
                variant='body2'
                textAlign={'end'}
                fontSize={'italic'}
              >
                {stockInfo.quote.is_market_open
                  ? `As of ${new Date(
                      stockInfo.quote.timestamp * 1000
                    ).toLocaleString('en-US')}`
                  : 'As of last market close'}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <StyleHelper.VerticalSpace />
          <Grid container spacing={4}>
            <Grid item xs={12} lg={layout === 'page' ? 6 : 12}>
              <StockRow
                label='Open'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.open)
                )}
              />
              <StockRow
                label='Close'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.close)
                )}
              />
              <StockRow
                label='Previous Close'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.previous_close)
                )}
              />
              <StockRow
                label='High'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.high)
                )}
              />
              <StockRow
                label='Low'
                value={Formatters.formatCommas(Number(stockInfo.quote.low))}
              />
              <StockRow
                label='Volume'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.volume)
                )}
              />
            </Grid>
            <Grid item xs={12} lg={layout === 'page' ? 6 : 12}>
              <StockRow
                label='Average Volume'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.average_volume)
                )}
              />
              <StockRow
                label='52 Week Low'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.fifty_two_week.low)
                )}
              />
              <StockRow
                label='52 Week High'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.fifty_two_week.high)
                )}
              />
              <StockRow
                label='52 Week Low Change'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.fifty_two_week.low_change)
                )}
              />
              <StockRow
                label='52 Week High Change'
                value={Formatters.formatCommas(
                  Number(stockInfo.quote.fifty_two_week.high_change)
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* <Paper elevation={3}>
        <Box p={3}>
          <Typography variant='subtitle1' fontWeight={'bold'}>
            Statistics
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <StockRow
                label='Market Cap'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.market_capitalization
                )}
              />
              <StockRow
                label='EV'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.enterprise_value
                )}
              />
              <StockRow
                label='Trailing PE'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.trailing_pe
                )}
              />
              <StockRow
                label='PEG'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.peg_ratio
                )}
              />
              <StockRow
                label='P/S'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.price_to_sales_ttm
                )}
              />
              <StockRow
                label='P/B'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.price_to_book_mrq
                )}
              />
              <StockRow
                label='EV/R'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.enterprise_to_revenue
                )}
              />
              <StockRow
                label='EV/EBITDA'
                value={Formatters.formatCommas(
                  stockInfo.stats.valuations_metrics.enterprise_to_ebitda
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <StockRow
                label='Fiscal Year Ends'
                value={stockInfo.stats.financials.fiscal_year_ends}
              />
              <StockRow
                label='MRQ'
                value={stockInfo.stats.financials.most_recent_quarter}
              />
              <StockRow
                label='Profit Margin'
                value={Formatters.formatCommas(
                  stockInfo.stats.financials.profit_margin
                )}
              />
              <StockRow
                label='Operating Margin'
                value={Formatters.formatCommas(
                  stockInfo.stats.financials.operating_margin
                )}
              />
              <StockRow
                label='52 Week High'
                value={Formatters.formatCommas(
                  stockInfo.stats.stock_price_summary.fifty_two_week_high
                )}
              />
              <StockRow
                label='52 Week Low'
                value={Formatters.formatCommas(
                  stockInfo.stats.stock_price_summary.fifty_two_week_low
                )}
              />
              <StockRow
                label='50 Day Moving Average'
                value={Formatters.formatCommas(
                  stockInfo.stats.stock_price_summary.day_50_ma
                )}
              />
              <StockRow
                label='200 Day Moving Average'
                value={Formatters.formatCommas(
                  stockInfo.stats.stock_price_summary.day_200_ma
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper> */}
      <StyleHelper.VerticalSpace />

      <Grid container spacing={4}>
        <Grid item xs={12} lg={layout === 'page' ? 7 : 12}>
          <Paper elevation={3}>
            <Box p={3}>
              <Typography
                variant='subtitle1'
                fontWeight={'bold'}
                color={'primary'}
              >
                Description
              </Typography>
              <Divider />
              <StyleHelper.VerticalSpace />
              <Typography variant='body2'>
                {stockInfo.profile.description}
              </Typography>
            </Box>
          </Paper>
          <StyleHelper.VerticalSpace />
          <Box p={3}>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Recent Alerts for {ticker}
            </Typography>
            <Divider />
            <StyleHelper.VerticalSpace />
            {recentAlerts.map(alert => (
              <AlertView
                key={alert.id}
                alert={alert}
                showAlert={() => setCurrentAlert(alert)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} lg={layout === 'page' ? 5 : 12}>
          <Paper elevation={3}>
            <Box p={3}>
              <Typography
                variant='subtitle1'
                fontWeight={'bold'}
                color={'primary'}
              >
                Profile
              </Typography>
              <Divider />
              <StyleHelper.VerticalSpace />
              <StockRow label='Name' value={stockInfo.profile.name} />
              <StockRow label='Symbol' value={stockInfo.profile.symbol} />
              <StockRow label='MIC' value={stockInfo.profile.mic_code} />

              <StockRow
                label='Exchange'
                value={stockInfo.profile.exchange}
              />
              <StockRow label='Sector' value={stockInfo.profile.sector} />
              <StockRow
                label='Industry'
                value={stockInfo.profile.industry}
              />

              <StockRow
                label='Exchange'
                value={stockInfo.profile.exchange}
              />
              <StockRow label='Type' value={stockInfo.profile.type} />
              <StockRow label='CEO' value={stockInfo.profile.CEO} />
              <StockRow
                label='Employees'
                value={Formatters.formatCommas(stockInfo.profile.employees)}
              />
              <StockRow label='Website' value={stockInfo.profile.website} />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <StyleHelper.VerticalSpace />
    </>
  )
}

interface StockPageProps {
  layout: 'drawer' | 'page',
  ticker?: string
}

export default function StockPage (props: PageProps & StockPageProps) {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [logoSrc, setLogoSrc] = useState<string | null>(null)
  const [stockInfo, setStockInfo] = useState<StockInfo | null>(null)
  const [lastUpdate, setLastUpdate] = useState<string | null>(null)
  const [recentAlerts, setRecentAlerts] = useState<CarnivoreAlert[]>([])
  const { setError } = useError()
  const stockService = new StockService(setError)
  const alertService = new AlertService(setError)
  const [currentAlert, setCurrentAlert] = useState<CarnivoreAlert | null>(null)

  useEffect(() => {
    async function getData(){
      //use either ticker being passed in or read from params
      let tickerToFetch = props.layout === 'drawer' ? props.ticker : params.ticker
      if(!tickerToFetch) return;
      //set document title
      if(props.layout === 'page') document.title = `${tickerToFetch} | Carnivore Trading`
      //flip loading state if not currently loading
      if(!loading) setLoading(true)
      //grab logo
      const res = await stockService.getStockLogo(tickerToFetch)
      setLogoSrc(res?.url)
      //grab stock info
      const stockInfoRes = await stockService.getStockInfo(tickerToFetch)
      setStockInfo(stockInfoRes?.stockInfo)
      setLastUpdate(stockInfoRes?.lastUpdate)
      //grab alerts
      const alertRes = await alertService.searchAlerts(
        1,
        tickerToFetch.toUpperCase(),
        undefined,
        3
      )
      if (alertRes?.alerts?.docs) {
        setRecentAlerts(alertRes.alerts.docs)
      }
      setLoading(false)
    }
    
    getData()
  }, [])

  return (
    <Container>
      {loading && props.layout === 'page' && <StockPageLoadingState />}
      {loading && props.layout === 'drawer' && <TickerDrawerLoadingState />}
      {!loading && stockInfo && lastUpdate && (
        <StockInformation 
          layout={props.layout}
          logoSrc={logoSrc}
          ticker={props.layout === 'drawer' ? props.ticker! : params.ticker!}
          lastUpdate={lastUpdate}
          recentAlerts={recentAlerts}
          setCurrentAlert={setCurrentAlert}
          stockInfo={stockInfo}
        />)}
      {!loading && !stockInfo && (
        <>
          <Typography variant='h3'>An Error Occurred</Typography>
          <Typography variant='body1'>
            We cannot find any stock information for this ticker
          </Typography>
        </>
      )}

      {currentAlert && (
        <ViewAlertModal
          open={currentAlert != null}
          onClose={() => setCurrentAlert(null)}
          alert={currentAlert}
          size='lg'
        />
      )}
    </Container>
  )
}

interface _StockRowProps {
  value: any
  label: string
}
function StockRow (props: _StockRowProps) {
  return (
    <>
      <Grid container py={1}>
        <Grid item xs={12} lg={3}>
          <Typography variant='subtitle2'>{props.label}</Typography>
        </Grid>
        <Grid item xs={12} lg={9} alignItems={'flex-end'}>
          <Typography variant='body2' textAlign={'end'}>
            {props.value}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}
