// KnowledgeCenterModal.tsx
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material'
import React from 'react'
import { useKnowledgeCenter } from '../../context/knowledgecenter-context'
import useMediaQuery from '@mui/material/useMediaQuery'
import Knowledges from '../knowledge/knowledges'
import { Close, QuestionMark, QuestionMarkRounded } from '@mui/icons-material'
import { StyleHelper } from '../style-helper'
import CarnivoreLogo from '../imgs/carnivore-logo'

export const KnowledgeCenterModal: React.FC = () => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isOpen, closeKnowledgeCenter, knowledgeCenterType } =
    useKnowledgeCenter()

  // Render your knowledge center content based on `knowledgeCenterType`
  const renderContent = () => {
    if (Knowledges.find(k => k.id === knowledgeCenterType)) {
      return Knowledges.find(k => k.id === knowledgeCenterType)?.content
    } else {
      return (
        <Box alignItems={'center'} height={'100%'}>
          <Typography variant='h6' textAlign={'center'}>
            We're Sorry, We can't load content right now.
          </Typography>
          <StyleHelper.VerticalSpace />
          <Typography variant='body1' textAlign={'center'}>
            Please try again later
          </Typography>
        </Box>
      )
    }
  }

  const renderTitle = () => {
    if (Knowledges.find(k => k.id === knowledgeCenterType)) {
      return (
        <Typography variant='h5' fontWeight={'bold'} textAlign={'center'}>
          {Knowledges.find(k => k.id === knowledgeCenterType)?.title}
        </Typography>
      )
    }
  }

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      sx={{
        width: isMobile ? '100%' : '33%',
        height : '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '33%',
          boxSizing: 'border-box'
        }
      }}
      onClose={closeKnowledgeCenter}
    >
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2 // Padding bottom to add some space between the button and content
          }}
        >
          <IconButton onClick={closeKnowledgeCenter}>
            <Close />
          </IconButton>
        </Box>
        <Grid container justifyContent='center'>
          <Grid item xs={8} lg={2}>
            <CarnivoreLogo />
          </Grid>
        </Grid>
        {renderTitle()}
        <StyleHelper.VerticalSpace />
        {renderContent()}
      </Box>
    </Drawer>
  )
}
