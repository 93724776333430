import { Box } from '@mui/material'
import CarnivoreLogoTxt from './imgs/carnivore-logo-txt'
import ThemeSwitch from './theme-switch'
import { ReactNode, useEffect, useState } from 'react'

export enum ImgType {
  signup,
  email,
  package,
  payment
}
interface SignupSideBarProps {
  imgType: ImgType
  component?: ReactNode
}
export default function SignupSideBar (props: SignupSideBarProps) {
  const signupImg = require('../assets/imgs/signup/signup.png')
  const emailImg = require('../assets/imgs/signup/verify.png')
  const packageImg = require('../assets/imgs/signup/package.png')
  const paymentImg = require('../assets/imgs/signup/payments.png')

  const [img, setImg] = useState(signupImg)

  useEffect(() => {
    switch (props.imgType) {
      case ImgType.email:
        setImg(emailImg)
        break
      case ImgType.signup:
        setImg(signupImg)
        break
      case ImgType.package:
        setImg(packageImg)
        break
      case ImgType.payment:
        setImg(paymentImg)
        break
    }
  }, [])

  return (
    <Box
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box sx={{ alignSelf: 'flex-end' }}>
        <ThemeSwitch />
      </Box>
      <Box sx={{ p: 4 }}>
        {props.component ? (
          props.component
        ) : (
          <Box
            component='img'
            sx={{
              width: '100%', // Makes the image responsive
              height: 'auto' // Maintains aspect ratio
            }}
            src={img}
            alt='Description'
          />
        )}
      </Box>
      <Box sx={{ width: '70%' }}>
        <CarnivoreLogoTxt />
      </Box>
    </Box>
  )
}
