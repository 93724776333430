import React, { ReactNode, useState } from 'react'
import {
  Button,
  Modal,
  Box,
  useMediaQuery,
  Typography,
  Grid
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import { StyleHelper } from '../style-helper'
import LoadingButton from '../controls/loading-btn'

interface AlertModel extends CarnivoreModalProps {
  header: string
  message: string
  customAction?: () => Promise<void>
  customActionText?: string
  customContent?: ReactNode
}

const AlertModal: React.FC<AlertModel> = ({
  open,
  onClose,
  size = 'sm',
  header,
  message,
  customAction,
  customContent,
  customActionText
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const [loading, setLoading] = useState(false)

  const _customAction = async () => {
    setLoading(true)
    if (customAction) {
      await customAction()
    }
    setLoading(false)
    onClose()
  }

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography variant='h5' fontWeight={'bold'}>
          {header}
        </Typography>
        <StyleHelper.VerticalSpace />
        <Typography variant='body1'>{message}</Typography>
        <StyleHelper.DoubleVerticalSpace />
        {customContent && customContent}
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            {customAction && customActionText && (
              <LoadingButton
                onClick={_customAction}
                loading={loading}
                variant='contained'
                sx={{ ml: 1 }}
              >
                {customActionText}
              </LoadingButton>
            )}

            <Button variant='text' sx={{ ml: 1 }} onClick={onClose}>
              Close
            </Button>
            {/* Adjust margin */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default AlertModal
