import { Box, Grid, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { StyleHelper } from './style-helper'

interface GettingStartedInfo {
  title: string
  content: string
  icon: ReactNode
}

export default function GettingStartedInfo (props: GettingStartedInfo) {
  return (
    <Box p={2} mb={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant='h2' color={'primary'} textAlign={'center'}>
        {props.icon}
      </Typography>
      <StyleHelper.VerticalSpace />
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} lg={8}>
          <Typography variant='h3' textAlign={'center'}>
            {props.title}
          </Typography>
          <StyleHelper.VerticalSpace />
          {props.content.split('\n')?.map(line => (
            <Typography variant='body1' mt={2} textAlign={'center'}>
              {line}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}
