import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, IconButton, Typography, Container } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface Testimonial {
  author: string
  url: string
}

interface TestimonialCarouselProps {
  testimonials: Testimonial[]
}

const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({
  testimonials
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playingIndex, setPlayingIndex] = useState<number | null>(null)
  const timeoutRef = useRef<number | null>(null)

  const handleNext = () => {
    if (!isPlaying) {
      setCurrentIndex(prevIndex =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      )
    }
  }

  const handlePrev = () => {
    if (!isPlaying) {
      setCurrentIndex(prevIndex =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      )
    }
  }

  const handleDotClick = (index: number) => {
    if (!isPlaying) {
      setCurrentIndex(index)
    }
  }

  const stopCurrentVideo = () => {
    if (playingIndex !== null) {
      const iframe = document.querySelectorAll('iframe')[playingIndex]
      const iframeSrc = iframe.src
      iframe.src = iframeSrc // Resetting the src stops the video
      setPlayingIndex(null)
    }
  }

  const onIframePlay = (index: number) => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current)
    }
  }

  const onIframePause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    // if (!isPlaying) {
    //   timeoutRef.current = window.setTimeout(() => {
    //     handleNext()
    //   }, 5000)
    // }

    return () => {
      // if (timeoutRef.current) {
      //   clearTimeout(timeoutRef.current)
      // }
    }
  }, [currentIndex, isPlaying])

  return (
    <Container maxWidth='md'>
      <Typography
        variant='subtitle1'
        textAlign={'center'}
        fontWeight={'bold'}
        gutterBottom
      >
        Listen to What Our Carnivores Say
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <IconButton onClick={handlePrev} aria-label='Previous testimonial'>
          <ArrowBackIosIcon />
        </IconButton>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Box display={'block'} textAlign='center'>
            <iframe
              src={testimonials[currentIndex].url}
              title={testimonials[currentIndex].author}
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              onClick={() =>
                isPlaying ? onIframePause() : onIframePlay(currentIndex)
              }
              style={{ width: '400px', height: '200px', borderRadius: '8px' }}
            ></iframe>
            <Typography variant='h6' mt={2}>
              {testimonials[currentIndex].author}
            </Typography>
          </Box>
          {/* {testimonials.map((testimonial, index) => (
            
          ))} */}
          <Box mt={2}>
            {testimonials.map((_, index) => (
              <Button
                key={index}
                size='small'
                onClick={() => handleDotClick(index)}
                sx={{
                  minWidth: 0,
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  margin: '0 4px',
                  backgroundColor:
                    index === currentIndex ? 'primary.main' : 'grey.400'
                }}
              />
            ))}
          </Box>
        </Box>
        <IconButton onClick={handleNext} aria-label='Next testimonial'>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Container>
  )
}

export default TestimonialCarousel
