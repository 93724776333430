import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class AlertService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async searchAlerts (
    page: number,
    ticker?: string,
    alertType?: string,
    limit?: number
  ) {
    return axiosInstance
      .get('/alert/search', {
        params: {
          ticker,
          alertType,
          page,
          limit
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getAlert (alertID: string) {
    return axiosInstance
      .get('/alert', {
        params: {
          id: alertID
        }
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getNumberInLastMonth () {
    return axiosInstance
      .get('/alert/count')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async getBookmarkedAlerts () {
    return axiosInstance
      .get('/alert/bookmarked')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async bookmarkAlert (alertID: string) {
    return axiosInstance
      .post('/alert/bookmark', {
        alertID
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }

  async viewAlert (alertID: string) {
    return axiosInstance
      .post('/alert/view', {
        alertID
      })
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
