import { Box, Typography } from '@mui/material'
import LoadingButton from './controls/loading-btn'
import { StyleHelper } from './style-helper'
import { useState } from 'react'
import { useError } from '../context/error'
import { UserService } from '../services/user.service'

export default function SendTestPush () {

  const [loading, setLoading] = useState(false)

  const {setError} = useError()
  const userService = new UserService(setError)
  const _sendTest = async () => {
    setLoading(true)
    await userService.sendTestPush()
    setLoading(false)

  }
  return (
    <Box>
      <Typography variant='body2'>
        If you would like to test your browser notifications, click the button
        below
      </Typography>
      <StyleHelper.VerticalSpace />

      <Typography variant='body2' fontStyle={'italic'}>
        Please Note: You will only receive browser notifications if you have
        your browser open. You do not have to be on our site, but your browser
        MUST be open. If you are in a focus mode like "work", "sleep", or "do
        not disturb", you will not receive browser notifications. Please disable
        your focus mode settings.
      </Typography>
      <StyleHelper.VerticalSpace />
      <LoadingButton loading={loading} onClick={_sendTest} color='secondary' variant='contained'>
        Send Test Push
      </LoadingButton>
      <StyleHelper.DoubleVerticalSpace />
    </Box>
  )
}
