import { Box, Divider, Paper, Typography } from '@mui/material'
import { useTheme } from '../context/theme'
import { StyleHelper } from './style-helper'

interface SectorCardProps {
  title: string
  content?: string
}

export default function SectorCard (props: SectorCardProps) {
  const { mode } = useTheme()
  return (
    <Paper elevation={1}>
      <Box p={3}>
        <Typography
          variant='subtitle1'
          fontWeight={'bold'}
          textAlign={'center'}
        >
          {props.title}
        </Typography>
        <Divider />
        <StyleHelper.VerticalSpace />
        <Typography variant='body2' textAlign={'center'}>
          {props.content?.length === 0 ? 'None Today' : props.content}
        </Typography>
      </Box>
    </Paper>
  )
}
