import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton
} from '@mui/material'

interface LoadingTableProps {
  rowCount: number
  columnCount: number
}

const LoadingTable: React.FC<LoadingTableProps> = ({
  rowCount,
  columnCount
}) => {
  const renderSkeletonRow = (columns: number) => {
    const cells = []
    for (let i = 0; i < columns; i++) {
      cells.push(
        <TableCell key={i}>
          <Skeleton animation='wave' />
        </TableCell>
      )
    }
    return <TableRow>{cells}</TableRow>
  }

  const renderSkeletonRows = (rows: number, columns: number) => {
    const rowsArr = []
    for (let i = 0; i < rows; i++) {
      rowsArr.push(renderSkeletonRow(columns))
    }
    return rowsArr
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Array.from(Array(columnCount).keys()).map(index => (
              <TableCell key={index}>
                <Skeleton animation='wave' />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderSkeletonRows(rowCount, columnCount)}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default LoadingTable
