import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { CarnivoreModalProps } from '../../_types/modal-props.interface'
import useBoolean from '../../hooks/useBoolean'
import { StyleHelper } from '../style-helper'
import { Cancel } from '@mui/icons-material'
import { FormEvent, useState } from 'react'
import { useTextInput } from '../../hooks/useTextField'
import LoadingButton from '../controls/loading-btn'
import { useError } from '../../context/error'
import { CommentService } from '../../services/comment.service'
import { useSuccess } from '../../context/success'

const CommentModal: React.FC<CarnivoreModalProps> = ({
  open,
  onClose,
  size = 'md'
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [loading, { toggle: toggleLoading }] = useBoolean(false)
  const { setSuccess } = useSuccess()

  const { setError } = useError()
  const [feedbackType, setFeedbackType] = useState('')

  const messageInput = useTextInput({
    initialValue: ''
  })

  const commentService = new CommentService(setError)

  const _getWidth = () => {
    if (isSmallScreen) {
      return '90%'
    }
    switch (size) {
      case 'sm':
        return '30%'
      case 'md':
        return '60%'
      case 'lg':
        return '80%'
    }
  }

  const _leaveFeedback = async (e: FormEvent) => {
    e.preventDefault()
    toggleLoading()

    const res = await commentService.postComment(
      feedbackType,
      messageInput.value
    )

    toggleLoading()
    setSuccess('Thank you for your feedback')
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: _getWidth(), // Adjust width based on screen size
          maxWidth: _getWidth(),
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant='subtitle1' fontWeight={'bold'}>
            Share Feedback
          </Typography>
          <IconButton onClick={onClose}>
            <Cancel />
          </IconButton>
        </Box>
        <StyleHelper.SmallVerticalSpace />
        <Divider />
        <StyleHelper.VerticalSpace />
        <Typography variant='body2'>
          Whether you have a brilliant idea, a feature request, or feedback on
          your Carnivore experience, we're all ears! We value your input and
          want to make Carnivore even better for you.
        </Typography>

        <StyleHelper.DoubleVerticalSpace />

        <form onSubmit={_leaveFeedback}>
          <FormControl fullWidth>
            <InputLabel id='comment-type'>Select Type</InputLabel>
            <Select
              labelId='comment-type'
              id='comment-type-select'
              value={feedbackType}
              label='Select Type'
              required
              onChange={e => setFeedbackType(e.target.value)}
            >
              <MenuItem value={'bug'}>Bug</MenuItem>
              <MenuItem value={'feature'}>Feature Request</MenuItem>
              <MenuItem value={'general'}>General Feedback</MenuItem>
            </Select>
          </FormControl>
          <StyleHelper.VerticalSpace />
          <TextField
            label='Message'
            required
            placeholder='Describe, feel free to be detailed'
            variant='outlined'
            type='text'
            fullWidth
            multiline
            inputProps={{ maxLength: 500 }}
            rows={7}
            value={messageInput.value}
            error={messageInput.error}
            helperText={messageInput.helperText}
            onChange={messageInput.onChange}
          />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Typography variant='caption'>{messageInput?.value?.length ?? 0}/500</Typography>
          </Box>
          <StyleHelper.VerticalSpace />

          <LoadingButton
            loading={loading}
            variant='contained'
            type='submit'
            disabled={messageInput.value === '' || loading}
          >
            Submit
          </LoadingButton>
        </form>
      </Box>
    </Modal>
  )
}

export default CommentModal
