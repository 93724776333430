import { AttachMoney } from '@mui/icons-material'
import { Autocomplete, Box, Button, Grid, InputAdornment, Link, Paper, TextField, Typography } from '@mui/material'
import { AddressElement } from '@stripe/react-stripe-js'
import React, { FormEvent, useState } from 'react'
import KnowledgeBtn from '../../components/controls/knowledge-btn'
import { StyleHelper } from '../../components/style-helper'
import { useError } from '../../context/error'
import { useGlobalState } from '../../context/global'
import { useTextInput } from '../../hooks/useTextField'
import PROFESSION_OPTIONS from '../../_constants/profession-options'
import PageProps from '../../_types/page-props.interface'
import { UserService } from '../../services/user.service'
import PhoneNumberInput from '../../components/controls/phone-input'
import LoadingButton from '../../components/controls/loading-btn'
import useBoolean from '../../hooks/useBoolean'
import { useSuccess } from '../../context/success'


export default function ProfilePage(props: PageProps) {
    const { user } = useGlobalState()

    const accountBalanceInput = useTextInput({
        initialValue: user ? user.accountBalance?.toString() ?? "" : '',
        errMsg: 'Enter only numbers in this field',
        validate: (value: string) => {
          return /^[+-]?(\d+\.?\d*|\.\d+)$/.test(value)
        }
      })

    const [isSaving, {toggle}] = useBoolean(false)
    const [personalInformation, setPersonalInformation] = useState({
        fName: user?.fName,
        lName: user?.lName,
        phoneNumber: user?.phoneNumber,
        accountBalance: accountBalanceInput.value,
        profession: user?.profession ?? "",
        address: user?.address
    });  

    const { setSuccess } = useSuccess()
    const { setError } = useError()
    const userService = new UserService(setError)
      
    async function updateUser(e: FormEvent){
        e.preventDefault();

        if (
        personalInformation.accountBalance && 
        personalInformation.accountBalance !== '' &&
        !/^[+-]?(\d+\.?\d*|\.\d+)$/.test(personalInformation.accountBalance)
        ) {
            setError('Estimated Account Balance must be a valid number')
            return
        }
        
        const phoneRegex = /^\+\d{1,3}\d{10,11}$/
        if (personalInformation.phoneNumber && !phoneRegex.test(personalInformation.phoneNumber)) {
            setError('Please enter a valid phone number')
            return
        }
        
        toggle()
        await userService.updateUser(({...personalInformation, accountBalance: accountBalanceInput.value }))
        setSuccess('Information Saved!')
        toggle()
    }

  return (
    <Box display={'flex'} flexDirection={'column'} px={3} pb={3}>
        <Typography
            variant='h4'
            fontWeight={'bold'}
            gutterBottom
            textAlign={'left'}>
            Personal Information
        </Typography>
        <form onSubmit={updateUser}>
            <AddressElement
                options={{
                    display: { name: 'split' },
                    mode: 'billing',
                    defaultValues: {
                        firstName: personalInformation.fName,
                        lastName: personalInformation.lName,
                        address: personalInformation.address
                    } as any
                }}
                onChange={event => {
                    setPersonalInformation((c) => ({...c, fName:event.value.firstName, lName:event.value.lastName, address: event.value.address} as any))}} /> 
            <StyleHelper.DoubleVerticalSpace />
            <PhoneNumberInput defaultValue={user?.phoneNumber} onChange={e => setPersonalInformation(c => ({...c, phoneNumber: e}))} />
            <StyleHelper.DoubleVerticalSpace />
            <TextField label="Email Address" helperText="Email support@carnivoretrading.com to change your email" variant="outlined" sx={{width: '100%'}} defaultValue={user?.email} disabled/>
            <StyleHelper.DoubleVerticalSpace />
            <TextField
                disabled={isSaving}
                label='Account Balance'
                variant='outlined'
                fullWidth 
                value={accountBalanceInput.value}
                error={accountBalanceInput.error}
                helperText={accountBalanceInput.helperText}
                onChange={accountBalanceInput.onChange}
                InputProps={{
                    tabIndex: 6,
                    startAdornment: (
                    <InputAdornment position='start'>
                        <AttachMoney />
                    </InputAdornment>
                    ),
                    endAdornment: (
                    <InputAdornment position='end'>
                        <KnowledgeBtn id='account-balance' />
                    </InputAdornment>
                    )
                }}
                />
            <StyleHelper.DoubleVerticalSpace />
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={PROFESSION_OPTIONS}
                onChange={(e, value) => setPersonalInformation(c => ({...c, profession: value!}))}
                defaultValue={personalInformation.profession}
                renderInput={(params) => <TextField {...params} label="Profession" sx={{ textTransform: 'capitalize' }}/>}
            />
            <StyleHelper.DoubleVerticalSpace />
            <LoadingButton
                type='submit'
                variant='contained'
                color='primary'
                loading={isSaving}
                disabled={isSaving || accountBalanceInput.error}
            >
                save personal information
            </LoadingButton>
        </form>
    </Box>
  )
}
