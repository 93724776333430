import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class LongTermService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }

  async getCurrentBuyHold () {
    return axiosInstance
      .get('/longterm/current')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
